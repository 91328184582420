import { useState, useEffect } from 'react';
import '../src/scss/mainview.scss';
import Sidebar from './components/SideBar';
import SideBarRight from './components/SidebarRight';
import Mapleaf3 from './MapLeaf3';
import Swal from 'sweetalert2';
// import MapLeaf from './components/MapLeaf';
// import Mapleaf2 from './components/Mapleaf2';
function MainView({sendFieldInfoToApp, sendFacilitiesInfoToApp ,instanceInfotoApp, userInfotoApp,sendBiomassToApp, sendSumAreaToApp,sendMoistureMetalToApp, sendBiomassPerContToApp ,userInputtoApp, passContaminationAndZoneToApp }) {

  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const [buttonInnerText, setButtonInnerText] = useState(false);
  const [zone, setZone]=useState();

  const [allInfoField, setAllInfoField] = useState([]);
  const [allInfoFacilities, setAllInfoFacilities] = useState([]);
  const [fieldsFromCsv, setFieldsFromCsv] =useState([]);



  function passContaminationAndZoneToMain(dataForBiomassML)
  {
    console.log("ML INFO: ",  dataForBiomassML)
    passContaminationAndZoneToApp(dataForBiomassML)
  }

  function passImportedFields(parsedData)
  {
    setFieldsFromCsv(parsedData)
    //console.log("PARSED:", fieldsFromCsv)
  }

 
  function passUserInput(data)
  {
    userInfotoApp(data)
  }

  function passInstanceInfo(result)
  {
    //console.log("DATA TO MAIN FROM RIGHT", result );
    instanceInfotoApp(result);
  }

  function passBiomassInfo(biomass)
  {
    //console.log("DATA TO MAIN FROM RIGHT BIOMASS", biomass );
    sendBiomassToApp(biomass)
  }
  function passSumofArea(sumOfArea)
  {
    //console.log("DATA TO MAIN FROM RIGHT Sum of AreaS", sumOfArea );
    sendSumAreaToApp(sumOfArea);
  }
  
  function passMoistureMetal(moisture_metal)
  {
    //console.log("DATA TO MAIN FROM RIGHT MoistureMetal", moisture_metal );
    sendMoistureMetalToApp(moisture_metal);
  }


  function passBiomassPerCont(per_contaminant)
  {
    //console.log("DATA TO MAIN FROM RIGHT PER CONTAMINANT", per_contaminant );
    sendBiomassPerContToApp(per_contaminant);
  }

  function closeSideBar() {
    setSidebarIsOpen(!sidebarIsOpen)
    setButtonInnerText(!buttonInnerText)
  }

  function createArrayOfFields(array)
  {
    
    setAllInfoField(array);
    //console.log("DATA MAIN TO APP", allInfoField)
    sendFieldInfoToApp(array);
  }
  function createArrayOfFacilities(arrayFacilities)
  {
    setAllInfoFacilities(arrayFacilities);
    sendFacilitiesInfoToApp(arrayFacilities);
  }
  
  function createZone(mostCommon)
  {
    setZone(mostCommon)
  }
  

  ////*********FOR CHECK ----TO BE REMOVED */
  // if (allInfoField.length>0 )
  // {
  //   console.log("we receive field",allInfoField);
  // }
  // if (allInfoFacilities.length>0 )
  // {
  //   console.log("we receive facilities",allInfoFacilities);
  // }



  const [guidance1,setGuidance1]=useState(true)
  const [guidance2,setGuidance2]=useState(false)
  // useEffect(()=>{
  //   Swal.fire({
  //     title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Select Contaminated Land Areas</h5>`,
  //     html: "<div style='margin:15px; font-size:18px; font-weight:600;'>Step 1: Select Contaminated Land Areas</div>",
  //     confirmButtonColor: "green",
  //     confirmButtonText: "OK",
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       setGuidance1(false)
  //       setGuidance2(true)
  //       Swal.fire({
  //         title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Select Contaminated Land Areas</h5>`,
  //         html: "<div style='margin:15px; font-size:18px; font-weight:600;'>Step 2: Choose your biomass.</div>",
  //         confirmButtonColor: "green",
  //         confirmButtonText: "OK",
  //         allowOutsideClick: false,
  //       }).then((result) => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (result.isConfirmed) {
  //           setGuidance2(false)
  //         }
  //       });
  //     }
  //   });
  // },[])
  
  return (

    <div className="Main">
      <div top></div>
      <div className="sidebar">
        <Sidebar currentView="main"/>
      </div>
      <div className='main_view'>
       {/* {guidance1?<div style={{transition:"3s",background:"rgba(255, 9, 1, 0.5)", zIndex:10, height:"250px", width:"60px", position:"absolute", borderRadius:"5px", }}> </div>:<></>}  */}

        <Mapleaf3 createArrayOfFields={createArrayOfFields} createArrayOfFacilities={createArrayOfFacilities} createZone={createZone} gotFieldsFromCsv={fieldsFromCsv}/>
      </div>
      <button className='closebutton' onClick={closeSideBar}>{buttonInnerText ? <div>details</div> : <div>close</div>}</button>
      {sidebarIsOpen ? <div className="sidebar-right">
     {/* {guidance2?<div style={{transition:"3s",background:"rgba(255, 9, 1, 0.5)", zIndex:10, height:"7%", width:"12%", position:"absolute", borderRadius:"5px", bottom:10,right:75 }}> </div>:<></>} */}

        <SideBarRight arrayOfFields={allInfoField} arrayOfFacilities={allInfoFacilities} passInstanceInfo={passInstanceInfo} passUserInput={passUserInput} passBiomassInfo={passBiomassInfo} passSumofArea={passSumofArea} passMoistureMetal={passMoistureMetal} passBiomassPerCont={passBiomassPerCont} zone={zone} passImportedFields={passImportedFields} passContaminationAndZoneToMain={passContaminationAndZoneToMain}/>
      </div> : <div className="sidebar-right-closed"><SideBarRight arrayOfFields={allInfoField} arrayOfFacilities={allInfoFacilities} passInstanceInfo={passInstanceInfo} passUserInput={passUserInput}/></div>}
    </div>
  );
}

export default MainView;
