import { DataGrid } from "@mui/x-data-grid";
import "../src/scss/multicriteria.scss";
import Sidebar from "./components/SideBar";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip, alertTitleClasses } from "@mui/material";
import { BarChart, ReferenceLine } from '@mui/x-charts';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EuroIcon from '@mui/icons-material/Euro';
import environment from './images/environment.png';
import relevance from './images/relevance.png';
import scale from './images/scale.png';
import {
    buildLinearFunction,
    calculatePrometheeOne,
    calculatePrometheeTwo,
} from 'promethee';
import { red } from "@mui/material/colors";
import { INFOTEXTHOVER } from "./components/constatstext";
import InfoIcon from '@mui/icons-material/Info';
import Swal from "sweetalert2";
//import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';



const columns = [
    { field: "kpi", headerName: "KPIs", flex: 1, sortable: false },
    {
        field: "significance", headerName: "Significance Factor", editable: true, flex: 0.5,
        renderCell: (params) => (
            <div className="cell" style={{ width: '100%', height: '50%', textAlign: "center", cursor: "pointer", verticalAlign: "center" }}>
                {params.value}
            </div>
        ),
    },
]

const kpiEnergyRows = [
    { id: 1, kpi: "Primary Energy Demand", significance: 100 },
    { id: 2, kpi: 'Productivity ', significance: 200 },
    { id: 3, kpi: 'Global Warming Potential', significance: 200 },
    { id: 4, kpi: 'Emissions', significance: 200 },
    { id: 5, kpi: 'Off-take of contaminants', significance: 200 },
    { id: 6, kpi: 'Decontamination efficiency', significance: 200 },
    { id: 7, kpi: 'Avoided land-use change', significance: 200 },
    { id: 8, kpi: 'Total Life Cycle Cost ', significance: 200 },
    { id: 9, kpi: 'Net Present Value', significance: 200 },
    { id: 10, kpi: 'Internal Rate of Return', significance: 200 },
    { id: 11, kpi: 'Levelized Cost of (bio) Energy', significance: 200 },
    { id: 12, kpi: 'Jobs Creation', significance: 200 },
    { id: 13, kpi: 'Social acceptability', significance: 200 },

]
function useApiRef() {
    const apiRef = useRef(null);
    const _columns = useMemo(
        () =>
            columns.concat({
                field: "",
                width: 0,
                hide: true,
                flex: 0,
                renderCell: (params) => {
                    apiRef.current = params.api;
                    return null;
                }
            }),
        [columns]
    );

    return { apiRef, columns: _columns };
}

function replaceDotAndComma(input) {
    // Check if input is an array
    if (Array.isArray(input)) {
        return input.map(replaceDotAndComma); // Recursively apply function to each element
    }

    // Check if input is a string
    if (typeof input === 'string') {
        // Replace periods with commas and commas with periods
        return input.replace(/\./g, 'TEMP').replace(/,/g, '.').replace(/TEMP/g, ',');
    }

    // Return input as-is if it's neither an array nor a string
    return input;
}

function Multicriteria({ kpis, identifiers }) {

    

    const [locale, setLocale]=useState("no")
    //var identifiersCaps=[];
    for (let i = 0; i < identifiers.length; i++) {

        if (identifiers[i].includes("fp")) {
            identifiers[i] = identifiers[i].replace("fp", "PYR")
        }
        if (identifiers[i].includes("scwg")) {
            identifiers[i] = identifiers[i].replace("scwg", "SCWG")
        }
    }
    //console.log(kpis, identifiers)
    
    // var total_lcc=kpis[0]
    // var npv=kpis[1]
    // var irr=kpis[2]
    // var levelized_cost=kpis[3]
    // var ped=kpis[4]
    // var productivity=kpis[5]
    // var gwp=kpis[6]
    // var emissions=kpis[7]
    // var off_take=kpis[8]
    // var decontaminaion=kpis[9]
    // var land_use=kpis[10]
    // var jobCreation=kpis[1]
    // var social_acceptability=kpis[1]
    const [total_lcc, setTotal_lcc] = useState(kpis[0]);
    const [npv, setNpv] = useState(kpis[1]);
    const [irr, setIrr] = useState(kpis[2]) ;
    const [levelized_cost, setLevelized_cost] = useState(kpis[3]);
    const [ped, setPed] = useState(kpis[4]);
    const [productivity, setProductivity] = useState(kpis[5]);
    const [gwp, setGwp] = useState(kpis[6]) ;
    const [emissions, setEmissions] = useState(kpis[7]) ;
    const [off_take, setOff_take] = useState(kpis[8]) ;
    const [decontaminaion, setDecontamination] = useState(kpis[9]);
    const [land_use, setLand_use] = useState(kpis[10]) ;
    const [jobCreation, setJobCreation] = useState(kpis[11]);
    const [social_acceptability, setSocial_acceptability] = useState(kpis[12]);
    //useState(kpis[12])

    //console.log("CLEAN", typeof kpis[0][0], total_lcc)
    const columnsTransposed = kpis[0].map((_, colIndex) => kpis.map(row => row[colIndex]));

    // Store each column in a separate array
    const [arrayOfArrays, setArrayOfArrays] = useState(columnsTransposed.map(column => [...column]));
    //console.log(arrayOfArrays)


    const groupingModelMulticriteria = [
        {
            groupId: 'Multicriteria Results',
            headerAlign: 'center',
            description: "",
            children: [

                { field: "id" },
                { field: "identifier" },
                { field: "f" },
            ],
            headerClassName: 'headerTheming'
        }
    ]

    const [tableRows, setTableRows] = useState([]);
    const [barChartHeaders, setBarChartHeaders] = useState([]);
    const [barChartData, setBarChartData] = useState([]);

    const [hoverEco, setHoverEco] = useState(false);
    const [hoverEnv, setHoverEnv] = useState(false);
    const [hoverSocial, setHoverSocial] = useState(false);


    const [wfEconomic, setWfEconomic] = useState(34);
    const [wfEnvironmental, setWfEnvironmental] = useState(33)
    const [wfSocial, setWfSocial] = useState(100 - (wfEconomic + wfEnvironmental))

    //economic -input significance
    const [sigTotalLCcost, setSigTotalLCcost] = useState(7.5);
    const [sigNetPresentValue, setSigNetPresentValue] = useState(7);
    const [sigIRR, setSigIRR] = useState(7.00);
    const [sigLevelizedCost, setSigLevelizedCost] = useState(8);

    //environmental - input significance
    const [sigPED, setSigPED] = useState(8);
    const [sigProductivity, setSigProductivity] = useState(8);
    const [sigGWP, setSigGWP] = useState(9);
    const [sigEmmisions, setSigEmmisions] = useState(6.5);
    const [sigOffCont, setSigOffCont] = useState(7.5);
    const [sigDecontaminationEf, setSigDecontaminationEf] = useState(7);
    const [sigAvoid, setSigAvoid] = useState(6.5);

    //socil - iput significance
    const [sigJob, setSigJob] = useState(6.5);
    const [sigSocialAcc, setSigSocialAcc] = useState(7.5);

    const { apiRef, columns } = useApiRef();
    const [forceUpdate, setForceUpdate] = useState(true);
    

   
    const handleClickButton = () => {     
        setForceUpdate(prev => !prev);
        //console.log(apiRef.current.getRowModels());
        // tableData = (apiRef.current.getRowModels());
        // console.log(tableData.get(1))

        //cleaering the previous info and results
        setTableRows([]);
        setBarChartHeaders([]);
        setBarChartData([]);
        //PROMETHEE AND MULTI

        let theUnderneathEconomic = sigTotalLCcost + sigNetPresentValue + sigIRR + sigLevelizedCost;
        let theUnderneathEnvironmental = sigPED + sigProductivity + sigGWP + sigEmmisions + sigOffCont + sigDecontaminationEf + sigAvoid;
        let theUnderneathSocial = sigJob + sigSocialAcc;

        //console.log("YO", Math.max.apply(Math, total_lcc), "meg", Math.max.apply(Math, npv), npv)
        let criteria = [
            {
                name: 'Total Life Cycle Cost',
                weight: (sigTotalLCcost / theUnderneathEconomic) * (wfEconomic / 100),
                objective: 'minimize',
                preferenceFunction: buildLinearFunction({
                    // preference: 0.2 * (5000 - 1000),
                    // indifference: 0.05 * (5000-100),
                    preference: 0.2 * (Math.max.apply(Math, total_lcc) - Math.min.apply(Math, total_lcc)),
                    indifference: 0.05 * (Math.max.apply(Math, total_lcc) - Math.min.apply(Math, total_lcc)),
                }),
            }, {
                name: 'Net Present Value',
                weight: (sigNetPresentValue / theUnderneathEconomic) * (wfEconomic / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, npv) - Math.min.apply(Math, npv)),
                    indifference: 0.05 * (Math.max.apply(Math, npv) - Math.min.apply(Math, npv)),
                }),
            },
            {
                name: 'Internal Rate of Return',
                weight: (sigIRR / theUnderneathEconomic) * (wfEconomic / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, irr) - Math.min.apply(Math, irr)),
                    indifference: 0.05 * (Math.max.apply(Math, irr) - Math.min.apply(Math, irr)),
                }),
            },
            {
                name: 'Levelized Cost of (bio) Energy',
                weight: (sigLevelizedCost / theUnderneathEconomic) * (wfEconomic / 100),
                objective: 'minimize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, levelized_cost) - Math.min.apply(Math, levelized_cost)),
                    indifference: 0.05 * (Math.max.apply(Math, levelized_cost) - Math.min.apply(Math, levelized_cost)),
                }),
            },

            {
                name: 'Primary Energy Demand',
                weight: (sigPED / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'minimize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, ped) - Math.min.apply(Math, ped)),
                    indifference: 0.05 * (Math.max.apply(Math, ped) - Math.min.apply(Math, ped)),
                }),
            },

            {
                name: 'Productivity ',
                weight: (sigProductivity / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, productivity) - Math.min.apply(Math, productivity)),
                    indifference: 0.05 * (Math.max.apply(Math, productivity) - Math.min.apply(Math, productivity)),
                }),
            },

            {
                name: 'Global Warming Potential',
                weight: (sigGWP / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'minimize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, gwp) - Math.min.apply(Math, gwp)),
                    indifference: 0.05 * (Math.max.apply(Math, gwp) - Math.min.apply(Math, gwp)),
                }),
            },

            {
                name: 'Emissions',
                weight: (sigEmmisions / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'minimize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, emissions) - Math.min.apply(Math, emissions)),
                    indifference: 0.05 * (Math.max.apply(Math, emissions) - Math.min.apply(Math, emissions)),
                }),
            },
            {
                name: 'Off-take of contaminants',
                weight: (sigOffCont / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, off_take) - Math.min.apply(Math, off_take)),
                    indifference: 0.05 * (Math.max.apply(Math, off_take) - Math.min.apply(Math, off_take)),
                }),
            },
            {
                name: 'Decontamination efficiency ',
                weight: (sigDecontaminationEf / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, decontaminaion) - Math.min.apply(Math, decontaminaion)),
                    indifference: 0.05 * (Math.max.apply(Math, decontaminaion) - Math.min.apply(Math, decontaminaion)),
                }),
            },
            {
                name: 'Avoided land-use change',
                weight: (sigAvoid / theUnderneathEnvironmental) * (wfEnvironmental / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, land_use) - Math.min.apply(Math, land_use)),
                    indifference: 0.05 * (Math.max.apply(Math, land_use) - Math.min.apply(Math, land_use)),
                }),
            },
            {
                name: 'Jobs Creation',
                weight: (sigJob / theUnderneathSocial) * (wfSocial / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, jobCreation) - Math.min.apply(Math, jobCreation)),
                    indifference: 0.05 * (Math.max.apply(Math, jobCreation) - Math.min.apply(Math, jobCreation)),
                }),
            },
            {
                name: 'Social acceptability',
                weight: (sigSocialAcc / theUnderneathSocial) * (wfSocial / 100),
                objective: 'maximize',
                preferenceFunction: buildLinearFunction({
                    preference: 0.2 * (Math.max.apply(Math, social_acceptability) - Math.min.apply(Math, social_acceptability)),
                    indifference: 0.05 * (Math.max.apply(Math, social_acceptability) - Math.min.apply(Math, social_acceptability)),
                }),
            },
        ];

        const alternatives = arrayOfArrays.map((column, index) => ({
            identifier: identifiers[index],
            evaluations: column,
        }));
    
        // Perform PROMETHEE calculation
        var result = calculatePrometheeTwo({ alternatives, criteria });
    
        // Check for zero liquidFlux and update KPIs if necessary
        if (result[0].liquidFlux === 0) {
            
            const updatedKPIs = [
                replaceDotAndComma(kpis[0]) || [],
                replaceDotAndComma(kpis[1]) || [],
                replaceDotAndComma(kpis[2]) || [],
                replaceDotAndComma(kpis[3]) || [],
                replaceDotAndComma(kpis[4]) || [],
                replaceDotAndComma(kpis[5]) || [],
                replaceDotAndComma(kpis[6]) || [],
                replaceDotAndComma(kpis[7]) || [],
                replaceDotAndComma(kpis[8]) || [],
                replaceDotAndComma(kpis[9]) || [],
                replaceDotAndComma(kpis[10]) || [],
                replaceDotAndComma(kpis[11]) || [],
                replaceDotAndComma(kpis[12]) || [],
            ];
    
            setTotal_lcc(updatedKPIs[0]);
            setNpv(updatedKPIs[1]);
            setIrr(updatedKPIs[2]);
            setLevelized_cost(updatedKPIs[3]);
            setPed(updatedKPIs[4]);
            setProductivity(updatedKPIs[5]);
            setGwp(updatedKPIs[6]);
            setEmissions(updatedKPIs[7]);
            setOff_take(updatedKPIs[8]);
            setDecontamination(updatedKPIs[9]);
            setLand_use(updatedKPIs[10]);
            setJobCreation(updatedKPIs[11]);
            setSocial_acceptability(updatedKPIs[12]);
    
            // Update arrayOfArrays in state
            setArrayOfArrays(prevArrayOfArrays => replaceDotAndComma(prevArrayOfArrays));


            
    
        }
    
        // Prepare new data for tableRows and barChart
        const newTableRows = result.map((r, i) => ({ id: i + 1, identifier: r.alternativeIdentifier, f: r.liquidFlux }));
        const newBarChartHeaders = result.map(r => r.alternativeIdentifier);
        const newBarChartData = result.map(r => r.liquidFlux);
    
        // Update state with new data
        setTableRows(newTableRows);
        setBarChartHeaders(newBarChartHeaders);
        setBarChartData(newBarChartData);
    
        // Logging for debugging
        //console.log(newTableRows);
        //console.log(newBarChartHeaders);
        //console.log(newBarChartData);
    };


    const tableColumns = [
        { field: "id", headerName: "Ranking", flex: 0.5, headerClassName: "customHead", headerAlign: "center" },
        { field: "identifier", headerName: "Identifier", flex: 1.5, headerClassName: "customHead", headerAlign: "center" },
        { field: "f", headerName: "Φ Score", flex: 2.5, headerClassName: "customHead", headerAlign: "center" },
    ]


    function handleChangeSignificanceFactor(e) {
        if (isNaN(parseFloat(e.target.value))) {
            e.target.value = 0

        }
        if (parseFloat(e.target.value) < 0) {
            e.target.value = 0
        }
        if (parseFloat(e.target.value) > 10) {
            e.target.value = 10
        }
        let sigName = e.target.name;
        switch (sigName) {
            case 'sigTotalLCcost':
                setSigTotalLCcost(parseFloat(e.target.value));
                break;
            case 'sigNetPresentValue':
                setSigNetPresentValue(parseFloat(e.target.value));
                break;
            case 'sigIRR':
                setSigIRR(parseFloat(e.target.value));
                break;
            case 'sigLevelizedCost':
                setSigLevelizedCost(parseFloat(e.target.value));
                break;
            case 'sigPED':
                setSigPED(parseFloat(e.target.value));
                break;
            case 'sigProductivity':
                setSigProductivity(parseFloat(e.target.value));
                break;
            case 'sigGWP':
                setSigGWP(parseFloat(e.target.value));
                break;
            case 'sigEmmisions':
                setSigEmmisions(parseFloat(e.target.value));
                break;
            case 'sigOffCont':
                setSigOffCont(parseFloat(e.target.value));
                break;
            case 'sigDecontaminationEf':
                setSigDecontaminationEf(parseFloat(e.target.value));
                break;
            case 'sigAvoid':
                setSigAvoid(parseFloat(e.target.value));
                break;
            case 'sigJob':
                setSigJob(parseFloat(e.target.value));
                break;
            case 'sigSocialAcc':
                setSigSocialAcc(parseFloat(e.target.value));
                break;
            // ... add other cases as needed
            default:
                console.log("wrong")
                break;
        }
    }

    useEffect(() => {
        if (wfEconomic + wfEnvironmental > 100 && wfEconomic > wfEnvironmental) {
            setWfEnvironmental(100 - wfEconomic)
        }
        if (wfEconomic + wfEnvironmental > 100 && wfEnvironmental > wfEconomic) {
            setWfEconomic(100 - wfEnvironmental)
        }
        setWfSocial(100 - wfEconomic - wfEnvironmental)

        if (wfSocial < 0) {
            setWfSocial(0)
        }
    }, [wfEconomic, wfEnvironmental])


    //function to handle the change in weight factors
    function handleChangeWeightFactor(e) {
        let fieldName = e.target.name;

        if (fieldName === "wfEconomic") {
            setWfEconomic(parseFloat(e.target.value))
            if (e.target.value === "") {
                setWfEconomic(0)
            }
            if (e.target.value > 100) {
                setWfEconomic(100)
            }
        }
        if (fieldName === "wfEnvironmental") {
            setWfEnvironmental(parseFloat(e.target.value))
            if (e.target.value === "") {
                setWfEnvironmental(0)
            }
            if (e.target.value > 100) {
                setWfEnvironmental(100)
            }
        }


    }


    const [showInfo, setShowInfo] = useState(false);
    const [text, setText] = useState("");
    const [header, setHeader] = useState("");

    const [label1, setLabel1] = useState(0);
    const [label2, setLabel2] = useState(0);
    const [label3, setLabel3] = useState(0);
    const [label4, setLabel4] = useState(0);
    const [label5, setLabel5] = useState(0);
    const [label6, setLabel6] = useState(0);
    const [label7, setLabel7] = useState(0);
    const [label8, setLabel8] = useState(0);
    const [label9, setLabel9] = useState(0);
    const [label10, setLabel10] = useState(0);
    const [label11, setLabel11] = useState(0);
    const [label12, setLabel12] = useState(0);
    const [label13, setLabel13] = useState(0);


    const hoverLabel1 = () => { setLabel1(1); setShowInfo(true); setText(INFOTEXTHOVER[0].text); setHeader(INFOTEXTHOVER[0].header); };
    const hoverLabel2 = () => { setLabel2(2); setShowInfo(true); setText(INFOTEXTHOVER[1].text); setHeader(INFOTEXTHOVER[1].header); };
    const hoverLabel3 = () => { setLabel3(3); setShowInfo(true); setText(INFOTEXTHOVER[2].text); setHeader(INFOTEXTHOVER[2].header); };
    const hoverLabel4 = () => { setLabel4(4); setShowInfo(true); setText(INFOTEXTHOVER[3].text); setHeader(INFOTEXTHOVER[3].header); };
    const hoverLabel5 = () => { setLabel5(5); setShowInfo(true); setText(INFOTEXTHOVER[4].text); setHeader(INFOTEXTHOVER[4].header); };
    const hoverLabel6 = () => { setLabel6(6); setShowInfo(true); setText(INFOTEXTHOVER[5].text); setHeader(INFOTEXTHOVER[5].header); };
    const hoverLabel7 = () => { setLabel7(7); setShowInfo(true); setText(INFOTEXTHOVER[6].text); setHeader(INFOTEXTHOVER[6].header); };
    const hoverLabel8 = () => { setLabel8(8); setShowInfo(true); setText(INFOTEXTHOVER[7].text); setHeader(INFOTEXTHOVER[7].header); };
    const hoverLabel9 = () => { setLabel9(9); setShowInfo(true); setText(INFOTEXTHOVER[8].text); setHeader(INFOTEXTHOVER[8].header); };
    const hoverLabel10 = () => { setLabel10(10); setShowInfo(true); setText(INFOTEXTHOVER[9].text); setHeader(INFOTEXTHOVER[9].header); };
    const hoverLabel11 = () => { setLabel11(11); setShowInfo(true); setText(INFOTEXTHOVER[10].text); setHeader(INFOTEXTHOVER[10].header); };
    const hoverLabel12 = () => { setLabel12(12); setShowInfo(true); setText(INFOTEXTHOVER[11].text); setHeader(INFOTEXTHOVER[11].header); };
    const hoverLabel13 = () => { setLabel13(13); setShowInfo(true); setText(INFOTEXTHOVER[12].text); setHeader(INFOTEXTHOVER[12].header); };

    const outOfLabel1 = () => { setLabel1(0); setShowInfo(false) };
    const outOfLabel2 = () => { setLabel2(0); setShowInfo(false) };
    const outOfLabel3 = () => { setLabel3(0); setShowInfo(false) };
    const outOfLabel4 = () => { setLabel4(0); setShowInfo(false) };
    const outOfLabel5 = () => { setLabel5(0); setShowInfo(false) };
    const outOfLabel6 = () => { setLabel6(0); setShowInfo(false) };
    const outOfLabel7 = () => { setLabel7(0); setShowInfo(false) };
    const outOfLabel8 = () => { setLabel8(0); setShowInfo(false) };
    const outOfLabel9 = () => { setLabel9(0); setShowInfo(false) };
    const outOfLabel10 = () => { setLabel10(0); setShowInfo(false) };
    const outOfLabel11 = () => { setLabel11(0); setShowInfo(false) };
    const outOfLabel12 = () => { setLabel12(0); setShowInfo(false) };
    const outOfLabel13 = () => { setLabel13(0); setShowInfo(false) };


    useEffect(()=>{
        if (kpis[0][0].includes("."))
            {
                const updatedKPIs = [
                    replaceDotAndComma(kpis[0]) || [],
                    replaceDotAndComma(kpis[1]) || [],
                    replaceDotAndComma(kpis[2]) || [],
                    replaceDotAndComma(kpis[3]) || [],
                    replaceDotAndComma(kpis[4]) || [],
                    replaceDotAndComma(kpis[5]) || [],
                    replaceDotAndComma(kpis[6]) || [],
                    replaceDotAndComma(kpis[7]) || [],
                    replaceDotAndComma(kpis[8]) || [],
                    replaceDotAndComma(kpis[9]) || [],
                    replaceDotAndComma(kpis[10]) || [],
                    replaceDotAndComma(kpis[11]) || [],
                    replaceDotAndComma(kpis[12]) || [],
                ];
        
                setTotal_lcc(updatedKPIs[0]);
                setNpv(updatedKPIs[1]);
                setIrr(updatedKPIs[2]);
                setLevelized_cost(updatedKPIs[3]);
                setPed(updatedKPIs[4]);
                setProductivity(updatedKPIs[5]);
                setGwp(updatedKPIs[6]);
                setEmissions(updatedKPIs[7]);
                setOff_take(updatedKPIs[8]);
                setDecontamination(updatedKPIs[9]);
                setLand_use(updatedKPIs[10]);
                setJobCreation(updatedKPIs[11]);
                setSocial_acceptability(updatedKPIs[12]);
        
                // Update arrayOfArrays in state
                setArrayOfArrays(prevArrayOfArrays => replaceDotAndComma(prevArrayOfArrays));
            }
        },[])
      

    return (

        <div className="multicriteria_outer">
            <div className="multicriteria_title">
                Multicriteria Assessment
            </div>

            <div className="multicriteria_main" >
                <div className="signiHeader" >
                    <div className="multicriteria_title" style={{ color: "white", fontSize: "20px", borderRadius: "5px 5px 0px 0px", width: "93%", margin: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}><img src={relevance} style={{ width: "25px", marginRight: "10px" }} />KPIs' Significance <Tooltip placement="right-end" title={<Fragment> <div style={{ fontSize: "14px" }}>In this section, you can see the significance scores assigned to various Key Performance Indicators (KPIs) used in optimizing biomass supply chains from contaminated land areas. Each KPI is given a score indicating its relative importance in the analysis.
                        You can set your own significance scores for each KPI based on its importance to your analysis. Enter a percentage value that reflects its significance, ensuring that the total percentage for all KPIs adds up to 100%.
                        After assigning significance scores, click assess to evaluate and rank alternative scenarios based on the weighted scores of the selected KPIs. PROMETHEE calculates a preference index, denoted as the φ (phi) score, for each alternative scenario. This score quantifies the overall preference of each scenario relative to others, considering the assigned significance of each KPI. Higher φ scores indicate greater overall preference.
                    </div></Fragment>}><InfoIcon style={{ cursor: "pointer", marginLeft: "30px", height: '35px' }} /></Tooltip></div>
                    <div className="general_trial" style={{ justifyContent: "space-between" }}>
                        <div className="multicriteria_significance">
                            <div className="multicriteria_kpis">

                                <div className={hoverEco || label1 !== 0 ? "economic_label" : "no_label"} onMouseEnter={hoverLabel1} onMouseLeave={outOfLabel1}><label >Total Life Cycle Cost</label></div>
                                <div className={hoverEco || label2 !== 0 ? "economic_label" : "no_label"} onMouseEnter={hoverLabel2} onMouseLeave={outOfLabel2}><label >Net Present Value</label></div>
                                <div className={hoverEco || label3 !== 0 ? "economic_label" : "no_label"} onMouseEnter={hoverLabel3} onMouseLeave={outOfLabel3}><label>Internal Rate of Return</label></div>
                                <div className={hoverEco || label4 !== 0 ? "economic_label" : "no_label"} onMouseEnter={hoverLabel4} onMouseLeave={outOfLabel4}><label >Levelized Cost of (bio) Energy</label></div>
                                <div className={hoverEnv || label5 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel5} onMouseLeave={outOfLabel5}><label>Primary Energy Demand</label></div>
                                <div className={hoverEnv || label6 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel6} onMouseLeave={outOfLabel6}><label>Productivity </label></div>
                                <div className={hoverEnv || label7 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel7} onMouseLeave={outOfLabel7}><label>Global Warming Potential</label></div>
                                <div className={hoverEnv || label8 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel8} onMouseLeave={outOfLabel8}><label>Acidification Potential </label></div>
                                <div className={hoverEnv || label9 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel9} onMouseLeave={outOfLabel9}><label>Off-take of Contaminants</label></div>
                                <div className={hoverEnv || label10 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel10} onMouseLeave={outOfLabel10}><label>Decontamination Efficiency </label></div>
                                <div className={hoverEnv || label11 !== 0 ? "environmental_label" : "no_label"} onMouseEnter={hoverLabel11} onMouseLeave={outOfLabel11}><label>Land under Phytoremediation</label></div>
                                <div className={hoverSocial || label12 !== 0 ? "social_label" : "no_label"} onMouseEnter={hoverLabel12} onMouseLeave={outOfLabel12}><label>Job creation</label></div>
                                <div className={hoverSocial || label13 !== 0 ? "social_label" : "no_label"} onMouseEnter={hoverLabel13} onMouseLeave={outOfLabel13}><label>Social Acceptability</label></div>

                            </div>
                            <div className="multicriteria_significance_factor">
                                <div><input type="number" step="0.5" name="sigTotalLCcost" value={sigTotalLCcost} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigNetPresentValue" value={sigNetPresentValue} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigIRR" value={sigIRR} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigLevelizedCost" value={sigLevelizedCost} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigPED" value={sigPED} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigProductivity" value={sigProductivity} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigGWP" value={sigGWP} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigEmmisions" value={sigEmmisions} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigOffCont" value={sigOffCont} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigDecontaminationEf" value={sigDecontaminationEf} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigAvoid" value={sigAvoid} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigJob" value={sigJob} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                                <div><input type="number" step="0.5" name="sigSocialAcc" value={sigSocialAcc} style={{ textAlign: "center", width: "40px", MozAppearance: 'textfield' }} onChange={handleChangeSignificanceFactor}></input></div>
                            </div>


                        </div>
                        <div className={showInfo ? "kpi_explanation" : "kpi_none"}>
                            <div style={{ padding: "2px" }}>
                                <div style={{ textAlign: "center" }}>{header}</div><br></br>{text}
                            </div>

                        </div>
                    </div>
                </div>

                {/* <hr style={{ borderLeft: "1px solid green", height: "290px", marginTop: "4%",marginLeft:"-6%" }}></hr> */}
                <div className="multicriteria_weight_factors">
                    <div className="multicriteria_title" style={{ color: "white", fontSize: "20px", borderRadius: "5px 5px 0px 0px", display: "flex", justifyContent: "center", alignItems: "center" }}><img src={scale} style={{ width: "25px", marginRight: "10px" }} />Weighting Factor<Tooltip placement="right-end" title={<Fragment> <div style={{ fontSize: "14px" }}>In this section, you can allocate the relative importance of different categories of KPIs to reflect your priorities in the biomass supply chain optimization. The weight factors are percentages that must sum to 100%.</div></Fragment>}><InfoIcon style={{ cursor: "pointer", marginLeft: "30px", height: '35px' }} /></Tooltip></div>
                    <div className="outer_weight_factors">
                        <div className="weight_factors">
                            <div className="economic_wf" onMouseEnter={() => setHoverEco(true)} onMouseLeave={() => setHoverEco(false)}><EuroIcon style={{ marginRight: "15px" }} /><label>Economic KPIS Weight Factor</label></div>
                            <div className="environmental_wf" onMouseEnter={() => setHoverEnv(true)} onMouseLeave={() => setHoverEnv(false)}><img src={environment} alt="environmental icon" style={{ width: "25px", marginRight: "15px" }} /><label>Environmental KPIS Weight Factor </label></div>
                            <div className="social_wf" onMouseEnter={() => setHoverSocial(true)} onMouseLeave={() => setHoverSocial(false)}><Diversity3Icon style={{ marginRight: "15px" }} /><label>Social KPIS Weight Factor</label></div>
                        </div>
                        <div className="weight_factors_input">
                            <div><input name="wfEconomic" value={wfEconomic} onChange={handleChangeWeightFactor}></input><span style={{ fontSize: "18px" }}>%</span></div>
                            <div><input name="wfEnvironmental" value={wfEnvironmental} onChange={handleChangeWeightFactor}></input><span style={{ fontSize: "18px" }}>%</span></div>
                            <div><input name="wfSocial" value={wfSocial} onChange={handleChangeWeightFactor} disabled></input><span style={{ fontSize: "18px" }}>%</span></div>
                        </div>

                    </div>
                    {hoverEnv ? <div className="weight_factors_info">
                        <div className="inner_info">This represents the importance of environmental performance measures, such as emissions and energy demand. Adjust this percentage based on how crucial environmental impacts are to your decision-making.</div>
                    </div> :
                        hoverEco ? <div className="weight_factors_info"><div className="inner_info">
                            This represents the importance of financial performance measures, such as costs and profitability. Adjust this percentage based on how crucial economic outcomes are to your decision-making.</div></div> :
                            hoverSocial ? <div className="weight_factors_info"><div className="inner_info">
                                This represents the importance of social performance measures, such as job creation and social acceptability. Adjust this percentage based on how crucial social outcomes are to your decision-making. By adjusting Economic or Environmental KPIs weight factors, the Social KPI weight factor is automatically adjusted to allow the sum to remain 100%.</div></div> : <></>}
                </div>
            </div>

            <button className="button_multi" onClick={handleClickButton}>
                Assess
            </button>
            {tableRows.length > 0 ?
                <div className="multicriteria_results">
                    <div className="multicriteria_datagrid"><DataGrid
                        rows={tableRows}
                        columns={tableColumns}
                        sx={{
                            '& .headerTheming': {
                                fontSize: "18px",
                                background: "rgb(202, 255, 194)",
                            },
                            "& .customHead": {
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "18px",
                            },
                            "& .MuiDataGrid-cell": {
                                justifyContent: "center",
                            },
                        }}

                        experimentalFeatures={{ columnGrouping: true }}
                        columnGroupingModel={groupingModelMulticriteria}
                    /></div><div className="multicriteria_barchart"><BarChart
                        yAxis={[{}]}
                        xAxis={[{
                            scaleType: 'band', data: barChartHeaders, tickLabelStyle: {
                                angle: -10, fontSize: 12, textAnchor: 'end',
                            },
                        }]}
                        series={[{
                            data: barChartData, label: 'Φ Score',

                        }]}

                        width={620}

                        height={400}>

                    </BarChart>
                    </div></div> : <></>}
        </div>

    )
}

export default Multicriteria;


{/* <DataGrid
                        rowHeight={22}
                        showCellRightBorder={true}
                        showColumnVerticalBorder={true}
                        rows={kpiEnergyRows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 15,
                                },
                            },
                        }}
                        slotProps={{
                            row: {
                                "&.Mui-selected": { backgroundColor: "red", }
                            }
                        }}
                        sx={{
                            "& .editable:hover": {
                                backgroundColor: `rgba(255, 179, 102, 0.4)`,
                                cursor: "pointer",
                            },
                            "&.MuiDataGrid-cell-field-significance:hover": {
                                backgroundColor: `rgba(255, 179, 102, 0.4)`,
                                cursor: "pointer"
                            }
                        }}

                        pageSizeOptions={[13]}
                        disableRowSelectionOnClick

                    /> */}