import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles, makeStyles } from "@material-ui/core/styles";



function TableTrial() {
    const [columns, setColumns] = useState([
        { id: 'scenario1', label: 'Scenario 1' },
        { id: 'scenario2', label: 'Scenario 2' },
        // ... add more columns as needed
    ]);

    const useStyles = makeStyles({
        table: {
            minWidth: 650
        },
        tableRow: {
            height: 30
        },
        tableCell: {
            padding: "0px 16px"
        }
    });
    const classes = useStyles();
    const StyledTableRow = withStyles((theme) => ({
        root: {
            height: 10
        }
    }))(TableRow);

    const StyledTableCell = withStyles((theme) => ({
        root: {
            padding: "0px 16px"
        }
    }))(TableCell);

    const idColumnValues = [
        "Total Life Cycle Cost",
        "Net Present Value",
        "Internal Rate of Return",
        "Levelized Cost of (bio) Energy",
        "Primary Energy Demand",
        "Productivity",
        "Global Warming Potential",
        "Emissions",
        "Off-take of contaminants",
        "Decontamination efficiency",
        "Avoided land-use change",
        "Job creation",
        "Social acceptability"
    ];

    const [rows, setRows] = useState(idColumnValues.map((value, index) => ({
        id: value,
        scenario1: Math.round(Math.random() * 1000),
        scenario2: Math.round(Math.random() * 1000),
        // ... match additional data with column ids
    })));

    const [activeColumn, setActiveColumn] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const handleColumnClick = (columnId) => {
        if (columnId !== 'id') {
            setActiveColumn(columnId);
            setActiveRow(null); // Clear row highlight when a column is highlighted
            consoleColumnData(columnId);
        }
    };

    const handleRowClick = (rowIndex) => {
        consoleRowData(rowIndex);
        setActiveRow(rowIndex);
        setActiveColumn(null); // Clear column highlight when a row is highlighted
    };

    const consoleColumnData = (columnId) => {
        const columnData = rows.map(row => ({ [row.id]: row[columnId] }));
        console.log(`${columnId} =`, columnData);
    };
    const consoleRowData = (rowIndex) => {
        const rowData = {};
        columns.forEach(column => {
            rowData[column.label] = rows[rowIndex][column.id];
        });
        console.log(`Row ${rowIndex + 1} data:`, rowData);
    };



    return (
        <TableContainer component={Paper} >
            <table style={{ width: "100%", height: 250 }} aria-label="dynamic table">
                <thead>
                    <tr style={{border: "1px solid black" }}>
                        <td style={{border: "1px solid black" }}> KPI </td>
                        {columns.map((column) => (
                            <td
                                key={column.id}
                                onClick={() => handleColumnClick(column.id)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: activeColumn === column.id ? 'lightblue' : 'inherit',
                                    border: "1px solid black"
                                }}
                            >
                                {column.label}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr
                            className={classes.tableRow}
                            key={rowIndex}
                            onClick={() => handleRowClick(rowIndex)}
                            style={{
                                border: "1px solid black",
                                backgroundColor: activeRow === rowIndex ? 'green' : 'inherit',
                                cursor: "pointer",
                                height: 10,
                            }}
                        >
                            <td style={{border: "1px solid black", margin:"0px"}}>{row.id}</td>
                            {columns.map((column) => (
                                <td
                                    className={classes.tableCell}
                                    key={column.id}
                                    style={{
                                        backgroundColor: activeColumn === column.id && activeRow === null ? 'lightblue' : '',
                                        paddingTop: "0px",
                                        paddingBottom: 0,
                                        border: "1px solid black"
                                    }}
                                >
                                    {row[column.id]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableContainer>
    );
}

export default TableTrial;