import '../src/scss/app.scss';
import MainView from './MainView';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserView from './UserView';
import DetailsView from './DetailsView';
import { useEffect, useState } from 'react';
import { Context } from './Context';
import Multicriteria from './Multicriteria';
import TableTrial from './TableTrial';
import { KeycloakProvider, useKeycloak } from "keycloak-react-web";
import Keycloak from 'keycloak-js';
import PrivateRoute from './PrivateRoute';
import Apage from './Apage';
import keycloak from './Keycloak';




function App() {




  const authInstance = new Keycloak(keycloak) 


  const [fields, setFields] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [instanceInfo, setInstanceInfo] = useState([])
  const [userInput, setUserInput] = useState([]);
  const [infoFromUserView, setInfoFromUserView] = useState([]);
  const [sumOfAreaApp, setsumOfAreaApp] = useState();
  const [biomassApp, setBiomassApp] = useState();
  const [moistureMetalApp, setMoistureMetalApp] = useState();
  const [per_cont_app, setper_cont_app] = useState();
  const [zone, setZone] = useState();
  const [userData, setUserData] = useState();
  const [mlStaff,setMlStaff]=useState();

  function passContaminationAndZoneToApp(dataForBiomassML)
  {
    setMlStaff(dataForBiomassML)
  }
  
  

  function sendFieldInfoToApp(array) {
    
    setFields(array);
    //console.log("TO APP", fields)
  }

  function sendFacilitiesInfoToApp(arrayFacilities) {
    setFacilities(arrayFacilities);
  }

  function userInfotoApp(data) {
    setUserData(data)
  }

  function sendZonetoApp(mostCommon) {
    setZone(mostCommon)
  }

  function instanceInfotoApp(results) {
    setInstanceInfo(results);
  }

  function userInputtoApp(data) {
    setUserInput(data);
  }

  function sendInstanceInfotoApp(sendToDetails) {
    //alert(sendToDetails)
    setInfoFromUserView(sendToDetails)
  }


  function sendSumAreaToApp(sumOfArea) {
    setsumOfAreaApp(sumOfArea)
  }

  function sendBiomassToApp(biomass) {
    setBiomassApp(biomass)
  }

  function sendMoistureMetalToApp(moisture_metal) {
    setMoistureMetalApp(moisture_metal)
  }

  function sendBiomassPerContToApp(per_contaminant) {

    setper_cont_app(per_contaminant)
  }
  ////*********FOR CHECK 
  //---THIS HAS BEEN CHECKED--WE RECEIVE Fields and facilities CORRECLTY*/
  // if (fields.length>0 )
  // {
  //   console.log("we receive field in APP",fields);
  // }
  // if (facilities.length>0 )
  // {
  //   console.log("we receive facilities in APP",facilities);
  // }

  //console.log(infoFromUserView)

  return (

    <div className="App">
      <KeycloakProvider client={keycloak}>
        <Context.Provider value={{ fields, setFields, facilities, setFacilities, instanceInfo, setInstanceInfo }}>
          <BrowserRouter>
            <Routes>
                <Route path="/main" element={<MainView sendFieldInfoToApp={sendFieldInfoToApp} sendFacilitiesInfoToApp={sendFacilitiesInfoToApp} instanceInfotoApp={instanceInfotoApp} sendSumAreaToApp={sendSumAreaToApp} sendBiomassToApp={sendBiomassToApp} sendMoistureMetalToApp={sendMoistureMetalToApp} sendBiomassPerContToApp={sendBiomassPerContToApp} sendZonetoApp={sendZonetoApp} userInfotoApp={userInfotoApp} passContaminationAndZoneToApp={passContaminationAndZoneToApp}/>} />
                <Route path="/user" element={<UserView fieldsApp={fields} facilitiesApp={facilities} instance={instanceInfo} sendInstanceInfotoApp={sendInstanceInfotoApp} />} />
                <Route path="/details" element={<DetailsView results={instanceInfo} userInput={userInput} fromUserView={infoFromUserView} field={fields} biomass={biomassApp} sumOfArea={sumOfAreaApp} moistureMetal={moistureMetalApp} per_contaminant={per_cont_app} userinfo={userData} mlStaff={mlStaff}/>} />
                <Route path="/" element={<Apage />} />
                {/* <Route path="/main" element={<PrivateRoute><MainView sendFieldInfoToApp={sendFieldInfoToApp} sendFacilitiesInfoToApp={sendFacilitiesInfoToApp} instanceInfotoApp={instanceInfotoApp} sendSumAreaToApp={sendSumAreaToApp} sendBiomassToApp={sendBiomassToApp} sendMoistureMetalToApp={sendMoistureMetalToApp} sendBiomassPerContToApp={sendBiomassPerContToApp} sendZonetoApp={sendZonetoApp} userInfotoApp={userInfotoApp} /></PrivateRoute>} />
                <Route path="/user" element={<PrivateRoute><UserView fieldsApp={fields} facilitiesApp={facilities} instance={instanceInfo} sendInstanceInfotoApp={sendInstanceInfotoApp} /></PrivateRoute>} />
                <Route path="/details" element={<PrivateRoute><DetailsView results={instanceInfo} userInput={userInput} fromUserView={infoFromUserView} field={fields} biomass={biomassApp} sumOfArea={sumOfAreaApp} moistureMetal={moistureMetalApp} per_contaminant={per_cont_app} userinfo={userData} /></PrivateRoute>} /> */}
                {/* <Route path="/multicriteria" element={<Multicriteria />} />
                 <Route path="/table" element={<TableTrial />} /> */}
            </Routes>
          </BrowserRouter>
        </Context.Provider>
      </KeycloakProvider>
    </div>
  );
}

export default App;
