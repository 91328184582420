import landcharacterization from '../images/land-characterization-icon.png';
import gear from '../images/gears-vector-icon.jpg';
import contaminant from '../images/contaminant.jpg';
import contaminate from '../images/contaminatin-black.png';
import soil from '../images/soil-black.jpg';

export const STYLE = {

    //*****LEFT SIDE BAR INFO ******/
    CURRENT_STEP: {
        boxShadow: "0px 0px 2px rgba(0,0,0,0.95)",
        background: "rgb(252, 137, 35, 0.5) "
    },
    CURRENT_VIEW: {
        boxShadow: "0px 0px 2px rgba(0,0,0,0.95)",
        background: "rgb(77, 255, 77, 0.5)"
    },

    //*********POPUP(s) STYLING AND TEXT **********/
    POPUP_ADD:
        `<div style="display:flex; flex-direction:column; justify-content:center; width:100%">` +
        `<div style="display:flex; justify-content:space-evenly; align-items:center; margin-bottom:10px;">` +
        `<label style="display:flex; align-items:center;"><img style="width:35px;  margin-right:2px" src="${landcharacterization}"/>Land Characterization</label></div>` +

        `<select style="font-weight:600; text-align:center;color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input0" name="cars" size="3" >
        <option value="6">Willow</option>
        <option value="1">Miscanthus</option>
        <option value="0">Reed Cannary Grass</option>
        <option value="22">Populus</option>
        <option value="7">Hazelnut</option>
        <option value="vineyard">Vineyard</option>
        <option value="4">Sugar Cane</option>
        <option value="2">Capiacu Grass</option>
        <option value="3">Napier Grass</option>
        <option value="5">Energy Cane</option>
        <option value="11">Giant Reed</option>
        <option value="12">Switchgrass</option>
        </select>` +
        `<div style="display:flex; flex-wrap: wrap; justify-content:space-between">
        <input type="number" placeholder="Contamination level" style="flex:1; font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input1">` +
        `<input type="number" placeholder="Contamination type" style="flex:1;font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid 	rgb(0, 0, 0, 0.2);" id="swal-input2">` +
        `<input type="number" placeholder="Physical properties" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input3">` +
        `<input type="number" placeholder="Chemical properties" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input4">` +
        `<input type="number" placeholder="Arsenic" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input5">` +
        `<input type="number" placeholder="Cadmium" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid 	rgb(0, 0, 0, 0.2);" id="swal-input6">` +
        `<input type="number" placeholder="Chromium" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input7">` +
        `<input type="number" placeholder="Coper" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input8">` +
        `<input type="number" placeholder="Mercury" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input9">` +
        `<input type="number" placeholder="Iron" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input10">` +
        `<input type="number" placeholder="Nickel" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input11">` +
        `<input type="number" placeholder="Lead" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input12">` +
        `<input type="number" placeholder="Zinc" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input13">` +
        `<input type="number" placeholder="Texture of Soil" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input14">` +
        `<input type="number" placeholder="Organic Matter Content" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input15">` +
        `<input type="number" placeholder="pH" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input16">` +
        `<input type="number" placeholder="Time of Trial" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input17">` +
        `<input  type="number" placeholder="Stimulant" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input18"> </div>` +
        `</div>
        </div>`
    ,
    
    

    POP_UP_LAND_CHARACTERIZATION: `
   <div style="display:flex; flex-direction:column;  ">
   <div style="display:flex; justify-content:space-between;">
        <div style="font-weight:600;  font-size:22px; display:flex; margin-left:-5px;align-items:center; justify-content:flex-start;"><img style="width:25px; margin-right:5px;" src=${contaminate} alt="contaminant"/>Contaminants concentration in soil</div> 
        <div style="font-weight:600;font-size:22px; display:flex; align-items:center; justify-content:flex-end; margin-right:25px;"><img src=${soil} alt="soil image" style="width:35px; margin-right:5px;"/>Soil Characteristics</div>
    </div> 
        <div className="oranic_inorganic" style="display:flex; jusify-content:space-between; "> 
        <div style="display:flex; width:50%; justify-content:space-between;">
            <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column; "> 
                <div style="margin:1px;"><label>Arsenic (As)</label></div>
                <div style="margin:1px;"><label>Cadmium (Cd)</label></div>
                <div style="margin:1px;"><label>Chromium (Cr)</label></div>
                <div style="margin:1px;"><label>Copper (Cu)</label></div>
                <div style="margin:1px;"><label>Mercury (Hg)</label></div>
                <div style="margin:1px;"><label>Nickel (Ni)</label></div>
                <div style="margin:1px;"><label>Lead (Pb)</label></div>
                <div style="margin:1px;"><label>Zinc (Zn)</label></div>
            </div>
            <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column;"> 
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input0">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input1">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input2">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input3">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input4">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input5">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input6">mg/kg</div>
                <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); " id="swal-input7">mg/kg</div>
            </div>
        </div>
        <hr style="border:1px solid gray;   width:2px; height:200px;">

        <div style="display:flex; width:42%; justify-content:space-between;">
        <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column; "> 
            <div><label">Organic matter content</label></div>
            <div><label">pH</label></div>
        </div>
        <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column;"> 
            <div><input type="number" value='8.6' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input8">%</div>
            <div><input type="number" value='7.2' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input9"></div>
  
        </div>
    </div>
        
    </div>
    `,
    POP_UP_MANUAL:
    `<div style="display:flex; flex-direction:column;">
        <div style="font-weight:600; text-align:center;">Biomass Type</div>
            <select style="font-size:18px;text-align:center;color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); width:90%;" id="swal-input-biomass-type-manual" name="cars" size="3" >
            <option value="SRC Willow">Willow</option>
            <option value="Miscanthus">Miscanthus</option>
            <option value="Reed canary grass">Reed Canary Grass</option>
            </select>

        <div>
            <div style="margin:1px;"><label style="margin-right:10px;">Biomass yield</label><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-biomass-yield-man">mg/kg</div>
        </div>

        <div style="font-weight:600;">
            Contaminants
        </div>
        <div style="display:flex; ">
            <div style="display:flex; flex-direction:column;">
                <div style="margin:1px;"><label>Arsenic (As)</label></div>
                <div style="margin:1px;"><label>Cadmium (Cd)</label></div>
                <div style="margin:1px;"><label>Chromium (Cr)</label></div>
                <div style="margin:1px;"><label>Copper (Cu)</label></div>
                <div style="margin:1px;"><label>Mercury (Hg)</label></div>
                <div style="margin:1px;"><label>Lead (Pb)</label></div>
                <div style="margin:1px;"><label>Nickel (Ni)</label></div>
                <div style="margin:1px;"><label>Zinc (Zn)</label></div>
            </div>
            <div style="display:flex; flex-direction:column;">
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-arsenic-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-cadmium-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-chrom-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-copper-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-mer-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-lead-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-nickel-man">mg/kg dry biomass</div>
                <div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-zinc-man">mg/kg dry biomass</div>
            </div>
        </div>
        <button onclick="addContaminant()" style="margin-top: 10px;">+</button>
    </div>`,

    POP_UP_MANUAL_2:
    `
    <table style="border-collapse: collapse; width: 100%; font-size: 12px;">
    <thead>
        <tr>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600; width: 20%;">Biomass Type</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Biomass Yield (kg/ha)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Arsenic (As) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Cadmium (Cd) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Chromium (Cr) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Copper (Cu) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Mercury (Hg) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Lead (Pb) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Nickel (Ni) (mg/kg dry biomass)</th>
            <th style="border: 1px solid black; padding: 4px 8px; text-align: center; font-weight: 600;">Zinc (Zn) (mg/kg dry biomass)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="SRC Willow" id="swal-type-Willow">Willow</input></td>
            <td style="border: 1px solid black; padding: 4px 8px; "><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Miscanthus" id="swal-type-Miscanthus">Miscanthus</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Miscanthus"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Miscanthus"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Reed canary grass" id="swal-type-Canary">Reed canary grass</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Canary"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Canary"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Capiacu/Napier Grass" id="swal-type-Napier">Capiacu/Napier Grass</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Napier"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Napier"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Sugar Cane" id="swal-type-Sugar">Sugar Cane</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Sugar"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Sugar"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Energy Cane" id="swal-type-Energy">Energy Cane</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Energy"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Energy"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Giant Reed" id="swal-type-Giant">Giant Reed</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Giant"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Giant"></td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 4px 8px; text-align:left;"><input type="checkbox" value="Switchgrass" id="swal-type-Switchgrass">Switchgrass</input></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:50px; padding: 2px;" id="swal-biomass-yield-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-arsenic-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-cadmium-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-chrom-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-copper-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-mer-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-lead-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-nickel-man-Switchgrass"></td>
            <td style="border: 1px solid black; padding: 4px 8px;"><input type="number" min="0" placeholder="0" style="width:80px; padding: 2px;" id="swal-zinc-man-Switchgrass"></td>
        </tr>
    </tbody>
</table>
    `,

    POP_UP_LAND_CHARACTERIZATION_CHROME:
    `
    <div style="display:flex; flex-direction:column;">
    <div style="display:flex; justify-content:space-between;">
         <div style="font-weight:600;  font-size:22px; display:flex; margin-left:-5px;align-items:center; justify-content:flex-start;"><img style="width:25px; margin-right:5px;" src=${contaminate} alt="contaminant"/>Contaminants concentration in soil</div> 
         <div style="font-weight:600;font-size:22px; display:flex; align-items:center; justify-content:flex-end; margin-right:25px;"><img src=${soil} alt="soil image" style="width:35px; margin-right:5px;"/>Soil Characteristics</div>
     </div> 
         <div className="oranic_inorganic" style="display:flex; jusify-content:space-between; "> 
         <div style="display:flex; width:50%; justify-content:space-between;">
             <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column; "> 
                 <div style="margin:2px;"><label>Arsenic (As)</label></div>
                 <div style="margin:2px;"><label>Cadmium (Cd)</label></div>
                 <div style="margin:2px;"><label>Chromium (Cr)</label></div>
                 <div style="margin:2px;"><label>Copper (Cu)</label></div>
                 <div style="margin:2px;"><label>Mercury (Hg)</label></div>
                 <div style="margin:2px;"><label>Nickel (Ni)</label></div>
                 <div style="margin:2px;"><label>Lead (Pb)</label></div>
                 <div style="margin:2px;"><label>Zinc (Zn)</label></div>
             </div>
             <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column;"> 
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input0">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input1">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input2">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input3">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input4">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input5">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input6">mg/kg</div>
                 <div><input type="number" min="0" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input7">mg/kg</div>
             </div>
         </div>
         <hr style="border:1px solid rgb(0, 0, 0, 0.2);  width:2px; height:220px;">
 
         <div style="display:flex; width:42%; justify-content:space-between;">
         <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column; "> 
             <div><label">Organic matter content</label></div>
             <div><label">pH</label></div>
         </div>
         <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column;"> 
             <div><input type="number" value='8.6' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input8">%</div>
             <div><input type="number" value='7.2' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input9"></div>
   
         </div>
     </div>
         
     </div>
   
    `,

    // <div style="display:flex; flex-direction:column;">
    // <label style="font-weight:600;">Biomass Stimulants</label>
    // <select required style="text-align:center;color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); width:90%;" id="swal-input12" name="cars" size="3" >
    //         <option value="0">None</option>
    //         <option value="1">GWC</option>
    //         <option value="2">NPK</option>
    //     </select>
    // </div>
    // <div style="display:flex; justify-content:space-evenly; align-items:center; margin-bottom:10px;"><label style="display:flex; align-items:center; margin-left:2px"><img style="width:25px;" src="${location}"/>Center Coordinates</label></div>
    //     <div style="display:flex; justify-content:space-evenly; align-items:center; margin-bottom:10px;">
    //     <input placeholder="Latitude" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-inputLat">
    //     <input placeholder="Longtitude" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-inputLng"></div>`+
    POPUP_ADD_FACILITY:
        `<div style="display:flex; flex-direction:column; justify-content:center">
    <div style="display:flex; justify-content:space-evenly; align-items:center; margin-bottom:10px;">
    <label style="display:flex; align-items:center;"><img style="width:35px;  margin-right:2px" src="${gear}"/>Technology Characteristics</label></div>
    <select   style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input1"> 
    <option value="choice">Please Choose</option>
    <option value="pyrolysis">Pyrolysis</option>
    <option value="upgrade">Upgrade</option>
    </select>
    </div>`
    ,
    POPUP_ADD_INNER_DIV:
    {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginBottom: "20px",
    },
    POP_UP_OPTIMIZATION: `<div width:'100px'">
                         <input type="radio" id="optimization_input1" name="optimization_dialog" value="biomass_list">
                         <label >Continue to List of Biomasses </label><br>
                         <input type="radio" id="optimization_input2" name="optimization_dialog" value="optimization_solution">
                         <label >Break & Show Optimization Solution</label></div>`
    ,
    POPUP_STEP2_INPUT:
        `<div style="margin:10px;"><label style="display:flex; font-weight:600; justify-content:center;"><img style="width:35px;  margin-right:2px" src="${gear}"/>Optimization Input</label></div>
    <div style="display:flex; height:200px;">
        <div style="width:65%; display:flex; flex-direction:column; align-items:flex-start;">
            <div data-toggle="tooltip" data-placement="top" title="Please provide the producer price of biodiesel produced in Pyrolysis/SCWG-FT processes"><label style="padding:5px; cursor: pointer; font-weight:600;">Biodiesel price</label></div>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the producer price of biogasoline or bioethanol produced in Pyrolysis/SCWG-FT processes"><label style="padding:5px; cursor: pointer; font-weight:600;">Biogasoline price</label></div>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the producer price of bioLPG produced in Pyrolysis process"><label style="padding:5px; cursor: pointer; font-weight:600;">BioLPG price</label></div></i>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the producer price of biojetfuel produced in SCWG-FT process"><label style="padding:5px; cursor: pointer; font-weight:600;">Biojetfuel price</label></div></i>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the producer price of biochar produced in Pyrolysis process"><label style="padding:5px; cursor: pointer; font-weight:600;">Biochar selling price</label></div></i>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the appropriate discount rate for the techno-economic assessment. This is the Weighted Average Capital Cost or any other applicable discount rate for this type of investment."><label style="padding:5px; cursor: pointer; font-weight:600;">Discount rate</label></div></i>
            <div data-toggle="tooltip" data-placement="top" title="Please provide the biomass cost at the farm gate"><label style="padding:5px; cursor: pointer; font-weight:600;">Harvesting cost</label></div></i>
            <div data-toggle="tooltip" data-placement="top" title="Please check if you want to investigate the feasibility of using mobile Pyrolysis facilities"><label style="padding:5px; cursor: pointer; font-weight:600;">Mobile Facilities</label></div></i>
        </div>
        <div style="width:35%; display:flex; flex-direction:column; align-items:flex-start;">
           
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="1.44" id="swal-input1"></input>€/l</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="1.32" id="swal-input2"></input>€/l</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="0.7" id="swal-input3"></input>€/l</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="0.7" id="swal-input4"></input>€/l</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="250" id="swal-input5"></input>€/t</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="10" id="swal-input6"></input>%</div>
            <div>  <input style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" placeholder="60" id="swal-input7"></input>€/odt</div>
            <div>  <input type="checkbox" style="width:100px; color:rgb(102, 102, 102); font-weight:600;  border-radius:2px; margin-right:2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);"  id="swal-input8"></input></div>
        </div>
    </div>`
    // `<div><label style="display:flex; align-items:center;"><img style="width:35px;  margin-right:2px" src="${gear}"/>Optimization Input</label></div>` +
    // `<div style="display:flex; justify-content:space-evenly;>
    // <div style="display:flex; flex-direction:column; align-items:flex-start; justify-content:center; width:45%;background:red">
    // <div>Storage max capacity m\u00B3</div>` +
    // `<div>Storage min capacity m\u00B3</div>` +
    // `<div>Upgrade facility max capacity<input placeholder="2000" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input3">t/d</div>` +
    // `<div>Upgrade facility min capacity<input placeholder="100" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input4">t/d</div>` +
    // `<div>Fast pyrolysis max capacity <input placeholder="2000" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input5">odt/d</div>` +
    // `<div>Fast pyrolysis min capacity <input placeholder="100" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input6">odt/d</div>` +
    // `<div>Biofuel selling price<input placeholder="1000" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input7">€/t</div>` +
    // `<div>Biochar selling price<input placeholder="250" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input8">€/t</div>` +
    // //`<input placeholder="storage unit cost" style="font-weight:600; color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input10">` +
    // `<div>Discount rate<input placeholder="0.1" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input9"></div>` +
    // `<div>Harvesting unit cost<input placeholder="60" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input10">€/odt</div>` +
    // `</div>
    // <div style="display:flex; flex-direction:column; align-items:flex-start; justify-content:center; width:35%; background:blue">
    // <input placeholder="30000" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input1">
    // <input placeholder="0" type="number" style="font-weight:600; width:100px; color:rgb(102, 102, 102) ;padding:7px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid 	rgb(0, 0, 0, 0.2);" id="swal-input2">
    // </div>
    // </div>`

};

//<div style="display:flex; align-items:center; justify-content:center"><label>min cap S</label>








// POP_UP_LAND_CHARACTERIZATION: `
//     <div style="display:flex; flex-direction:column;">
//     <div style="font-weight:600; margin:10px; font-size:22px; display:flex; align-items:center; justify-content:center;"><img style="width:25px; margin-right:5px;" src=${contaminate} alt="contaminant"/>Contaminants concentration in soil</div> 
//         <div className="oranic_inorganic" style="display:flex; jusify-content:space-between; "> 
//         <div style="display:flex; width:42%; justify-content:space-between;">
//             <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column; "> 
//                 <div style="margin:1px;"><label>Arsenic (As)</label></div>
//                 <div style="margin:1px;"><label>Cadmium (Cd)</label></div>
//                 <div style="margin:1px;"><label>Chromium (Cr)</label></div>
//                 <div style="margin:1px;"><label>Copper (Cu)</label></div>
//                 <div style="margin:1px;"><label>Mercury (Hg)</label></div>
//                 <div style="margin:1px;"><label>Lead (Pb)</label></div>
//                 <div style="margin:1px;"><label>Nickel (Ni)</label></div>
//                 <div style="margin:1px;"><label>Zinc (Zn)</label></div>
//             </div>
//             <div className="inorganic" style="display:flex; align-items:flex-start;flex-direction:column;"> 
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input0">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input1">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input2">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input3">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input4">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input6">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input7">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input8">mg/kg</div>
//             </div>
//         </div>
//         <hr style="border:1px solid rgb(0, 0, 0, 0.2);  width:2px; height:220px;">
//         <div style="display:flex; width:55%; justify-content:space-between;">
//             <div className="organic" style="display:flex; align-items:flex-start;flex-direction:column; ">
//                 <div style="margin:1px;"><label>Hexachlorocyclohexane (HCH)</label></div>
//                 <div style="margin:1px;"><label>Dimethylamine</label></div>
//                 <div style="margin:1px;"><label>Petroleum products</label></div>
//             </div>
//             <div className="inorganic" style="display:flex; align-items:flex-end;flex-direction:column;"> 
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input13">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input14">mg/kg</div>
//                 <div><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ;padding:3px; border-radius:2px; margin:1px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input15">mg/kg</div>
//             </div>
//         </div>  
//         </div>

//         <hr style="border:1px solid rgb(0, 0, 0, 0.2);  width:95%;">


//         <div style="font-weight:600;font-size:22px; display:flex; align-items:center; justify-content:center;"><img src=${soil} alt="soil image" style="width:35px; margin-right:5px;"/>Soil Characteristics</div>
//         <div style="display:flex; justify-content:space-around;">
//             <div>
//                 <div style="font-weight:600; text-align:center;">Soil Texture</div>
//                 <select style="text-align:center;color:rgb(102, 102, 102) ;padding:10px; border-radius:2px; margin:5px 10px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2); width:90%;" id="swal-input9" name="cars" size="3" >
//                 <option value="0">Sandy Silt Loam</option>
//                 <option value="1">Clay Loam</option>
//                 <option value="2">Silty Clay Loam</option>
//                 <option value="3">Sandy Loam</option>
//                 <option value="4">Sand</option>
//                 <option value="5">Clay</option>
//                 <option value="6">Sandy Clay Loam</option>
//                 <option value="7">Light Loam</option>
//                 <option value="8">Sandy Silt</option>
//                 <option value="9">Silty Sand</option>
//                 <option value="10">Silty Clay</option>
//                 <option value="11">Silty Loam</option>
//                 </select>
//             </div>

//             <div style="display:flex;">
//             <div style="display:flex; flex-direction:column; justify-content:center; align-items:flex-start;margin-left:5px;">
//                 <div><label">Organic matter content</label></div>
//                 <div><label">pH</label></div>
//             </div>
//             <div style="display:flex; flex-direction:column; justify-content:center; align-items:flex-start;margin-left:5px;">
//                  <div><input type="number" value='8.6' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input10">%</div>
//                  <div><input type="number" value='7.2' placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102) ; border-radius:2px; margin:0px 3px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);" id="swal-input11"></div>
//             </div>
//             </div>
        
      
        
//     </div>
//     `,