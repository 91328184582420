import { Box } from "@mui/material";
import '../scss/card-right-facility.scss';
import location from '../images/location-icon.png';
import technologyIcon from '../images/gears-vector-icon.jpg';

function CardRightSideFacilities({facilityID, facilityCenter, facilityTechnology}) {
    return (
        <Box sx={{
            boxShadow: 1,
            borderRadius: 2,
            margin: '3px',
            border: "1px solid rgba(63, 62, 62, 0.3);",
            fontSize:"12px",            
        }}>
            <div className="card_header">Facility {facilityID}</div>
            <div className="card_facility_tech"><img style={{width:"15px", marginRight:"2.5px", marginTop:"1px" }} src={technologyIcon} alt="area icon"></img><b style={{fontSize:"13px"}}>Technology type:</b> <br/>{facilityTechnology}</div>
            <div className="card_center"><img style={{width:"15px", marginRight:"2.5px", marginTop:"1px" }} src={location} alt="area icon"></img><b style={{fontSize:"13px"}}>Coordinates:</b> <br/>{facilityCenter}</div>
        </Box>
    )
}

export default CardRightSideFacilities;