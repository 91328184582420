export default class FlowInfo{
    constructor(id, flow, from, period, to)
    {
        this.id=id;
        this.flow=flow;
        this.from=from;
        this.period=period;
        this.to=to;
    }

}