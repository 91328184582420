import { MapContainer, TileLayer, Circle, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../src/scss/mapjs.scss";
import RoutingTrial from "./RoutingTrial";
import L from 'leaflet';
import { LOCATIONS } from "./components/locations";
import { useEffect, useState, useMemo } from "react";
import pyroIcon from "./images/fire.png";
import upgradeIcon from "./images/upgraden.png";
import { click } from "@testing-library/user-event/dist/click";
import location from './images/location-icon.png';
import gear from './images/gears-vector-icon.jpg';
import scwg from './images/wg.png';
import wgfp from './images/fp_wg.png';
import storage from './images/storage.png';

export default function MapJS({ general, fields, facilities, flow, clickedRow, singleRow, sendToDetails }) {

  var resultFields = fields;

  const [routingFromTrial, setRoutingFromTrial] = useState("");

  function gotDataFromRoutingTrial(finalizedCalculationOfRoutes) {
    setRoutingFromTrial(finalizedCalculationOfRoutes)
    //console.log("GOT FROM TRIAL:", finalizedCalculationOfRoutes)
    //sendToDetails(finalizedCalculationOfRoutes)
  }


  //console.log("yolo)):",fields)
  const [currentClickedRow, setCurrentClickedRow] = useState([]);

  var flows = [];

  var resultFacilities = [];


  var pyroFacilities = [];
  var upgradeFacilities = [];
  var storageFacilities = [];
  var scwgFacilities = [];
  if (general) {

    flows = [...flow];
    //console.log("GENERAL", facilities)
    resultFacilities = [...facilities]

    for (let i = 0; i < resultFacilities.length; i++) {
      if (resultFacilities[i].id.startsWith("u")) {
        upgradeFacilities.push(resultFacilities[i]);
      }
      if (resultFacilities[i].id.startsWith("f")) {
        pyroFacilities.push(resultFacilities[i])
      }
      if (resultFacilities[i].id.startsWith("s")) {
        storageFacilities.push(resultFacilities[i])
      }
      if (resultFacilities[i].id.startsWith("wg")) {
        scwgFacilities.push(resultFacilities[i])
      }
    }
  }
  //console.log("SCWG", scwgFacilities)
  let customMarkerUpgrade = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(-18, 22),
      iconSize: new L.Point(30, 30),
      iconUrl: upgradeIcon
    }
  });
  let customMarkerSCWG = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(30, 22),
      iconSize: new L.Point(30, 30),
      iconUrl: wgfp
    }
  });

  let customMarkerPyro = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(30, 22),
      iconSize: new L.Point(30, 30),
      iconUrl: wgfp
    }
  });
  let customMarker2 = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(5, 25),
      iconSize: new L.Point(24, 24),
      iconUrl: storage//'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png'
    }
  });


  function calculateRadiusFromArea(area) {
    const pi = Math.PI;
    const radius = Math.sqrt(area / pi);
    return radius;
  }


  return (
    <MapContainer center={[fields[0].center.lat, fields[0].center.lng]} zoom={9} style={{ height: "70vh" }}>
      {resultFields ? <div>{resultFields.map((locations) => (
        <><Circle
          key={locations.id}
          center={[locations.center.lat, locations.center.lng]}
          pathOptions="blue"
          radius={calculateRadiusFromArea(locations.area)}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }} />
        </>
      ))}</div> : <></>}
      {storageFacilities.length > 0 ? (
        <div>
          {storageFacilities
            .filter(facil => facil.capacity > 0) // Filter facilities with capacity > 0
            .map(facil => (
              <Marker
                key={facil.id} // Add a key prop for better performance and to avoid warnings
                icon={new customMarker2()}
                position={[facil.lat, facil.long]}
                eventHandlers={{
                  click: (e) => {
                    console.log(e);
                  },
                }}
              >
                <Popup>
                  <h3
                    style={{
                      textAlign: "center",
                      color: "white",
                      background: "rgb(3,171,3)",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      margin: "-15px -25px 0px -21px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Storage Facility {facil.id}
                  </h3>
                  <p>
                    <img
                      style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }}
                      src={gear}
                      alt="area icon"
                    />
                    Storage Facility Capacity: {facil.capacity.toFixed(2)} dry biomass tonnes
                  </p>
                  <p>
                    <img
                      style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }}
                      src={location}
                      alt="area icon"
                    />
                    {facil.lat.toFixed(2)} {facil.long.toFixed(2)}
                  </p>
                </Popup>
              </Marker>
            ))}
        </div>
      ) : (
        <></>
      )}
      {upgradeFacilities.length > 0 ?
        <div>{upgradeFacilities.map((facil) => (
          <Marker
            icon={new customMarkerUpgrade()}
            position={[facil.lat, facil.long]}
            eventHandlers={{
              click: (e) => {
                console.log(e)
              }
            }}
          ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Upgrade Facility {facil.id}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Upgrade Facility Capacity: {facil.capacity.toFixed(2)} {pyroFacilities.length > 0 ? <>tonnes of pyrolysis oil per day</> : <>tonnes of Fischer-Tropsch product per day</>}</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat.toFixed(2)} {facil.long.toFixed(2)}</p></Popup></Marker>))} </div> : <></>}

      {pyroFacilities.length > 0 ?
        <div>{pyroFacilities.map((facil) => (
          <Marker
            icon={new customMarkerPyro()}
            position={[facil.lat, facil.long]}
            eventHandlers={{
              click: (e) => {
                console.log(e)
              }
            }}
          ><Popup><h3 style={{ textAlign: "center", color: "white", background: "red", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Pyrolysis {facil.id}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Pyrolysis Facility Capacity: {facil.capacity.toFixed(2)} tonness of dry biomass per day</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat.toFixed(2)} {facil.long.toFixed(2)}</p></Popup></Marker>))} </div> : <></>}
      {scwgFacilities.length > 0 ?
        <div>{scwgFacilities.map((facil) => (
          <Marker
            icon={new customMarkerSCWG()}
            position={[facil.lat, facil.long]}
            eventHandlers={{
              click: (e) => {
                console.log(e)
              }
            }}
          ><Popup>
              <h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>
                SCWG Facility {facil.id}
              </h3>
              {facil.capacity !== undefined && (
                <p>
                  <img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />
                  SCWG Facility Capacity: {facil.capacity.toFixed(2)} tonnes of dry biomass per day
                </p>
              )}
              <p>
                <img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />
                {facil.lat.toFixed(2)} {facil.long.toFixed(2)}
              </p>
            </Popup></Marker>))} </div> : <></>}
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <RoutingTrial routes={flows} fields={fields} facilities={resultFacilities} currentRoute={clickedRow} sendtoMapJs={gotDataFromRoutingTrial} />
    </MapContainer>

  );
}

//   var resultFields = fields;
//   const [routingFromTrial, setRoutingFromTrial] = useState("");

//   function gotDataFromRoutingTrial(finalizedCalculationOfRoutes) {
//     setRoutingFromTrial(finalizedCalculationOfRoutes);
//     console.log("GOT FROM TRIAL:", finalizedCalculationOfRoutes);
//     //sendToDetails(finalizedCalculationOfRoutes)
//   }

//   const [currentClickedRow, setCurrentClickedRow] = useState([]);

//   var flows = [];
//   var resultFacilities = [];
//   var pyroFacilities = [];
//   var upgradeFacilities = [];
//   var storageFacilities = [];
//   var scwgFacilities = [];

//   if (general) {
//     flows = [...flow];
//     console.log("GENERAL", general);
//     resultFacilities = [...facilities];

//     for (let i = 0; i < resultFacilities.length; i++) {
//       if (resultFacilities[i].id.startsWith("u")) {
//         upgradeFacilities.push(resultFacilities[i]);
//       }
//       if (resultFacilities[i].id.startsWith("f")) {
//         pyroFacilities.push(resultFacilities[i]);
//       }
//       if (resultFacilities[i].id.startsWith("s")) {
//         storageFacilities.push(resultFacilities[i]);
//       }
//       if (resultFacilities[i].id.startsWith("wg")) {
//         scwgFacilities.push(resultFacilities[i]);
//       }
//     }
//   }
//   console.log("SCWG", scwgFacilities);

//   let customMarkerUpgrade = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(-12, 22),
//       iconSize: new L.Point(30, 30),
//       iconUrl: upgradeIcon
//     }
//   });

//   let customMarkerSCWG = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(7, 27),
//       iconSize: new L.Point(30, 30),
//       iconUrl: scwg
//     }
//   });

//   let customMarkerPyro = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(29, 22),
//       iconSize: new L.Point(30, 30),
//       iconUrl: pyroIcon
//     }
//   });

//   let customMarker2 = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(5, 22),
//       iconSize: new L.Point(24, 24),
//       iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png'
//     }
//   });

//   function calculateRadiusFromArea(area) {
//     const pi = Math.PI;
//     const radius = Math.sqrt(area / pi);
//     return radius;
//   }

//   const memoizedMapContainer = useMemo(() => (
//     <MapContainer center={[fields[0].center.lat, fields[0].center.lng]} zoom={3} style={{ position: "absolute", height: "70vh" }}>
//       {resultFields ? <div>{resultFields.map((locations) => (
//         <Circle
//           key={locations.id}
//           center={[locations.center.lat, locations.center.lng]}
//           pathOptions="blue"
//           radius={calculateRadiusFromArea(locations.area)}
//           eventHandlers={{
//             click: (e) => {
//               console.log(e)
//             }
//           }} />
//       ))}</div> : <></>}
//       {storageFacilities.length > 0 ?
//         <div>{storageFacilities.map((facil) => (
//           <Marker
//             icon={new customMarker2()}
//             position={[facil.lat, facil.long]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log(e)
//               }
//             }}
//           >
//             <Popup>
//               <h3 style={{
//                 textAlign: "center",
//                 color: "white",
//                 background: "rgb(3,171,3)",
//                 paddingBottom: "5px",
//                 paddingTop: "5px",
//                 margin: "-15px -25px 0px -21px",
//                 borderRadius: "5px 5px 0px 0px"
//               }}>Storage Facility {facil.id}</h3>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Storage Facility Capacity: {facil.capacity / 100000000}</p>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat} {facil.long}</p>
//             </Popup>
//           </Marker>
//         ))}</div> : <></>}
//       {upgradeFacilities.length > 0 ?
//         <div>{upgradeFacilities.map((facil) => (
//           <Marker
//             icon={new customMarkerUpgrade()}
//             position={[facil.lat, facil.long]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log(e)
//               }
//             }}
//           >
//             <Popup>
//               <h3 style={{
//                 textAlign: "center",
//                 color: "white",
//                 background: "rgb(17, 36, 242)",
//                 paddingBottom: "5px",
//                 paddingTop: "5px",
//                 margin: "-15px -25px 0px -21px",
//                 borderRadius: "5px 5px 0px 0px"
//               }}>Upgrade Facility {facil.id}</h3>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Upgrade Facility Capacity: {facil.capacity}</p>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat} {facil.long}</p>
//             </Popup>
//           </Marker>
//         ))}</div> : <></>}
//       {pyroFacilities.length > 0 ?
//         <div>{pyroFacilities.map((facil) => (
//           <Marker
//             icon={new customMarkerPyro()}
//             position={[facil.lat, facil.long]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log(e)
//               }
//             }}
//           >
//             <Popup>
//               <h3 style={{
//                 textAlign: "center",
//                 color: "white",
//                 background: "red",
//                 paddingBottom: "5px",
//                 paddingTop: "5px",
//                 margin: "-15px -25px 0px -21px",
//                 borderRadius: "5px 5px 0px 0px"
//               }}>Fast Pyrolysis {facil.id}</h3>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Fast Pyrolysis Facility Capacity: {facil.capacity}</p>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat} {facil.long}</p>
//             </Popup>
//           </Marker>
//         ))}</div> : <></>}
//       {scwgFacilities.length > 0 ?
//         <div>{scwgFacilities.map((facil) => (
//           <Marker
//             icon={new customMarkerSCWG()}
//             position={[facil.lat, facil.long]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log(e)
//               }
//             }}
//           >
//             <Popup>
//               <h3 style={{
//                 textAlign: "center",
//                 color: "white",
//                 background: "rgb(17, 36, 242)",
//                 paddingBottom: "5px",
//                 paddingTop: "5px",
//                 margin: "-15px -25px 0px -21px",
//                 borderRadius: "5px 5px 0px 0px"
//               }}>SCWG Facility {facil.id}</h3>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />SCWG Facility</p>
//               <p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{facil.lat} {facil.long}</p>
//             </Popup>
//           </Marker>
//         ))}</div> : <></>}
//       <TileLayer
//         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//       />
//       <RoutingTrial routes={flows} fields={fields} facilities={resultFacilities} currentRoute={currentClickedRow} sendtoMapJs={gotDataFromRoutingTrial} />
//     </MapContainer>
//   ), [fields, flows, resultFacilities, upgradeFacilities, pyroFacilities, storageFacilities, scwgFacilities]);

//   return (
//     <div style={{ position: "relative" }}>
//       {memoizedMapContainer}
//       {routingFromTrial === "OK" ? <></> : <h1 style={{ position: "absolute", zIndex: "10", height: "80vh", marginTop:"-10px", width: "100%", background: "red" }}>Rendering</h1>}
//     </div>
//   );
// };

// export default MyMapComponent;