import { Box } from "@mui/material";
import Sidebar from "./components/SideBar";
import './scss/userview.scss';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { useEffect, useState } from "react";
import { useKeycloak } from "keycloak-react-web";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';



function UserView({ fieldsApp, facilitiesApp, instance, sendInstanceInfotoApp }) {


    const { keycloak, initialized } = useKeycloak();

    const [render, setRender] = useState("");
    const navigate = useNavigate();
    const [mydata, setmydata] = useState([]);

    let columns = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "numscenarios", headerName: "Number of Scenarios", flex: 3 },
        {
            field: "biomass", headerName: "Plants", flex: 3,
            renderCell: (params) => (

                <DeleteIcon />

            ),

        },
    ];

    let columns_db = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "numscenarios", headerName: "Number of Scenarios", flex: 1 },
        { field: "scenarioname", headerName: "Scenario Name", flex: 3 },
        { field: "biomass", headerName: "Plants", flex: 3 },
        {
            field: "delete", headerName: "Delete Scenario", flex: 1,
            renderCell: (params) => (
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
                    onMouseOver={(e) => e.target.style.color = 'red'}
                    onMouseOut={(e) => e.target.style.color = 'black'}
                    onClick={deleteRow}
                ><DeleteIcon /></div>


            ),

        },

    ];

    let data = [];
    let data_db = {};

    if (instance.length > 0) {
        console.log("WE RECEIVED INFO", instance);
        data = [{ id: 1, name: instance[1], info: instance[2], timestamp: instance[0] }];
    }

    const columnGroupingModel = [
        {
            groupId: 'Optimization Solution',
            description: '',
            children: [{ field: columns[0].field }, { field: columns[1].field }, { field: columns[2].field }],
            headerClassName: 'headerTheming'
        },
    ];

    const columnGroupingModel2 = [
        {
            groupId: 'Optimization Solutions - Saved Scenarios',
            description: '',
            children: [{ field: columns_db[0].field }, { field: columns_db[1].field }, { field: columns_db[2].field }, { field: columns_db[3].field }, { field: columns_db[4].field }],
            headerClassName: 'headerTheming'
        },
    ];

    var whatWeGotFromAll = [];
    const [whatWeGotFromAllState, setwhatWeGotFromAllState] = useState([]);
    async function fetchAllFromUser() {
        try {
            const response = await fetch(`/api/all`);///api/by-code/${keycloak.tokenParsed.sub}
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const result = await response.json();
            // alert("Successfully fetched all");
            console.log("All results", result);
            whatWeGotFromAll = result
            whatWeGotFromAll = result.filter(item => item._id.includes(keycloak.tokenParsed.sub));;
            console.log("All What", whatWeGotFromAll);
            setwhatWeGotFromAllState([...whatWeGotFromAll])
            callIt();
        } catch (error) {
            console.log("Couldn't fetch data", error);
        }
    }

    function callIt() {
        var newData = []; // Create a local array to store new data
        console.log("LEN", whatWeGotFromAll.length)
        //console.log(keycloak.tokenParsed.sub)
        // whatWeGotFromAll = whatWeGotFromAll.filter(item => item._id.includes(keycloak.tokenParsed.sub));
        for (let i = 0; i < whatWeGotFromAll.length; i++) {
            console.log("Length", Object.keys(whatWeGotFromAll[i]), allInstances);

            const currentScenarioName = (whatWeGotFromAll[i]._id.split('_').pop() === "") ? "unnamed scenario" : (whatWeGotFromAll[i]._id).split('_').pop();

            // Check if the current scenario name is the same as the scenarioname in the current dataEntry
            var dataEntry = {
                id: i,
                numscenarios: Object.keys(whatWeGotFromAll[i].biomass).length * 2,
                scenarioname: ((whatWeGotFromAll[i]._id).split('_').pop() === "") ? "unnamed scenario" : (whatWeGotFromAll[i]._id).split('_').pop(),// 
                biomass: Object.keys(whatWeGotFromAll[i].biomass),
                delete: { DeleteIcon }
            };



            console.log("HELLO", dataEntry);
            newData.push(dataEntry); // Add new data to local array
        }
        console.log("HELLO AGAIN", newData);
        setmydata([...mydata, ...newData]);
        console.log("HELLO AGAIN", mydata);
    }

    useEffect(() => {
        console.log("we fetch")
        fetchAllFromUser();
    }, []);

    useEffect(() => {
        console.log("HELLO", mydata);
    }, [data_db,mydata]);

    // Block to check if we have anything on local storage and populate the table
    var allInstances;
    var tableHeader;

    // if (localStorage.getItem("comparisonObject") !== null) {
    //     allInstances = JSON.parse(localStorage.getItem("comparisonObject"));
    //     for (let i = 0; i < allInstances.length; i++) {
    //         data.push({
    //             id: i,
    //             numscenarios: Object.keys(allInstances[i].biomass).length * 2,
    //             biomass: Object.keys(allInstances[i].biomass),
    //         });
    //     }
    //     console.log("INITIAL DATA", data)
    // }



    async function deleteRow(staff) {
        try {
            var myCode=whatWeGotFromAllState[staff]._id
            console.log(whatWeGotFromAllState[staff])
            const response = await fetch(`/api/delete/${myCode}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });///api/by-code/${keycloak.tokenParsed.sub}
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            //var mySdata=[...mydata.slice(0,staff),...mydata.slice(staff+1)]
           
            // alert("Successfully fetched all");
            console.log("Deleted");

        } catch (error) {
            console.log("Couldn't delete data", error);
        }
        setmydata(mydata.filter(item => item.id !== staff));
    }


    const [dataSet, setDataSet] = useState([]);
    const [datasetLabels, setDatasetLabels] = useState([]);
    const [barLabel, setBarLabel] = useState();

    const handleRowClick = (params) => {
        console.log(params)
        setDataSet([]);
        setDatasetLabels([]);
        setBarLabel([]);
        setRender(current => !current);
        if (params.field === "delete") {
            console.log("WE DELETE NOW m", params.row.scenarioname)
            deleteRow(params.row.id);
            //     const myFId = params.id;
            //     console.log(myFId);
            //     const sendToDetails = JSON.parse(localStorage.getItem("comparisonObject"))[myFId];
            //     const sendToDetails2 = whatWeGotFromAllState[myFId]
            //     sendInstanceInfotoApp(sendToDetails);
            //    // navigate("/details", { state: { from: "userview" } });
            setRender("");
        } else {
            const myFId = params.id;
            console.log(myFId);
            //const sendToDetails = JSON.parse(localStorage.getItem("comparisonObject"))[myFId];
            const sendToDetails2 = whatWeGotFromAllState[myFId]
            sendInstanceInfotoApp(sendToDetails2);
            navigate("/details", { state: { from: "userview" } });
            setRender("");
        }
    };

    const [colorme, setColorme] = useState("white");
    const [colormeRow, setColormeRow] = useState("white");

    const HandleHover = (e) => {
        if (e.target.getAttribute("data-field") !== "id") {
            setColormeRow("rgba(255, 179, 102, 0.4)");
        }
        if (e.target.getAttribute("data-field") === "id") {
            setColormeRow("rgba(255, 179, 102, 0.4)");
        }
    };

    return (
        <div className="user_view">
            <div className="sidebar">
                <Sidebar currentView="user" />
            </div>
            <div className="central_user_view">
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                        height: "99vh",
                        margin: "3px",
                        borderRadius: "5px",
                        overflowY: "scroll",
                        '& .headerTheming': {
                            textAlign: "center",
                            backgroundColor: 'rgb(77, 255, 77)',
                            paddingLeft: '44%',
                            color: "white",
                            fontSize: "20px"
                        },
                    }}>
                    {/* <div style={{ height: 430, width: "100%", margin: "2px", boxShadow: "1px 0px 1px 0px grey" }}>
                        <DataGrid
                            toolbar
                            onCellClick={handleRowClick}
                            experimentalFeatures={{ columnGrouping: true }}
                            columnGroupingModel={columnGroupingModel}
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            slotProps={{
                                cell: {
                                    onMouseEnter: HandleHover
                                }
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            sx={{
                                overflow: "auto", cursor: "pointer",
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: `${colormeRow}`,
                                },
                            }}
                        />
                    </div> */}
                    <div style={{ height: 430, width: "100%", margin: "2px", boxShadow: "1px 0px 1px 0px grey" }}>
                        <DataGrid
                            toolbar
                            onCellClick={handleRowClick}
                            experimentalFeatures={{ columnGrouping: true }}
                            columnGroupingModel={columnGroupingModel2}
                            rows={mydata}
                            columns={columns_db}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            slotProps={{
                                cell: {
                                    onMouseEnter: HandleHover
                                }
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            sx={{
                                overflow: "auto", cursor: "pointer",
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: `${colormeRow}`,
                                },
                            }}
                        />
                    </div>
                    {render !== "" ? (
                        <div style={{ height: 430, width: "100%", margin: "2px", boxShadow: "1px 0px 1px 0px grey" }}>
                            <BarChart
                                xAxis={[{ scaleType: 'band', data: datasetLabels }]}
                                series={[{ data: dataSet, label: barLabel, id: 1, color: '#fdb462' }]}
                                margin={{
                                    bottom: 100,
                                    left: 100
                                }}
                            />
                        </div>
                    ) : <></>}
                </Box>
            </div>
        </div>
    );
}

export default UserView;
