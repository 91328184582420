export default class MarkerObject {
    constructor(id,center,area) {//,contamination_lvl,contamination_type,physical_prop, chemical_prop
      this.id=id;
      this.center = center;
      this.area = area;
    //   this.contamination_lvl=contamination_lvl;
    //   this.contamination_type=contamination_type;
    //   this.physical_prop=physical_prop;
    //   this.chemical_prop=chemical_prop;
     }
  }