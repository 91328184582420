import '../scss/sidebar.scss';
import logo from '../images/logo.png';
import logo2 from '../images/logoceresis.png';
import tick from '../images/tick.png';
import redx from '../images/redx.png';
import { STYLE } from './styles';
import { useState, Fragment, useEffect } from 'react';
import { NAVIGATION, STEPS } from '../components/constatstext';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import add from '../images/chooseland.gif';
import deletegif from '../images/delete.gif';
import edit from '../images/edit.gif';
import Swal from 'sweetalert2';
import greenCircle from '../images/greencircle.png';
import greycCirlcle from '../images/greycircle.png';
import CircleIcon from '@mui/icons-material/Circle';
import meDot from '../images/doticon.png';
import columnclick from '../images/row-click.gif';
import rowclick from '../images/column-row-click.gif';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
function Sidebar({ currentView }) {

    //*************THIS WILL BECOME PROPS ****************/
    const [step, setStep] = useState(1);
    
    const downloadPdf = () => {
        // let pdfPath = process.env.PUBLIC_URL + '/trialpdf.pdf';  // Try using process.env.PUBLIC_URL first
        
        // const link = document.createElement('a');
        // link.href = pdfPath;
        // link.setAttribute('download', 'CERESiS User Manual.pdf');
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const pdfPaths = [
            process.env.PUBLIC_URL + '/trialpdf.pdf',  // First PDF path
            process.env.PUBLIC_URL + '/trialpdf_ua.pdf'  // Second PDF path
        ];
    
        pdfPaths.forEach((pdfPath, index) => {
            const link = document.createElement('a');
            link.href = pdfPath;
            if(index===0)
                {link.setAttribute('download', `CERESiS User Manual_en.pdf`);}
            if(index===1)
                {link.setAttribute('download', `CERESiS User Manual_ua.pdf`);}
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
      };



    function myClick()
    {
        if (currentView==="main")
        {
            infoClick()
        }
        if (currentView==="details")
        {
            showTheGif()
        }
    }  

    const navigate = useNavigate();
    function infoClick()
    {
        Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-25px -30px 2px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Select Contaminated Land Areas</h5>`,
            html: `<img style="width:450px; margin:-10px" src=${add} alt="add area gif"></img>
            <div style="display:flex; justify-content:center; margin-top:10px; margin-bottom:-5px;  align-items:baseline;"><div style="font-size:28px; color:green; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:gray; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:gray; font-weight:600;">o</div></div>`,    
            confirmButtonColor: "green",
            confirmButtonText: "Next",
            width:500,
            allowOutsideClick: true,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire({
                title: `<h5 style='background-color:green; color:white; margin:-25px -30px 2px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Edit Selected Areas</h5>`,
                html:`<img style="width:450px; margin:-10px" src=${edit} alt="add area gif"></img>
                <div style="display:flex; justify-content:center; margin-top:10px; margin-bottom:-5px;  align-items:baseline;"><div style="font-size:28px; color:gray; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:green; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:grey; font-weight:600;">o</div></div>`,    
                confirmButtonColor: "green",
                confirmButtonText: "Next",
                width:500,
                allowOutsideClick: true,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.fire({
                        title: `<h5 style='background-color:green; color:white; margin:-25px -30px 2px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Remove Selected Areas</h5>`,
                        html: `<img style="width:450px; margin:-10px" src=${deletegif} alt="add area gif"></img>
                        <div style="display:flex; justify-content:center; margin-top:10px; margin-bottom:-5px;  align-items:baseline;"><div style="font-size:28px; color:grey; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:grey; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:green; font-weight:600;">o</div></div>`,    
                        confirmButtonColor: "green",
                        confirmButtonText: "Next",
                        width:500,
                        allowOutsideClick: true,
                      })
                }
              });
            }
          });
    }
    function showTheGif() {
        Swal.fire({ title: `<h5 style='background-color:green; color:white; margin:-25px -30px 2px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Click on the column(s) headers to view details for each scenario</h5>`,
            html: `<img style="width:550px; margin:-10px" src=${columnclick} alt="add area gif"></img>
            <div style="display:flex; justify-content:center; margin-top:10px; margin-bottom:-5px;  align-items:baseline;"><div style="font-size:28px; color:green; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:gray; margin-right:3px;  font-weight:600;">o</div></div>`,    
            confirmButtonColor: "green",
            confirmButtonText: "Next",
            width:650,
            allowOutsideClick: true,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire({
                title: `<h5 style='background-color:green; color:white; margin:-25px -30px 2px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Click on a row to view KPI comparison among all scenarios</h5>`,
                html:`<img style="width:550px; margin:-10px" src=${rowclick} alt="add area gif"></img>
                <div style="display:flex; justify-content:center; margin-top:10px; margin-bottom:-5px;  align-items:baseline;"><div style="font-size:28px; color:gray; margin-right:3px;  font-weight:600;">o</div><div style="font-size:28px; color:green; margin-right:3px;  font-weight:600;">o</div></div>`,    
                confirmButtonColor: "green",
                confirmButtonText: "Next",
                width:650,
                allowOutsideClick: true,
              });
            }})
    }

    //TODO ==== IF clickedStep > currentSTEP ==> THEN ASK FOR REQUIRED INPUT IN PREVIOUS STEPS
    function chooseAstep(e) {
        // console.log(e.target.childNodes[0].data);
        let listItemClicked = e.target.childNodes[0].data;
        if (listItemClicked === STEPS.STEP1) {
            setStep(1);
        }
        if (listItemClicked === STEPS.STEP2) {
            setStep(2);
        }
        if (listItemClicked === STEPS.STEP3) {
            setStep(3);
        }
        if (listItemClicked === STEPS.STEP4) {
            setStep(4);
        }
        if (listItemClicked === STEPS.STEP5) {
            setStep(5);
        }
        if (listItemClicked === STEPS.STEP6) {
            setStep(6);
        }
        if (listItemClicked === STEPS.STEP7) {
            setStep(7);
        }
    }

    function chooseTheView(e) {
        //console.log(e.target.childNodes[1].data)
        //console.log(e.target.childNodes[0].data);

        let navigationClickedItem = e.target.childNodes[1].data;
        if (navigationClickedItem === NAVIGATION.MAIN) {
            //setCurrentView("main");
            navigate("/main");
        }
        if (navigationClickedItem === NAVIGATION.USER) {
            //setCurrentView("user");
            navigate("/user");
        }
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    return (
        <div className="sidebar_list">
            <ul className='logos_sidebar'>


                <li><img src={logo} alt="Logo" style={{ width: 50, marginRight: -10 }} /><img className="logoceresis" src={logo2} alt="LogoCeresis" style={{ width: 100, marginBottom: 15, marginLeft: 5 }} />
                    <Tooltip placement="right-end"
                        title={
                            <Fragment >
                                <div style={{ fontSize: "14px" }}><b>The CERESiS DSS</b> (ContaminatEd land Remediation through Energy crops for Soil improvement to liquid biofuel Strategies) is an innovative Decision Support System (DSS) designed to optimize the whole liquid biofuel supply chain from biomass grown on contaminated land.<br />By utilizing technologies such as Fast  Pyrolysis (FP) and Super Critical Water Gasification (SCWG), CERESiS provides stakeholders with the tools needed to make informed decisions. The DSS platform helps you navigate each step with data-driven insights for your specific biofuel production strategy.<button style={{marginLeft:"5px"}} onClick={downloadPdf}>Download Manual</button></div>
                            </Fragment>
                        }>
                        <InfoIcon style={{ cursor: "pointer", color: "gray", marginBottom: 15 }} />
                    </Tooltip>

                </li>
            </ul>
            <hr />
            <ul className='navigation_ul'>
                <li>NAVIGATION</li>
            </ul>
            <hr />
            <ul className='view_ul'>
                <li onClick={chooseTheView} style={currentView === "main" ? STYLE.CURRENT_VIEW : {}}><HomeIcon style={{ marginRight: "4px" }} />{NAVIGATION.MAIN}</li>
                <li onClick={chooseTheView} style={currentView === "user" ? STYLE.CURRENT_VIEW : {}}><PersonIcon style={{ marginRight: "4px" }} />{NAVIGATION.USER}</li>
                {currentView === "details" ? <li onClick={chooseTheView} style={currentView === "details" ? STYLE.CURRENT_VIEW : {}}><InfoIcon style={{ marginRight: "4px" }} />{NAVIGATION.DETAILS}</li> : <></>}
                <li onClick={()=>navigate('/')} style={{color:"grey", fontWeight:600, display:"flex", alignItems:"center"}}>
                <ExitToAppIcon style={{marginRight:"4px"}}/>Logout
            </li>
            </ul>
            
            <ul className='navigation_ul'>
                <div style={{ display: "flex" }}> <li>GUIDELINES </li>
                    <Tooltip placement="right-end"
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 10],
                                        },
                                    },
                                ],
                            },
                        }}
                        title={
                            <Fragment>
                                <div style={{ fontSize: "14px" }}>Click for detailed guidelines</div> 
                            </Fragment>
                        }>

                        <InfoIcon style={{ cursor: "pointer", color: "gray", marginLeft: 15, marginTop: -3 }} onClick={myClick} />
                    </Tooltip>

                </div>
            </ul>
           
            <hr />
            
            <ul className='view_ul'>
                {currentView==="main"?<li style={{ fontSize: "11.5px", textAlign: "justify", textJustify: "inter-word", marginLeft: "-10px" }}>Begin by selecting contaminated land areas on the map using markers provided in the top left corner. You can choose to mark areas with either circles with a specified radius or polygons to define the boundaries more precisely. As you mark the contaminated areas, details for each plot will appear on the right side. You can either edit or delete fields using the markers on the top left. When you finish with selecting the areas, click on ‘Next Step’. Please note that you need to add at least 2 areas.</li>
                :currentView==="details"?<li style={{ fontSize: "11.5px", textAlign: "justify", textJustify: "inter-word", marginLeft: "-10px" }}>The Results View presents the outcomes from your analysis, highlighting the key performance indicators' (KPIs) values for each scenario investigated (scenario = combination of biomass type & conversion technology). This view enables you to explore detailed information for each scenario by clicking on column headers or compare KPIs across multiple scenarios by selecting rows. You may also perform multicriteria assessment of the scenarios by providing values for the significance of each KPI and weighting factors for each KPI category. With capabilities to visualize data through various charts and graphs, filter and sort results, and export data in CSV format, the Results View supports a deep and comparative analysis of biomass supply chain and technoeconomic scenarios, facilitating informed decision-making.</li>
                :<li style={{ fontSize: "11.5px", textAlign: "justify", textJustify: "inter-word", marginLeft: "-10px" }}>The User View presents the saved sets of scenarios from past DSS applications of the user. You may review the results or key inputs of each scenario set by clicking on it. Each scenario set contains a number of scenarios (scenario = combination of biomass type & conversion technology).</li>}
            </ul>
            
            {/* {currentView==="main"?<><ul className='steps_ul'>
                <li>STEPS</li>
            </ul>
            <hr/>
            <ul className='steps_sidebar'>
                <li onClick={chooseAstep} style={step === 1 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP1}{step >= 1 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 2 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP2}{step >= 2 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 3 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP3}{step >= 3 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 4 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP4}{step >= 4 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 5 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP5}{step >= 5 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 6 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP6}{step >= 6 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
                <li onClick={chooseAstep} style={step === 7 ? STYLE.CURRENT_STEP : {}}>{STEPS.STEP7}{step >= 7 ? <img src={tick} alt="tick" /> : <img src={redx} alt="red x" />}</li>
            </ul></>:<></>} */}
        </div>
    )
}

export default Sidebar;