import { Box } from "@mui/material";
import '../scss/card-right.scss';
import area from "../images/area.png";
import location from '../images/location-icon.png';

function CardRightSide({fieldID, fieldArea,fieldLng, fieldLat,fieldContaminationType,fieldPhysicalProp,fieldChemicalProp}) {
    return (
        <Box sx={{
            boxShadow: 1,
            borderRadius: 2,
            margin: '3px',
            padding:"1px",
            border: "1px solid rgba(63, 62, 62, 0.3);",
            fontSize:"12px",    
            background:"rgba(0, 204, 0, 0.3)"        
        }}>
            <div className="card_header">Field {fieldID}</div>
            <div className="card_area"><img style={{width:"15px", marginRight:"2.5px",verticalAlign: "middle", }} src={area} alt="area icon"></img><b style={{fontSize:"13px"}}>Area: </b><br/> {(parseFloat(fieldArea)).toFixed(2)}{"(ha)"}</div>
            {/* {"m\u00B2"} */}
            <div className="card_center"><img style={{width:"15px", marginRight:"2.5px", marginTop:"1px" }} src={location} alt="area icon"></img><b style={{fontSize:"13px"}}>Coordinates:</b> <br/><b>lat: </b>{fieldLat}<br/><b>lng: </b>{fieldLng}</div>
            {/* <div className="card_contamination_lvl"><b style={{fontSize:"13px"}}>Contamination Level:</b> <br/> {fieldContaminationLvl}</div>
            <div className="card_contamination_type"><b style={{fontSize:"13px"}}>Contamination Type:</b> <br/>{fieldContaminationType}</div> */}
        </Box>
    )
}

export default CardRightSide;