import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";

export default function RoutingTrial({ routes, facilities, fields, sendtoMapJs }) {
    const [finalizedCalculationOfRoutes, setFinalizedCalculationOfRoutes] = useState("NO");
    const [routesAdded, setRoutesAdded] = useState(0);
    const totalRoutes = routes.length;
    
    const map = useMap();
  
    // Utility function to remove duplicates
    function removeDuplicates(array, key1, key2) {
      let seen = {};
      return array.filter(item => {
        let compositeKey = item[key1] + '|' + item[key2];
        return seen.hasOwnProperty(compositeKey) ? false : (seen[compositeKey] = true);
      });
    }
  
    // Preprocess routes and fields data
    useEffect(() => {
      for (let i = 0; i < fields.length; i++) {
        fields[i].id = `f_${i}`;
      }
  
      for (let i = 0; i < routes.length; i++) {
        // Modify route IDs based on names
        if (routes[i].to.startsWith("Super Critical Water Gasification")) {
          routes[i].to = routes[i].to.replace("Super Critical Water Gasification Facility", "wg");
        }
        if (routes[i].to.startsWith("Pyrolysis")) {
          routes[i].to = routes[i].to.replace("Pyrolysis Facility", "fp");
        }
        if (routes[i].to.startsWith("Upgrade")) {
          routes[i].to = routes[i].to.replace("Upgrade Facility", "u");
        }
        if (routes[i].to.startsWith("Storage")) {
          routes[i].to = routes[i].to.replace("Storage Facility", "s");
        }
  
        if (routes[i].from.startsWith("Field")) {
          routes[i].from = routes[i].from.replace("Field", "f");
        }
        if (routes[i].from.startsWith("Super Critical Water Gasification")) {
          routes[i].from = routes[i].from.replace("Super Critical Water Gasification Facility", "wg");
        }
        if (routes[i].from.startsWith("Pyrolysis")) {
          routes[i].from = routes[i].from.replace("Pyrolysis Facility", "fp");
        }
        if (routes[i].from.startsWith("Upgrade")) {
          routes[i].from = routes[i].from.replace("Upgrade Facility", "u");
        }
        if (routes[i].from.startsWith("Storage")) {
          routes[i].from = routes[i].from.replace("Storage Facility", "s");
        }
      }
  
      for (let i = 0; i < routes.length; i++) {
        for (let y = 0; y < fields.length; y++) {
          if (routes[i].from === fields[y].id) {
            routes[i]["from_loc"] = fields[y].center;
          }
        }
        for (let y = 0; y < facilities.length; y++) {
          if (routes[i].from === facilities[y].id) {
            routes[i]["from_loc"] = [facilities[y].lat, facilities[y].long];
          }
          if (routes[i].to === facilities[y].id) {
            routes[i]["to_loc"] = [facilities[y].lat, facilities[y].long];
          }
        }
      }
    }, [routes, fields, facilities]);
  
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    // useEffect(() => {
    //   if (!map || routes.length === 0) return;
  
    //   routes.forEach((route, index) => {
    //     if (route.from_loc !== route.to_loc) {
    //       setTimeout(()=>{
    //         console.log("We Fire")
    //         L.Routing.control({
    //         waypoints: [
    //           L.latLng(route.from_loc),
    //           L.latLng(route.to_loc),
    //         ],
    //         fitSelectedRoutes: false,
    //         lineOptions: { styles: [{ color: `red`, opacity: 1, weight: 2 }] },
    //         show: false,
    //         createMarker: function (i, waypoint, n) {
    //             const marker = L.marker(waypoint.latLng, {
    //                 draggable: false,
    //                 opacity: 1,
    //                 icon: L.icon({
    //                     shadowUrl: null,
    //                     iconAnchor: new L.Point(5, 22),
    //                     iconSize: new L.Point(1, 1),
    //                     iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
    //                 })
    //             });
    //             return marker;
    //         },
    //       }).on('routesfound', () => {
    //         setRoutesAdded(prev => prev + 1);
    //       }).addTo(map);
    //       }, 1000)
          
    //     }
    //   });
    // }, [map, routes]);
  










    useEffect(() => {
      if (!map || routes.length === 0) return;
    
      const addRoutes = async () => {
        for (const route of routes) {
          if (route.from_loc !== route.to_loc) {
            L.Routing.control({
              waypoints: [
                L.latLng(route.from_loc),
                L.latLng(route.to_loc),
              ],
              router: L.Routing.mapbox("pk.eyJ1IjoiY2hyaXN0b3M4OSIsImEiOiJjbTFqcGtwbmEwMTd5MmxzNGgzbmNzZHN6In0.pE3po1sAWAXkkDv86VsJIA"),
              fitSelectedRoutes: false,
              lineOptions: { styles: [{ color: 'red', opacity: 1, weight: 2,  addWaypoints: false}] },
              show: false,
              createMarker: function (i, waypoint, n) {
                const marker = L.marker(waypoint.latLng, {
                  draggable: false,
                  opacity: 1,
                  icon: L.icon({
                    shadowUrl: null,
                    iconAnchor: new L.Point(5, 22),
                    iconSize: new L.Point(1, 1),
                    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
                  }),
                });
                return marker;
              },
            }).on('routesfound', () => {
              setRoutesAdded(prev => prev + 1);
            }).addTo(map);
    
            await delay(50); // Wait for 1 second before processing the next route
          }
        }
      };
    
      addRoutes();
    }, [map, routes]);
    ///The above is a trial ///
    
    useEffect(() => {
      if (routesAdded === totalRoutes) {
        setFinalizedCalculationOfRoutes("OK");
      }
    }, [routesAdded, totalRoutes]);
  
    useEffect(() => {
      if (finalizedCalculationOfRoutes === "OK") {
        //console.log("WE ARE DONE")
        sendtoMapJs(finalizedCalculationOfRoutes);
      }
    }, [finalizedCalculationOfRoutes, sendtoMapJs]);
  
    useEffect(() => {
        const isAllRoutesCalculated = routes.every(route => route.from_loc !== route.to_loc);
        if (isAllRoutesCalculated) {
            //console.log("WE ARE DONE 2")
            //alert("All routes have been calculated!");
        }
    }, [routes]);
    return null;
  }

//////---------------------------------------------------------START OF GOOD STAFF-------------------------------------

// export default function RoutingTrial({routes, facilities, fields, sendtoMapJs}) {

//     const [finalizedCalculationOfRoutes,setFinalizedCalculationOfRoutes]=useState("NO")

    

//     let finalRoutesToRender = [];
//     // for (let i = 0; i < routes.length; i++) {
//     //     for (let y = 0; y < fields.length; y++) {
//     //         for (let z = 0; z < facilities.length; z++) {
//     //             if (routes[i].from === fields[y].id && routes[i].to === fields[y].id) {
//     //                 finalRoutesToRender.push({ start: [fields[y].center.lat, fields[y].center.lng], end: [fields[y].center.lat, fields[y].center.lng] })
//     //             }

//     //             if (routes[i].from === facilities[z].id && routes[i].to === facilities[z].id) {
//     //                 finalRoutesToRender.push({ start: [facilities[z].center.lat, facilities[z].center.lng], end: [facilities[z].center.lat, facilities[z].center.lng] })
//     //             }
//     //             if (routes[i].from === fields[y].id && routes[i].to === facilities[z].id) {
//     //                 finalRoutesToRender.push({ start: [fields[y].center.lat, fields[y].center.lng], end: [facilities[z].center.lat, facilities[z].center.lng] })

//     //             }
//     //         }

//     //     }
//     // }

//     console.log("FINAL ROUTES:", finalRoutesToRender)


//     var broughtRoutes = [];
//     let broughtRoutesoriginal = [];
//     function removeDuplicates(array, key1, key2) {
//         let seen = {};
//         return array.filter(item => {
//             let compositeKey = item[key1] + '|' + item[key2];
//             return seen.hasOwnProperty(compositeKey) ? false : (seen[compositeKey] = true);
//         });
//     }
//     console.log("ROUTINGTRIAL:", routes)
   

//     broughtRoutes = [...Object.values(routes)];
//     console.log(broughtRoutes)
//     broughtRoutesoriginal = [...Object.values(routes)];

//     // for (let i = 0; i < broughtRoutes[0].length; i++) {

//     // }

//     // for (let i = 0; i < broughtRoutes[0].length; i++) {
//     //     if (broughtRoutes[0][i].to.startsWith("Super Critical Water Gasification")) {
//     //         broughtRoutes[0][i].to = broughtRoutes[0][i].to.replace("Super Critical Water Gasification Facility", "wg")
//     //         //console.log("TRIAL", broughtRoutes[0][i].to)
//     //     }
//     //     if (broughtRoutes[0][i].to.startsWith("Fast Pyrolysis")) {
//     //         broughtRoutes[0][i].to = broughtRoutes[0][i].to.replace("Fast Pyrolysis Facility", "fp")
//     //         //console.log("TRIAL", broughtRoutes[0][i].to)
//     //     }
//     //     if (broughtRoutes[0][i].to.startsWith("Upgrade")) {
//     //         broughtRoutes[0][i].to = broughtRoutes[0][i].to.replace("Upgrade Facility", "u")
//     //         //console.log("TRIAL", broughtRoutes[0][i].to)
//     //     }
//     //     if (broughtRoutes[0][i].to.startsWith("Storage")) {
//     //         broughtRoutes[0][i].to = broughtRoutes[0][i].to.replace("Storage Facility", "s")
//     //         // console.log("TRIAL", broughtRoutes[0][i].to)
//     //     }




//     //     if (broughtRoutes[0][i].from.startsWith("Field")) {
//     //         broughtRoutes[0][i].from = broughtRoutes[0][i].from.replace("Field", "f")
//     //         //console.log("TRIAL", broughtRoutes[0][i].to)
//     //     }

//     //     if (broughtRoutes[0][i].from.startsWith("Super Critical Water Gasification")) {
//     //         broughtRoutes[0][i].from = broughtRoutes[0][i].from.replace("Super Critical Water Gasification Facility", "wg")
//     //         // console.log("TRIAL", broughtRoutes[0][i].from)
//     //     }
//     //     if (broughtRoutes[0][i].from.startsWith("Fast Pyrolysis")) {
//     //         broughtRoutes[0][i].from = broughtRoutes[0][i].from.replace("Fast Pyrolysis Facility", "fp")
//     //         //console.log("TRIAL", broughtRoutes[0][i].from)
//     //     }
//     //     if (broughtRoutes[0][i].from.startsWith("Upgrade")) {
//     //         broughtRoutes[0][i].from = broughtRoutes[0][i].from.replace("Upgrade Facility", "u")
//     //         //console.log("TRIAL", broughtRoutes[0][i].from)
//     //     }
//     //     if (broughtRoutes[0][i].from.startsWith("Storage")) {
//     //         broughtRoutes[0][i].from = broughtRoutes[0][i].from.replace("Storage Facility", "s")
//     //         //console.log("TRIAL", broughtRoutes[0][i].from)
//     //     }
//     // }


//     ///------------------------------Trial-------------------//
//     console.log("ROUTING TRIAL:", routes)
//     for (let i = 0; i < fields.length; i++) {
//         fields[i].id = `f_${i}`
//     }
    
//     for (let i = 0; i < routes.length; i++) {
//         if (routes[i].to.startsWith("Super Critical Water Gasification")) {
//             routes[i].to = routes[i].to.replace("Super Critical Water Gasification Facility", "wg")
//             //console.log("TRIAL", broughtRoutes[0][i].to)
//         }
//         if (routes[i].to.startsWith("Fast Pyrolysis")) {
//             routes[i].to = routes[i].to.replace("Fast Pyrolysis Facility", "fp")
//             //console.log("TRIAL", broughtRoutes[0][i].to)
//         }
//         if (routes[i].to.startsWith("Upgrade")) {
//             routes[i].to = routes[i].to.replace("Upgrade Facility", "u")
//             //console.log("TRIAL", broughtRoutes[0][i].to)
//         }
//         if (routes[i].to.startsWith("Storage")) {
//             routes[i].to = routes[i].to.replace("Storage Facility", "s")
//             // console.log("TRIAL", broughtRoutes[0][i].to)
//         }




//         if (routes[i].from.startsWith("Field")) {
//             routes[i].from = routes[i].from.replace("Field", "f")
//             //console.log("TRIAL", broughtRoutes[0][i].to)
//         }

//         if (routes[i].from.startsWith("Super Critical Water Gasification")) {
//             routes[i].from = routes[i].from.replace("Super Critical Water Gasification Facility", "wg")
//             // console.log("TRIAL", broughtRoutes[0][i].from)
//         }
//         if (routes[i].from.startsWith("Fast Pyrolysis")) {
//             routes[i].from = routes[i].from.replace("Fast Pyrolysis Facility", "fp")
//             //console.log("TRIAL", broughtRoutes[0][i].from)
//         }
//         if (routes[i].from.startsWith("Upgrade")) {
//             routes[i].from = routes[i].from.replace("Upgrade Facility", "u")
//             //console.log("TRIAL", broughtRoutes[0][i].from)
//         }
//         if (routes[i].from.startsWith("Storage")) {
//             routes[i].from = routes[i].from.replace("Storage Facility", "s")
//             //console.log("TRIAL", broughtRoutes[0][i].from)
//         }
//     }

//     console.log("ROUTING TRIAL WAT WE GET:", routes, fields, facilities)
    

//     let myRoutes = routes
//     for (let i = 0; i < routes.length; i++) {
//         if (routes[i].from.slice(-2)==routes[i].to.slice(-2))
//         {
//             console.log("SAME point:", routes[i], i)
//             routes.splice(i,1);
//         }
//         for (let y = 0; y < fields.length; y++) {
//             if (routes[i].from === fields[y].id) {
//                 console.log("we found from fields:", fields[y].id)//, routes[i].fields[y].id)
//                 routes[i]["from_loc"]=fields[y].center
//             }

//         }
//         for (let y = 0; y < facilities.length; y++) {
//             if (routes[i].from === facilities[y].id) {
//                 console.log("we found from faciliies:", facilities[y].id)
//                 routes[i]["from_loc"]=[facilities[y].lat,facilities[y].long]
//                 //, routes[i].fields[y].id)
//             }
//             if (routes[i].to === facilities[y].id) {
//                 console.log("we found to faciliies:", facilities[y].id)//, routes[i].fields[y].id)
//                 routes[i]["to_loc"]=[facilities[y].lat,facilities[y].long]

//             }
//         }

//     }
//     console.log("ROUTING TRIAL2:", routes)

//     //---------------------Trial-------------------------------//






//     var singleRoute = [];
//     var routesToRender = [];

//     for (let x = 0; x < routes.length; x++) {
//         for (let i = 0; i < facilities.length; i++) {
//             for (let m = 0; m < fields.length; m++) {
//                 //console.log("YOOOO MF")
//                 if (routes[x].from === facilities[i].id) {

//                     if (routes[x].to === fields[m].id) {
//                         //console.log("from", facilities[i].id, "to", fields[m].id)
//                         singleRoute.push({ "from": [facilities[i].lat, facilities[i].long], "finalDest": [fields[m].center.lat, fields[m].center.lng] })
//                     }
//                     for (let y = 0; y < facilities.length; y++) {
//                         if (routes[x].to === facilities[y].id) {

//                             //console.log("from", facilities[i].id, "to", facilities[y].id)
//                             singleRoute.push({ "from": [facilities[i].lat, facilities[i].long], "finalDest": [facilities[y].lat, facilities[y].long] })
//                         }
//                     }

//                 }
//                 if (routes[x].from === fields[m].id) {
//                     if (routes[x].to === fields[m].id) {
//                         //console.log("from", fields[m].id, "to", fields[m].id)
//                     }
//                     if (routes[x].to === facilities[i].id) {
//                         //console.log("from", fields[m].id, "to", facilities[i].id)
//                         singleRoute.push({ "from": [fields[m].center.lat, fields[m].center.lng], "finalDest": [facilities[i].lat, facilities[i].long] })

//                     }
//                 }
//             }
//         }
//     }
    

//     const map = useMap();
//     //console.log(mywaypoints)

//     useEffect(() => {
//         if (!map) return;
//         //if (routesToRender.length > 0) {
//             console.log("routing len:",routes.length);
//             for (let i = 0; i < routes.length; i++) {
//                 if(routes[i].from_loc!==routes[i].to_loc)
//                 {

//                     L.Routing.control({
//                         waypoints: [
//                             L.latLng(routes[i].from_loc),
//                             L.latLng(routes[i].to_loc),
//                         ],                      //`#${randomColor}`
//                         lineOptions: { styles: [{ color: `red`, opacity: 1, weight: 2 }] },
//                         //collapsible: true,
//                         show: false,
//                         createMarker: function (i, waypoint, n) {
//                             const marker = L.marker(waypoint.latLng, {
//                                 draggable: false,
//                                 opacity: 1,
//                                 icon: L.icon({
//                                     shadowUrl: null,
//                                     iconAnchor: new L.Point(5, 22),
//                                     iconSize: new L.Point(1, 1),
//                                     iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
//                                 })
//                             });
//                             return marker;
//                         },
//                     }).on('routesfound', function (e) {
//                         var routes = e.routes;
//                         var summary = routes[0].summary;
//                         // alert distance and time in km and minutes
//                         console.log('Total distance is ' + summary.totalDistance / 1000 + ' km and total time is ' + Math.round(summary.totalTime % 3600 / 60) + ' minutes');
//                     }).addTo(map);
//                 }
                
//             }

   
//         return () => map;
//     }, [singleRoute]);

//         setTimeout(() => {
//         setFinalizedCalculationOfRoutes("OK")
//     }, 8000);
  
//     sendtoMapJs(finalizedCalculationOfRoutes)
    
//     //return null;
// }


//////---------------------------------------------------------END OF GOOD STAFF-------------------------------------

// setTimeout(() => {
//     setFinalizedCalculationOfRoutes("OK")
//   }, 8000);
  
// sendtoMapJs(finalizedCalculationOfRoutes)




// if (singleRoute.length > 0) {//}

        //     let randomColor = Math.floor(Math.random() * 16777215).toString(16);
        //     //console,log(randomColor);
        //     L.Routing.control({
        //         waypoints: [
        //             L.latLng(singleRoute[0].from),
        //             L.latLng(singleRoute[0].finalDest),
        //         ],                      //`#${randomColor}`
        //         lineOptions: { styles: [{ color: `red`, opacity: 1, weight: 2 }] },
        //         //collapsible: true,
        //         show: false,
        //         createMarker: function (i, waypoint, n) {
        //             const marker = L.marker(waypoint.latLng, {
        //                 draggable: false,
        //                 opacity: 1,
        //                 icon: L.icon({
        //                     shadowUrl: null,
        //                     iconAnchor: new L.Point(5, 22),
        //                     iconSize: new L.Point(1, 1),
        //                     iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
        //                 })
        //             });
        //             return marker;
        //         },
        //     }).on('routesfound', function (e) {
        //         var routes = e.routes;
        //         var summary = routes[0].summary;
        //         // alert distance and time in km and minutes
        //         console.log('Total distance is ' + summary.totalDistance / 1000 + ' km and total time is ' + Math.round(summary.totalTime % 3600 / 60) + ' minutes');
        //     }).addTo(map);

        // }

        //  L.Routing.control({
        //     waypoints: [
        //         L.latLng(mywaypoints[i].lat, mywaypoints[i].lng),
        //         L.latLng(mywaypoints[0].lat, mywaypoints[0].lng),
        //     ],
        //      lineOptions: {styles: [{color: 'blue', opacity: 1, weight: 2}]},
        //      collapsible:true,
        //      createMarker: function (i, waypoint, n) {
        //         const marker = L.marker(waypoint.latLng, {
        //             draggable: false,
        //             opacity: 0,
        //             icon: L.icon({
        //                 shadowUrl: null,
        //                 iconAnchor: new L.Point(5, 22),
        //                 iconSize: new L.Point(1, 1),
        //                 iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
        //             })
        //         });
        //         return marker;
        //     },
        // }).addTo(map);

        // L.Routing.control({
        //     waypoints: [
        //         L.latLng(-22.985308, -43.204845),
        //         L.latLng(-21.983449, -43.202773),
        //     ],
        //     lineOptions: { styles: [{ color: 'red', opacity: 1, weight: 2 }] },
        //     collapsible: true,
        //     createMarker: function (i, waypoint, n) {
        //         const marker = L.marker(waypoint.latLng, {
        //             draggable: false,
        //             opacity: 0,
        //             icon: L.icon({
        //                 shadowUrl: null,
        //                 iconAnchor: new L.Point(5, 22),
        //                 iconSize: new L.Point(1, 1),
        //                 iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
        //             })
        //         });
        //         return marker;
        //     },
        // }).addTo(map);

        // const routingControl = L.Routing.control({
        //   waypoints: [L.latLng(57.74, 11.94), L.latLng(57.6792, 11.949)],
        //   routeWhileDragging: false
        // }).addTo(map);
        

// if (routes && facilities) {
    //     broughtRoutes = [...Object.values(routes)];
    //     //console.log("We  Got the routes", broughtRoutes)
    //     //console.log("we got facilities", broughtRoutes[1])
    //     //console.log("we got Single Route", singleRoute)


    //     for (let xi = 0; xi < broughtRoutes[0].length; xi++) {

    //         console.log("AND WE DO:", broughtRoutes[0][xi].from)
    //         for (let xy = 0; xy < broughtRoutes[1].length; xy++) {

    //         }
    //     }
    //     routesToRender = [];

    //     let uniqueArray = removeDuplicates(singleRoute, 'from', 'finalDest');

    //     //console.log("NOT Unique", singleRoute)
    //     //console.log("Unique", uniqueArray)
    //     for (let g = 0; g < uniqueArray.length; g++) {
    //         if (uniqueArray[g].from[0] !== uniqueArray[g].finalDest[0] && uniqueArray[g].from[1] !== uniqueArray[g].finalDest[1]) {

    //             routesToRender.push({ "from": uniqueArray[g].from, "finalDest": uniqueArray[g].finalDest })
    //         }
    //     }

    //     console.log("MY ROUTES", routesToRender)

    //     singleRoute = [];
    //     for (let i = 0; i < broughtRoutes[0].length; i++) {

    //         if (routes.currentRoute.from === broughtRoutes[0][i].from) {

    //             if (routes.currentRoute.to === broughtRoutes[0][i].to) {
    //                 var current = broughtRoutes[0][i];
    //                 var currentFlow = routes.currentRoute.flow;
    //                 if (current.from.startsWith("f_")) {
    //                     for (let y = 0; y < fields.length; y++) {
    //                         if (current.from === fields[y].id) {
    //                             //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long])
    //                             for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                                 if (current.to === broughtRoutes[1][z].id) {

    //                                     singleRoute.push({ "from": [fields[y].lat, fields[y].lng], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //                 if (!current.from.startsWith("f_")) {
    //                     for (let x = 0; x < broughtRoutes[1].length; x++) {
    //                         if (current.from === broughtRoutes[1][x].id) {
    //                             //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long])
    //                             for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                                 if (current.to === broughtRoutes[1][z].id) {

    //                                     singleRoute.push({ "from": [broughtRoutes[1][x].lat, broughtRoutes[1][x].long], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })

    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }



    //             console.log("LAST Singlereute:", singleRoute)
    //         }


    //         ///CHECKIN STARTING POINT IN FIELDS
    //         if (broughtRoutes[0][i].from.startsWith("f_")) {
    //             for (let y = 0; y < broughtRoutes[1]; y++) {
    //                 if (broughtRoutes[0][i].from === broughtRoutes[1][y].id) {
    //                     //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long])
    //                     for (let z = 0; z < broughtRoutes[2].length; z++) {
    //                         if (broughtRoutes[0][i].to === broughtRoutes[2][z].id) {
    //                             //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long],"TO facility", broughtRoutes[1][z].id, [broughtRoutes[1][z].lat, broughtRoutes[1][z].long])
    //                             if (broughtRoutes[1][y].lat.toFixed(8) !== broughtRoutes[2][z].lat.toFixed(8) && broughtRoutes[1].long.toFixed(8) !== broughtRoutes[2][z].long.toFixed(8)) {
    //                                 routesToRender.push({ "from": [broughtRoutes[1][y].lat, broughtRoutes[1][y].long], "finalDest": [broughtRoutes[2][z].lat, broughtRoutes[2][z].long] })
    //                             }

    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //     }
    //     for (let i = 0; i < broughtRoutes[0].length; i++) {
    //         //CHECKING STARTING POINT FACILITIES
    //         for (let x = 0; x < broughtRoutes[2].length; x++) {
    //             if (broughtRoutes[0][i].from === broughtRoutes[2][x].id) {
    //                 //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long])
    //                 for (let z = 0; z < broughtRoutes[2].length; z++) {
    //                     if (broughtRoutes[0][i].to === broughtRoutes[2][z].id) {
    //                         //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long],"TO facility", broughtRoutes[1][z].id, [broughtRoutes[1][z].lat, broughtRoutes[1][z].long])

    //                         if (broughtRoutes[2][x].lat.toFixed(8) !== broughtRoutes[2][z].lat.toFixed(8) && broughtRoutes[2][x].long.toFixed(8) !== broughtRoutes[2][z].long.toFixed(8)) {
    //                             routesToRender.push({ "from": [broughtRoutes[2][x].lat, broughtRoutes[2][x].long], "finalDest": [broughtRoutes[2][z].lat, broughtRoutes[2][z].long] })
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //     }

    //     console.log("YEEEEEEEEEEEEE", routesToRender)


    // }



    //var hardCodedFields = [
        //     {
        //     id: "f_0",
        //     lat: 58.52484941,
        //     long: -3.333502065,
        //     radius: 449.6205
        // },
        // {
        //     id: "f_1",
        //     lat: 55.75714208,
        //     long: -2.777306979,
        //     radius: 610.864
        // },
        // {
        //     id: "f_2",
        //     lat: 55.86133936,
        //     long: -4.24821144,
        //     radius: 2129.41
        // },
        // {
        //     id: "f_3",
        //     lat: 57.6969384,
        //     long: -3.994933233,
        //     radius: 1910.046
        // },
        // {
        //     id: "f_4",
        //     lat: 56.61848991,
        //     long: -2.753734059,
        //     radius: 1567.493
        // },
        // {
        //     id: "f_5",
        //     lat: 56.78113,
        //     long: -5.47388,
        //     radius: 349.16
        // },
    //];