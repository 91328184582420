import Sidebar from "./components/SideBar";
import './scss/detailsview.scss';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box } from "@mui/material";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import {
    DataGrid, GridToolbar, GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import MapDetails from "./MapDetails";
import MapJS from "./MapJS";
import React, { useEffect, useState } from "react";
import ResultFacilities from "./components/ResultFacilities";
import FlowInfo from "./components/FlowInfo";
import GeneralInfo from "./components/GeneralInfo";
import { PieChart } from '@mui/x-charts/PieChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import MapJsSingle from "./MapJsSingle";
import Multicriteria from "./Multicriteria";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';
import { AlternateEmail, ConstructionOutlined, Label, Margin } from "@mui/icons-material";
import firstGif from './images/trial_gif.gif';
import columnclick from './images/row-click.gif';
import rowclick from './images/column-row-click.gif';
import questionmark from './images/qm.jpg';
import generatePDF, { Options } from "react-to-pdf";
import { useRef } from 'react';
import { useKeycloak } from "keycloak-react-web";
import { INFOTEXTHOVER } from "./components/constatstext";
import { CSVLink, CSVDownload } from "react-csv";


function DetailsView(results, fromUserView, field, biomass, sumOfArea, moistureMetal, per_contaminant, mlStaff) {






    var sendTheFieldstoMap = results.field;


    const { keycloak, initialized } = useKeycloak();
    const [renderMap, setRenderMap] = useState("")
    function gotFromMapJs(finalizedCalculationOfRoutes) {
        setRenderMap(finalizedCalculationOfRoutes)
        //console.log("GOT FROM MAPS TO DETAILS:", renderMap)
    }


    console.log("YOLOOOOO", results)
    //console.log("sum of area", results.sumOfArea)
    //console.log("moisture & metal ", results.moistureMetal)
    ///TABLE STAFF START
    const [columns, setColumns] = useState([]);
    var primary_energy_detailed = [];
    var numberOfColumns;
    const [identifiers, setIdentifiers] = useState();
    const [kPIs, setkPIs] = useState();
    var seriesMa;
    const seriesMa1 = [
        { id: 'series1', data: [3, 3], label: 'Series 1' },
        { id: 'series2', data: [2, 1], label: 'Series 2' },
        { id: 'series3', data: [6, 2], label: 'Series 3' },
        { id: 'series4', data: [4, 1], label: 'Series 4' },
    ];
    function convertArrayToSeries(inputArray) {
        const series = {};

        for (let i = 0; i < inputArray[0].length; i++) {
            const label = `Series ${String.fromCharCode(65 + i)}`;
            const data = [];

            for (let j = 0; j < inputArray.length; j++) {
                data.push(inputArray[j][i]);
            }

            // Dynamically generate the variable name (seriesA, seriesB, etc.)
            const seriesVariableName = `series${String.fromCharCode(65 + i)}`;

            // Create the series object
            series[seriesVariableName] = {
                data,
                label,
            };
        }

        return series;
    }



    function format2DArray(array) {
        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array[i].length; j++) {
                // Converting to locale-specific string with comma separator
                let formattedString = parseFloat(array[i][j]).toLocaleString();
                // Parsing the locale-specific string back to integer
                array[i][j] = formattedString;
            }
        }
        return array;
    }

    let labelsForBigTable = [];
    var myVariable;
    useEffect(() => {
        if (results.results.length === 0) {
            //results=results.fromUserView
            console.log("NO RESULTS", results)
        }
        //if (results.results !== null) {
        //console.log(results.results)

        numberOfColumns = Object.keys(Object.values(results)[0])
        for (let x = 0; x < Object.keys(results).length; x++) {
            if (Object.keys(results)[x] === "results") {
                //console.log("THIS IS WHAT WE WANT:", Object.keys(results)[x], x)
                numberOfColumns = Object.keys(Object.values(results)[x])
            }
        }

        for (let i = 0; i < numberOfColumns.length; i++) {

            if (numberOfColumns[i].includes("fp")) {
                labelsForBigTable.push(numberOfColumns[i].replace("fp", "PYR"))
            }
            if (numberOfColumns[i].includes("scwg")) {
                labelsForBigTable.push(numberOfColumns[i].replace("scwg", "SCWG"))
            }
        }

        setIdentifiers(numberOfColumns)
        //STORING THE KEYS OF THE RESULTS (i.e., plant_technology pair)
        for (let i = 0; i < numberOfColumns.length; i++) {
            console.log(numberOfColumns[i])
            setColumns(current => [...current, { id: `scenario${i + 1}`, label: `${labelsForBigTable[i]}` }]);

        }




        var arrayOfValuesForMajorTable = [];
        var values1 = []
        var values2 = []
        var values3 = []
        var values4 = []
        var values5 = []
        var values6 = []
        var values7 = []
        var values8 = []
        var values9 = []
        var values10 = []
        var values11 = []
        var values12 = []
        var values13 = []
        //check if we received correctly the results
        let minInp3 = [];
        let actualInp3Min = [];
        let maxInp1 = [];
        let actualInp1Max = [];
        for (let i = 0; i < numberOfColumns.length; i++) {


            //FP STAFF




            //TODO=====>Object.values(results)[0] this should be Object.values(results)[1]!!! Cause we added the user_id
            //TODO =====> change all of these in a single variable add a conditional to get the correct position in the array []
            if (numberOfColumns[i].includes("fp")) {
                //console.log("FAST PYROLYSIS", i)
                var miscanthusInput6 = 0.05 + 0.053 + 0.028;
                var napierAndcapiacuGrassInput6 = 0.047 + 0.042 + 0.023;
                var sugarCaneEnergyCaneinput6 = 0.029 + 0.024 + 0.013;
                var reedCanaryInput6 = 0.083 + 0.076 + 0.041;
                var switchGrassInput6 = 0.101 + 0.092 + 0.053;
                var willowInput6 = 0.102 + 0.093 + 0.054;
                var giantReed = 0.101 + 0.091 + 0.052;
                var input6New = 0;
                if (numberOfColumns[i].includes("Reed")) {
                    input6New = reedCanaryInput6;
                    //console.log("Reed", input6New)
                }
                if (numberOfColumns[i].includes("Willow")) {
                    input6New = willowInput6;
                    //console.log("Willow", input6New)
                }
                if (numberOfColumns[i].includes("Giant Reed")) {
                    input6New = giantReed;
                    //console.log("Giant Reed", input6New)

                }
                if (numberOfColumns[i].includes("Miscanthus")) {
                    input6New = miscanthusInput6;
                    //console.log("Miscanthus", input6New)
                }
                if (numberOfColumns[i].includes("Switchgrass")) {
                    input6New = switchGrassInput6;
                    //console.log("Switchgrass", input6New)
                }
                if (numberOfColumns[i].includes("Sugar") || numberOfColumns[i].includes("Energy")) {
                    input6New = sugarCaneEnergyCaneinput6;
                    //console.log("Sugar or Energy", input6New)
                }
                if (numberOfColumns[i].includes("Napier") || numberOfColumns[i].includes("Capiacu")) {
                    input6New = napierAndcapiacuGrassInput6;
                    //console.log("Napier or Capiacu", input6New)

                }



                //console.log(Object.values(results)[1][numberOfColumns[i]])
                //console.log(Object.values(results)[0])
                //console.log(Object.values(results)[1])
                //console.log(numberOfColumns)

                //This added for finding the my Variable for convenience and to use the same variable always
                //WHICH is corresponding to Object.values(results)[1] instead of Object.values(results)[0] 
                for (let x = 0; x < Object.keys(results).length; x++) {
                    if (Object.keys(results)[x] === "results") {
                        //console.log("THIS IS WHAT WE WANT DOS:", Object.keys(results)[x], x)
                        myVariable = Object.values(results)[x]
                    }
                }



                //This was the version working//var input2 = Object.values(results)[0][numberOfColumns[i]]["Total environmental impact [kg Co2 eq]"];
                var input2 = myVariable[numberOfColumns[i]]["Total environmental impact [kg Co2 eq]"];


                //console.log(input2)//<==input2 for KP1 ENV
                //TODO FINALIZE KPI1 _ENVIRONMENTAL AsKKKk
                //console.log("FP iteration", i, " ", Object.keys(results.biomass))

                var input1;
                for (const [key, value] of Object.entries(results.biomass)) {
                    if (numberOfColumns[i].includes(key)) {
                        input1 = value
                    }
                }
                //console.log(console.log("FP iteration", i, " ", numberOfColumns[i], "value", input1))


                var input34;
                var input3;
                var something = myVariable[numberOfColumns[i]]
                //console.log(Object.keys(something))
                let sumBiomas = 0;
                for (let y = 0; y < Object.keys(something).length; y++) {

                    if (Object.keys(something)[y].includes("|")) {
                        sumBiomas = sumBiomas + Object.values(something)[y]["Biomass flow"]
                    }
                }
                //console.log(sumBiomas)//<==input3 +4
                input34 = sumBiomas;
                input3 = input34 / 2
                var input5;
                var input6;
                for (const [key, value] of Object.entries(results.moistureMetal)) {
                    if (numberOfColumns[i].includes(key)) {
                        //console.log("MOISTURE AND HEAVY", key, value, "iteration", i)
                        input5 = 0.2;//<==MOISTURE
                        input6 = value[1];//<Heavy Metal
                        //console.log(input5, input6)
                    }
                }

                var calc1 = (0.033 * (17 / input1) * (0.2 / input6New))
                var calc2 = (0.82 * (0.2 / input6New) * (0.01316 * 0.355 * 0.365 * 1.195 * input2) / [(input3 * (1 - input5))])
                var calc3 = (1.184 + 0.14 * (0.2 / input6New))
                var calc4 = (0.026 * (0.2 / input6New))
                //console.log("CalcCheck:", calc1, calc2)
                //console.log("CalcCheck2:", input2, input3, input5)


                primary_energy_detailed.push([calc1, calc2, 1.661, 0.026])
                var primary_energy_demand = calc1 + calc2 + calc3 + calc4;//DONE

                //console.log("ENERGY DETAILS:", primary_energy_detailed, primary_energy_demand)


                var productivity = (0.2 / input6New) * [input1 / (0.82 * 0.355 * 0.365)]//DONE

                var calc1GWP = 3.8 * (17 / input1) * (0.2 / input6New)
                var calc2GWP = 0.82 * (0.2 / input6New) * (0.355 * 0.365 * 1.195 * input2) / [input3 * (1 - input5)]
                var calc3GWP = 40.4 + 3.5 * (0.2 / input6New)
                var calc4GWP = 0.7 * (0.2 / input6New)
                /////GWP
                var GWP = calc1GWP + calc2GWP + calc3GWP + calc4GWP - 29 - 45//DONE
                ///EMMISIONS
                var calc1Emmi = 18.6 * (17 / input1) * (0.2 / input6New)
                var calc2Emmi = 0.82 * (0.2 / input6New) * (3.07 * 0.355 * 0.365 * 1.195 * input2) / [input3 * (1 - input5)]
                var calc3Emmi = 72.6 + 78.3 * (0.2 / input6New)
                var calc4Emmi = 1.6 * (0.2 / input6New)
                var emissions = calc1Emmi + calc2Emmi + calc3Emmi + calc4Emmi//DONE
                //BEFORE CHANGES var GWP = (4.6 * [2 - (input1 / 17)]) + ((0.355 * 0.365 * 1.195 * input2) / [(input3) * (1 - input5)]) + 54.0 + 0.7 - 29 - 45;
                //var emissions = (22.7 * [2 - (input1 / 17)]) + ((3.07 * 0.355 * 0.365 * 1.195 * input2) / [(input3) * (1 - input5)]) + 188.7 + 1.6;
                var off_take = input1 * input6//<===input6 heavy metal consetration where to find?
                //console.log("CHECK FP:", input1, input2, input3, input34, input6, input5)
                var directJob = [312.2 * input3 * (1 - input5)] / [0.0842 * 11.11 * 1000000]
                var indirectJob = directJob * 6.19
                var inducedJob = directJob * 2.39
                var jobsCreation = directJob + indirectJob + inducedJob

                //console.log("CHCK EMISSIONS:", emissions)
                values5.push(primary_energy_demand)
                values6.push(productivity.toFixed(2))
                values7.push(GWP.toFixed(2))
                values8.push(emissions.toFixed(2))
                //console.log("CHCK EMISSIONS:", values8)
                values9.push(off_take.toFixed(2))
                values10.push(0.95)
                values11.push(results.sumOfArea.toFixed(2))
                values12.push(jobsCreation.toFixed(2))
                // for (let i = 1; i < values12.length; i++) {
                //     if (values12[i] > values12[0] * 1.2) {
                //         values12[i] = values12[0] * 1.2;
                //     }
                // }
                //values13.push(3.5)
                minInp3.push(input3)
                actualInp3Min = Math.min.apply(Math, minInp3)
                maxInp1.push(input1);
            }

            if (numberOfColumns[i].includes("scwg")) {
                //console.log("SCWG")


                var miscanthusInput6 = 0.10 + 0.10 + 0.09;
                var napierAndcapiacuGrassInput6 = 0.08 + 0.08 + 0.07;
                var sugarCaneEnergyCaneinput6 = 0.08 + 0.08 + 0.07;
                var reedCanaryInput6 = 0.11 + 0.11 + 0.10;
                var switchGrassInput6 = 0.09 + 0.09 + 0.08;;
                var willowInput6 = 0.11 + 0.11 + 0.10;
                var giantReed = 0.09 + 0.08 + 0.08;
                var input6New = 0;
                if (numberOfColumns[i].includes("Reed")) {
                    input6New = reedCanaryInput6;
                    //console.log("Reed", input6New)
                }
                if (numberOfColumns[i].includes("Willow")) {
                    input6New = willowInput6;
                    //console.log("Willow", input6New)
                }
                if (numberOfColumns[i].includes("Giant Reed")) {
                    input6New = giantReed;
                    //console.log("Giant Reed", input6New)

                }
                if (numberOfColumns[i].includes("Miscanthus")) {
                    input6New = miscanthusInput6;
                    //console.log("Miscanthus", input6New)
                }
                if (numberOfColumns[i].includes("Switchgrass")) {
                    input6New = switchGrassInput6;
                    //console.log("Switchgrass", input6New)
                }
                if (numberOfColumns[i].includes("Sugar") || numberOfColumns[i].includes("Energy")) {
                    input6New = sugarCaneEnergyCaneinput6;
                    //console.log("Sugar or Energy", input6New)
                }
                if (numberOfColumns[i].includes("Napier") || numberOfColumns[i].includes("Capiacu")) {
                    input6New = napierAndcapiacuGrassInput6;
                    //console.log("Napier or Capiacu", input6New)

                }



                //console.log(myVariable[numberOfColumns[i]])
                var input2 = myVariable[numberOfColumns[i]]["Environmental Performance"]['Total environmental impact [kg Co2 eq]: '];
                //console.log(myVariable[numberOfColumns[i]]["Environmental Performance"]['Total environmental impact [kg Co2 eq]: '])//<==input2 for KP1 ENVTotal environmental impact [kg Co2 eq]:
                //TODO FINALIZE KPI1 _ENVIRONMENTAL AsKKKk
                //console.log("SCWG iteration", i, " ", Object.keys(results.biomass))
                var input1;
                for (const [key, value] of Object.entries(results.biomass)) {
                    if (numberOfColumns[i].includes(key)) {
                        input1 = value
                    }
                }



                //console.log(console.log("SCWG iteration", i, " ", numberOfColumns[i], "value", input1))
                var input34;
                var input3;
                var something = myVariable[numberOfColumns[i]]
                //console.log(Object.keys(something))
                let sumBiomas = 0;
                for (let y = 0; y < Object.keys(something).length; y++) {

                    if (Object.keys(something)[y].includes("|")) {
                        sumBiomas = sumBiomas + Object.values(something)[y]["Biomass flow"]
                    }
                }
                //console.log(sumBiomas)//<==input3 +4
                input34 = sumBiomas;
                input3 = input34 / 2
                var input5;
                var input6;

                for (const [key, value] of Object.entries(results.moistureMetal)) {
                    if (numberOfColumns[i].includes(key)) {
                        //console.log("MOISTURE AND HEAVY", key, value, "iteration", i)
                        input5 = 0.2;//<==MOISTUREvalue[0];
                        input6 = value[1];//<Heavy Metal
                        //console.log(input5, input6)
                    }
                }

                //console.log("CHECK SCWG:", input1, input2, input3, input34, input6, input5)

                ///NEW FUNCTIONS PRIMARY ENERGY
                var calc1 = (0.019 * (17 / input1) * (0.313 / input6New))
                var calc2 = (0.313 / input6New) * (0.01316 * 0.351 * 0.198 * 1.195 * input2) / [(input3 * (1 - input5))]
                var calc3 = 0.64 + 0.238 * (0.313 / input6New)
                var calc4 = 0.0164 * (0.313 / input6New)

                var primary_energy_demand = calc1 + calc2 + calc3 + calc4;//DONE

                primary_energy_detailed.push([calc1, calc2, 0.878, 0.0164])
                //console.log("CHECK Primary Energy Detai:", primary_energy_detailed)
                seriesMa = convertArrayToSeries(primary_energy_detailed);
                //console.log("CHECK SERIES:", seriesMa)


                //PRODUCTIVITY
                var productivity = (0.313 / input6New) * [input1 / (0.351 * 0.198)] //DONE

                ///GWP
                var calc1GWP = 2.46 * (17 / input1) * (0.313 / input6New)
                var calc2GWP = (0.313 / input6New) * (0.351 * 0.198 * 1.195 * input2) / [input3 * (1 - input5)];
                var calc3GWP = 40.3 + 9.6 * (0.313 / input6New)
                var calc4GWP = 1.6 * (0.313 / input6New)

                var GWP = calc1GWP + calc2GWP + calc3GWP + calc4GWP - 29 - 25//DONE

                ///EMMISSIONS
                var calc1Emmi = 12.1 * (17 / input1) * (0.313 / input6New)
                var calc2Emmi = (0.313 / input6New) * (0.351 * 0.198 * 1.195 * input2) / [input3 * (1 - input5)];
                var calc3Emmi = 24.7 + 29.4 * (0.313 / input6New)
                var calc4Emmi = 3.3 * (0.313 / input6New)

                var emmissions = calc1Emmi + calc2Emmi + calc3Emmi + calc4Emmi;//DONE

                //console.log("CHECK SCWG:", input1, input2, input3, input34, input6, input5, calc1, calc2, "primary:", primary_energy_demand)
                // var productivity = input1 / (0.351 * 0.198)
                // var GWP = (2.46 * [2 - (input1 / 17)]) + ((0.351 * 0.198 * 1.195 * input2) / [(input3) * (1 - input5)]) + 50 + 1.6 - 29 - 25;
                // var emissions = (12.1 * [2 - (input1 / 17)]) + ((3.07 * 0.351 * 0.198 * 1.195 * input2) / [(input3) * (1 - input5)]) + 101.2 + 3.3
                var off_take = input1 * input6//<===input6 heavy metal consetration where to find?


                //input 5 may cause issues here
                var directJob = [1041.7 * input3 * (1 - input5)] / [0.2 * 11.11 * 1000000];
                var indirectJob = directJob * 6.19
                var inducedJob = directJob * 2.39
                var jobsCreation = directJob + indirectJob + inducedJob


                //console.log("CheckJobs staff:", directJob, indirectJob, inducedJob)
                values5.push(primary_energy_demand)
                values6.push(productivity.toFixed(2))
                values7.push(GWP.toFixed(2))
                values8.push(emissions.toFixed(2))
                values9.push(off_take.toFixed(2))
                values10.push(0.99)
                values11.push((results.sumOfArea).toFixed(2))
                //values12.push(values12[0]*1.2)
                values12.push(jobsCreation.toFixed(2))//<+++++++++THIS IS THE CORRECT ONEEEEEE
                // for (let i = 1; i < values12.length; i++) {
                //     if (values12[i] > values12[0] * 1.2) {
                //         values12[i] = values12[0] * 1.2;
                //     }
                // }
                //values13.push(3.5)
                minInp3.push(input3)
                actualInp3Min = Math.min.apply(Math, minInp3)
                maxInp1.push(input1);
            }

            //console.log(Object.values(results)[0][numberOfColumns[i]].KPIs)
            //removing the "euros" and "%" from the values
            var total_life_cycle_cost = myVariable[numberOfColumns[i]].KPIs['Total Life Cycle Cost expressed in present value [euros]'];
            var npv = myVariable[numberOfColumns[i]].KPIs['Net Present Value (NPV)'];
            var irr = myVariable[numberOfColumns[i]].KPIs['Internal Rate of Return (IRR)'];
            var levelized_cost_bioenergy = myVariable[numberOfColumns[i]].KPIs['Levelized Cost of Bioenergy - Biofuel price that makes NPV zero [euros/MJ]'];
            if (myVariable[numberOfColumns[i]].KPIs['Total Life Cycle Cost expressed in present value [euros]'].includes("euros")) {
                total_life_cycle_cost = myVariable[numberOfColumns[i]].KPIs['Total Life Cycle Cost expressed in present value [euros]']
                total_life_cycle_cost = total_life_cycle_cost.replace("euros", "")
                //console.log("ANTE XASOU", parseFloat(total_life_cycle_cost))
            }
            if (myVariable[numberOfColumns[i]].KPIs['Net Present Value (NPV)'].includes("euros")) {
                npv = myVariable[numberOfColumns[i]].KPIs['Net Present Value (NPV)']
                npv = npv.replace("euros", "")

            }
            if (myVariable[numberOfColumns[i]].KPIs['Internal Rate of Return (IRR)'].includes("%")) {
                irr = myVariable[numberOfColumns[i]].KPIs['Internal Rate of Return (IRR)']
                irr = irr.replace("%", "")
                //console.log("Check Internal Rate of Return (IRR)", irr)
                if (irr === "NaN" || irr === "nan") {
                    irr = 0
                }
            }

            //myVariable===>Object.values(results)[0]
            if (myVariable[numberOfColumns[i]].KPIs['Levelized Cost of Bioenergy - Biofuel price that makes NPV zero [euros/MJ]'].includes("euros")) {
                levelized_cost_bioenergy = myVariable[numberOfColumns[i]].KPIs['Levelized Cost of Bioenergy - Biofuel price that makes NPV zero [euros/MJ]']
                levelized_cost_bioenergy = levelized_cost_bioenergy.replace(" euros", "")
            }


            values1.push(parseFloat(total_life_cycle_cost));
            values2.push(parseFloat(npv));
            values3.push(parseFloat(irr));
            values4.push(parseFloat(levelized_cost_bioenergy));




        }

        //console.log("MAX INP3", Math.max.apply(Math, maxInp1), maxInp1)
        //console.log("MIN INP3", Math.min.apply(Math, minInp3), minInp3)
        //console.log("MIN KPI4", Math.min.apply(Math, values8), values8)
        //console.log("MAX KPI5", Math.max.apply(Math, values9), values9)
        //console.log("MAX KPI12", Math.max.apply(Math, values12), values12)
        let kpi13 = [];
        for (let i = 0; i < numberOfColumns.length; i++) {

            if (numberOfColumns[i].includes("fp")) {
                //console.log("Last Check:", values8, Math.min(...values8))
                //console.log("CHECK", actualInp3Min, minInp3[i], Math.min.apply(Math, values8), values8[i], values9[i], Math.min.apply(Math, values9), maxInp1[i], Math.max.apply(Math, maxInp1), values12[i], Math.max.apply(Math, values12))
                if (Math.max.apply(Math, values9) === 0) {
                    kpi13.push((10 / 58.4) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 22.3 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))

                }
                if (Math.max.apply(Math, values9) !== 0) {
                    kpi13.push((10 / 66.8) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.4 * (values9[i] / Math.max.apply(Math, values9)) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 22.3 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))

                }
                //console.log("SUPERKPI13:", (10 / 66.8) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.4 * (values9[i] / Math.min.apply(Math, values9)) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 22.3 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))

                // if (kpi13[i] > 8.7)
                // {   
                //    kpi13[i] = Math.random() < 0.33 ? 8.223  :(Math.random() < 0.66 ? 8.164 : 8.083);                
                // }
            }
            if (numberOfColumns[i].includes("scwg")) {
                if (Math.max.apply(Math, values9) === 0) {
                    //console.log("ZERO FOR SCWG", minInp3[i], Math.max.apply(Math, values12), values8[i], Math.max.apply(Math, maxInp1))
                    kpi13.push((10 / 58.4) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 19.372 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))
                    //console.log("ZERO KP13", kpi13)
                }
                if (Math.max.apply(Math, values9) !== 0) {
                    kpi13.push((10 / 66.8) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.4 * (values9[i] / Math.max.apply(Math, values9)) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 19.372 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))

                }
                //console.log("CHECK", actualInp3Min, minInp3[i], Math.min.apply(Math, values8), values8[i], values9[i], Math.min.apply(Math, values9), maxInp1[i], Math.max.apply(Math, maxInp1), values12[i], Math.max.apply(Math, values12))
                //console.log("SUPERKPI13:", (10 / 66.8) * (6.8 * (actualInp3Min / minInp3[i]) + 7.6 * (Math.min.apply(Math, values8) / values8[i]) + 8.4 * (values9[i] / Math.min.apply(Math, values9)) + 8.2 * (maxInp1[i] / Math.max.apply(Math, maxInp1)) + 19.372 + 9.0 * (values12[i] / Math.max.apply(Math, values12))))
                // if (kpi13[i] > 8.7)
                // {
                //     kpi13[i] = Math.random() < 0.33 ? 8.643 :(Math.random() < 0.66 ? 8.348 : 8.467);
                // }
            }
            //values13
        }

        //console.log("FINAL KPI", kpi13)



        arrayOfValuesForMajorTable.push(values1, values2, values3, values4, values5, values6, values7, values8, values9, values10, values11, values12, kpi13)
        setkPIs(arrayOfValuesForMajorTable);
        //console.log(arrayOfValuesForMajorTable)

        var newAr = format2DArray(arrayOfValuesForMajorTable);

        //console.log(newAr)

        const newRows = idColumnValues.map((value, index) => {
            const row = {
                id: value,
            };


            for (let scenarioIndex = 1; scenarioIndex <= arrayOfValuesForMajorTable[index].length; scenarioIndex++) {
                row[`scenario${scenarioIndex}`] = arrayOfValuesForMajorTable[index][scenarioIndex - 1];
            }


            return row;
        });

        // Set the state with the new rows
        setRows(newRows);
        //}
    }, [results.results])

    const [rows, setRows] = useState([]);
    const idColumnValues = [
        "Total Life Cycle Cost [€]",
        "Net Present Value [€]",
        "Internal Rate of Return [%]",
        "Levelized Cost of (bio) Energy [€/MJ]",
        "Primary Energy Demand [MJ_non_renewable_primary_energy/MJ_final_biofuel]",
        "Productivity [GJ_final_fuel/ha]",
        "Global Warming Potential [g CO2_eq/MJ_final_biofuel]",
        "Acidification Potential [mg SO2_eq/MJ_final_biofuel]",
        `Off-take of contaminants [g_heavy_metals/(ha*year)]`,
        "Decontamination efficiency ",
        "Land under Phytoremediation [ha]",
        "Job creation [jobs/year]",
        "Social acceptability"
    ];



    const [whichScenarioToShow, setWhichScenarioToShow] = useState();


    const useStyles = makeStyles({
        table: {
            minWidth: 650
        },
        tableRow: {
            height: 30
        },
        tableCell: {
            padding: "0px 16px"
        }
    });
    const [activeColumn, setActiveColumn] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [doit, setDoit] = useState(false)
    const handleColumnClick = (columnId, column) => {
        setSingle(false)
        if (columnId !== 'id') {

            //console.log(column)
            if (column.label.includes("pyr") || column.label.includes("PYR")) {
                setWhatToShow("scenarioFP")
                setWhichScenarioToShow(parseInt(column.id.replace("scenario", "") - 1))
                //console.log(whichScenarioToShow)
            }
            if (column.label.includes("scwg") || column.label.includes("SCWG")) {
                setWhatToShow("scenarioSCWG")

                setWhichScenarioToShow(parseInt(column.id.replace("scenario", "") - 1))
                //console.log(whichScenarioToShow)
            }
            setShowMulticriteriaDiv(false)
            setActiveColumn(columnId);
            setActiveRow(null); // Clear row highlight when a column is highlighted
            consoleColumnData(columnId);
        }
    };


    const [dataForComparison, setDataForComparison] = useState([]);
    const [compareBarChartLabels, setCompareBarChartLabels] = useState([]);
    const [compareBarChartValues, setCompareBarChartValues] = useState([]);
    const [barChartCurrent, setBarChartCurrent] = useState("");
    const [barChartYaxis, setBarChartYaxis] = useState("");
    const consoleColumnData = (columnId) => {
        const columnData = rows.map(row => ({ [row.id]: row[columnId] }));
        //console.log(`${columnId} =`, columnData);
    };

    //for (let i=)
    const consoleRowData = (rowIndex) => {
        const rowData = {};

        columns.forEach(column => {
            rowData[column.label] = rows[rowIndex][column.id];
        });
        //console.log(`Row ${rowIndex + 1} data:`, rowData, columns);
        //console.log("SOMETHING:", primary_energy_detailed)
        setCompareBarChartLabels(Object.keys(rowData))
        if (rowIndex === 0) {
            setBarChartCurrent("Total Life Cycle Cost")
            setBarChartYaxis("Euros")
        }
        if (rowIndex === 1) {
            setBarChartCurrent("Net Present Value");
            setBarChartYaxis("Euros")
        }
        if (rowIndex === 2) {
            setBarChartCurrent("Internal Rate of Return");
            setBarChartYaxis("Percent")
        }
        if (rowIndex === 3) {
            setBarChartCurrent("Levelized Cost of (bio) Energy");
            setBarChartYaxis("Euros per MJ")
        }
        if (rowIndex === 4) {
            setBarChartCurrent("Primary Energy Demand");
            setBarChartYaxis("MJ_fossil_primary_energy per MJ_final_fuel")
            setDoit(true)
        }
        if (rowIndex === 5) {
            setBarChartCurrent("Productivity");
            setBarChartYaxis("MJ_final_fuel per Hectar")
        }
        if (rowIndex === 6) {
            setBarChartCurrent("Global Warming Potential");
            setBarChartYaxis("kg CO2_eq per MJ_final_fue")
        }
        if (rowIndex === 7) {
            setBarChartCurrent("Acidification Potential");
            setBarChartYaxis("kg CO2_eq per MJ_final_fue")
        }
        if (rowIndex === 8) {
            setBarChartCurrent("Off-take of contaminants");
            setBarChartYaxis("g_heavy_metals per (ha*year)")
        }
        if (rowIndex === 9) {
            setBarChartCurrent("Decontamination efficiency");
            setBarChartYaxis("Percent")
        }
        if (rowIndex === 10) {
            setBarChartCurrent("Land under Phytoremediation");
            setBarChartYaxis("Hectars")
        }
        if (rowIndex === 11) {
            setBarChartCurrent("Job creation");
            setBarChartYaxis("Jobs per Year")
        }
        if (rowIndex === 12) {
            setBarChartCurrent("Social acceptability");
            setBarChartYaxis("")
        }

        setCompareBarChartValues(Object.values(rowData).map(value => parseFloat(value.replace(/,/g, ''))))
    };

    ///TABLE STAFF END


    const navigate = useNavigate();
    const location = useLocation();

    const [isShownRevenue, setIsShownRevenue] = useState(true);
    const [isShownTransport, setIsShownTransport] = useState(true);
    const [isShownConvertion, setIsShownConvertion] = useState(true);
    const [isShownEnvironment, setIsShownEnvironment] = useState(true);


    const [clickedRow, setClickedRow] = useState([]);
    const [single, setSingle] = useState(false);


    const [whatToShow, setWhatToShow] = useState("")

    const HandleHover = (e) => {
        console.log(e.target)

    }

    const handleRowClick = (params) => {
        console.log(params)
        if (params.id !== undefined) {
            setWhatToShow("scenario")
            setShowMulticriteriaDiv(false)
        }
        if (params.id === undefined) {
            setWhatToShow("comparison")
            setShowMulticriteriaDiv(false)
        }
    }


    if (results.fromUserView) {
        //console.log("Yolo", results.fromUserView);
        if (location.state !== null) {
            const info = location.state.from;
            console.log(info)
            results = results.fromUserView
            //console.log("YOlo2:", results.field)
            sendTheFieldstoMap = results.field
        }
        if (location.state === null) {
            console.log("null")
        }

    }
    //BLock of code to store and Append to local storage  ******comparisonObject*****///
    useEffect(() => {
        if (location.state === null) {
            var myRes = results;
            //console.log("myres", myRes)
            var myArrayRes = [];
            if (localStorage.getItem("comparisonObject") !== null) {
                myArrayRes = JSON.parse(localStorage.getItem("comparisonObject"))
            }

            myArrayRes.push(myRes);



            //console.log("MAAAARES", myArrayRes)
            //localStorage.setItem("comparisonObject", JSON.stringify(myArrayRes))
        }
    }, [])

    ////----------------------------End of Block-----------------///

    var maThink;
    var mathink_scwg;

    var maThinkArray = [];
    var maThnikArraySCWG = [];


    var facilities_info = [];
    var facilities_info_WG = [];
    var general_info = [];
    var general_info_WG = [];
    var flow_info = [];
    var flow_info_WG = [];
    var flow_info_Table = [];
    var flow_info_Table_WG = [];

    var keysOfResults;

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}_${month}_${year}`;
    }


    var nameOfScenario = "";
    async function addName() {
        //downloadPdf();
        const { value: formValues } = await Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-30px -30px -10px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Add your title for this scenario</h5>`,//field info to become a prop
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            cancelButtonColor: "red",
            showCancelButton: true,
            confirmButtonText: "Look up",
            showLoaderOnConfirm: true,
            focusConfirm: true,
            confirmButtonColor: "green",
            confirmButtonText: "Submit",//to become a prop
            showCancelButton: true,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: (input) => {
                nameOfScenario = input
                storeTheInstance()
            }

        })
    }

    async function storeTheInstance() {
        //console.log(Object.isExtensible(results))
        results = JSON.parse(JSON.stringify(results))
        //console.log(Object.isExtensible(results))

        results._id = keycloak.tokenParsed.sub + "_" + getDate() + "_" + nameOfScenario.replace(/[_ ]/g, "")
        //results._id = nameOfScenario.replace(/[_ ]/g, "")
        //-------GIVE IT A GO WITH MONGO------------//
        await fetch(`/api/insert`, {//http://167.235.129.196:4000/insert //// /api/insert
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(results)
        })//.then(response =>  console.log(response.ok)).then(data=>console.log(data)).catch(console.error);
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                    console.log(response)
                }
                return response.json()
            }).then((result) => {
                Swal.fire({
                    title: "Succesfully stored",
                    confirmButtonColor: "green",
                    confirmButtonText: "OK",
                });
            }).catch(error => {
                Swal.fire({
                    title: "An error has occured",
                    confirmButtonColor: "red",
                    confirmButtonText: "OK",
                })
            })

        //-------GIVE IT A GO WITH MONGO------------//
    }

    //Getting the results from RightSidebar => App => Detailview -- These are the results of the call to the Optimization Algorithm
    if (results !== null) {


        //console.log("DO IT", results)

        //console.log(Object.keys(Object.values(results)[0]))
        keysOfResults = Object.keys(Object.values(results)[0])

        //console.log(keysOfResults)
        //console.log(Object.keys(results))
        for (let x = 0; x < Object.keys(results).length; x++) {
            if (Object.keys(results)[x] === "results") {
                // WHAT WE WANT:", Object.keys(results)[x])
                keysOfResults = Object.keys(Object.values(results)[x])
                myVariable = Object.values(results)[x]
            }
        }
        //console.log(Object.keys(Object.values(results)[1]))
        //console.log(Object.keys(Object.values(results)))
        //STORING THE KEYS OF THE RESULTS (i.e., plant_technology pair)
        for (let i = 0; i < keysOfResults.length; i++) {
            //maThink = Object.values(results)[0][keysOfResults[i]]<==Previous and WORKING myVariable
            maThink = myVariable[keysOfResults[i]]
            maThinkArray.push(maThink)
            console.log(maThink)
            if (maThink === undefined)
                console.log("MATHING UNDEFINED")
        }
        //console.log(maThinkArray, whichScenarioToShow)

        //console.log(Object.values(results)[0]["Reed canary grass_fp"])
        //maThink = Object.values(results)[0]["Reed canary grass_fp"];

        if (maThink === undefined || maThink.length === 0) {
            //console.log("YO YO")
            maThink = results.fromUserView;
            if (maThink === undefined || maThink.length === 0) {
                // window.location.href = "/";
                //console.log("WINDOW")
            }
        }

        if (whatToShow === "scenarioFP") {
            var keysArray = Object.keys(maThinkArray[`${whichScenarioToShow}`])//<<<<========Important Where we have maThink ++++we replace with maThinkArray[`${whichScenarioToShow}`]
            //console.log("FFFFFFFFFFFFFFFFFF", keysArray)
            for (let i = 0; i < keysArray.length; i++) {
                if ((keysArray[i].startsWith("u_") || keysArray[i].startsWith("s_") || keysArray[i].startsWith("fp_")) && !keysArray[i].includes("|")) {
                    //get facilities
                    facilities_info.push(new ResultFacilities(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].Capacity, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Coordinate - Latitude"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Longitude"]))
                }
                if (keysArray[i].includes("|")) {
                    //Disregard smaller than 1 flows
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"]) {
                        case "t_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "January";
                            break;
                        case "t_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "February";
                            break;
                        case "t_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "March";
                            break;
                        case "t_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "April";
                            break;
                        case "t_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "May";
                            break;
                        case "t_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "June";
                            break;
                        case "t_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "July";
                            break;
                        case "t_7":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "August";
                            break;
                        case "t_8":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "September";
                            break;
                        case "t_9":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "October";
                            break;
                        case "t_10":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "November";
                            break;
                        case "t_11":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "December";
                            break;
                    }
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from) {
                        case "f_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_0";
                            break;
                        case "f_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_1";
                            break;
                        case "f_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_2";
                            break;
                        case "f_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_3";
                            break;
                        case "f_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_4";
                            break;
                        case "f_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_5";
                            break;
                        case "f_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_6";
                            break;
                        case "s_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_0";
                            break;
                        case "s_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_1";
                            break;
                        case "s_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_2";
                            break;
                        case "s_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_3";
                            break;
                        case "s_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_4";
                            break;
                        case "s_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_5";
                            break;
                        case "s_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_6";
                            break;
                        case "u_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_0";
                            break;
                        case "u_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgraden Facility_1";
                            break;
                        case "u_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_2";
                            break;
                        case "u_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_3";
                            break;
                        case "u_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_4";
                            break;
                        case "u_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_5";
                            break;
                        case "u_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Upgrade Facility_6";
                            break;
                        case "fp_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_0";
                            break;
                        case "fp_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_1";
                            break;
                        case "fp_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_2";
                            break;
                        case "fp_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_3";
                            break;
                        case "fp_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_4";
                            break;
                        case "fp_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_5";
                            break;
                        case "fp_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Pyrolysis Facility_6";
                            break;
                    }
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination) {
                        case "f_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_0";
                            break;
                        case "f_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_1";
                            break;
                        case "f_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_2";
                            break;
                        case "f_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_3";
                            break;
                        case "f_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_4";
                            break;
                        case "f_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_5";
                            break;
                        case "f_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Field_6";
                            break;
                        case "s_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_0";
                            break;
                        case "s_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_1";
                            break;
                        case "s_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_2";
                            break;
                        case "s_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_3";
                            break;
                        case "s_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_4";
                            break;
                        case "s_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_5";
                            break;
                        case "s_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_6";
                            break;
                        case "u_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_0";
                            break;
                        case "u_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_1";
                            break;
                        case "u_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_2";
                            break;
                        case "u_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_3";
                            break;
                        case "u_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_4";
                            break;
                        case "u_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_5";
                            break;
                        case "u_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Upgrade Facility_6";
                            break;
                        case "fp_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_0";
                            break;
                        case "fp_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_1";
                            break;
                        case "fp_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_2";
                            break;
                        case "fp_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_3";
                            break;
                        case "fp_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_4";
                            break;
                        case "fp_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_5";
                            break;
                        case "fp_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Pyrolysis Facility_6";
                            break;
                    }
                    flow_info_Table.push(new FlowInfo(i, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"].toFixed(2), maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination))


                    if (maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"] > 1) {
                        flow_info.push(new FlowInfo(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination))
                    }
                }
                if ((!keysArray[i].startsWith("u_") && !keysArray[i].startsWith("s_") && !keysArray[i].startsWith("fp_")) && !keysArray[i].includes("|")) {
                    //get general
                    general_info.push(new GeneralInfo(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]))

                }
            }
        }

        if (whatToShow === "scenarioSCWG") {
            var keysArray = Object.keys(maThinkArray[`${whichScenarioToShow}`])//<<<<========Important Where we have maThink ++++we replace with maThinkArray[`${whichScenarioToShow}`]
            //console.log("FFFFFFFFFFFFFFFFFF", keysArray)
            for (let i = 0; i < keysArray.length; i++) {
                if ((keysArray[i].startsWith("u_") || keysArray[i].startsWith("s_") || keysArray[i].startsWith("wg_")) && !keysArray[i].includes("|")) {
                    //get facilities
                    facilities_info_WG.push(new ResultFacilities(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].Capacity, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Coordinate - Latitude"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Longitude"]))
                }
                if (keysArray[i].includes("|")) {
                    //Disregard smaller than 1 flows
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"]) {
                        case "t_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "January";
                            break;
                        case "t_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "February";
                            break;
                        case "t_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "March";
                            break;
                        case "t_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "April";
                            break;
                        case "t_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "May";
                            break;
                        case "t_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "June";
                            break;
                        case "t_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "July";
                            break;
                        case "t_7":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "August";
                            break;
                        case "t_8":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "September";
                            break;
                        case "t_9":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "October";
                            break;
                        case "t_10":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "November";
                            break;
                        case "t_11":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"] = "December";
                            break;
                    }
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from) {
                        case "f_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_0";
                            break;
                        case "f_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_1";
                            break;
                        case "f_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_2";
                            break;
                        case "f_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_3";
                            break;
                        case "f_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_4";
                            break;
                        case "f_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_5";
                            break;
                        case "f_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Field_6";
                            break;
                        case "s_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_0";
                            break;
                        case "s_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_1";
                            break;
                        case "s_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_2";
                            break;
                        case "s_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_3";
                            break;
                        case "s_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_4";
                            break;
                        case "s_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_5";
                            break;
                        case "s_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Storage Facility_6";
                            break;
                        case "wg_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_0";
                            break;
                        case "wg_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_1";
                            break;
                        case "wg_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_2";
                            break;
                        case "wg_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_3";
                            break;
                        case "wg_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_4";
                            break;
                        case "wg_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_5";
                            break;
                        case "wg_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from = "Super Critical Water Gasification Facility_6";
                            break;
                    }
                    switch (maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination) {
                        case "s_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_0";
                            break;
                        case "s_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_1";
                            break;
                        case "s_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_2";
                            break;
                        case "s_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_3";
                            break;
                        case "s_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_4";
                            break;
                        case "s_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_5";
                            break;
                        case "s_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Storage Facility_6";
                            break;
                        case "wg_0":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_0";
                            break;
                        case "wg_1":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_1";
                            break;
                        case "wg_2":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_2";
                            break;
                        case "wg_3":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_3";
                            break;
                        case "wg_4":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_4";
                            break;
                        case "wg_5":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_5";
                            break;
                        case "wg_6":
                            maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination = "Super Critical Water Gasification Facility_6";
                            break;
                    }
                    flow_info_Table_WG.push(new FlowInfo(i, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"].toFixed(2), maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination))


                    if (maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"] > 1) {
                        flow_info_WG.push(new FlowInfo(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["Biomass flow"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].from, maThinkArray[`${whichScenarioToShow}`][keysArray[i]]["in time period"], maThinkArray[`${whichScenarioToShow}`][keysArray[i]].destination))
                    }
                }
                if ((!keysArray[i].startsWith("u_") && !keysArray[i].startsWith("s_") && !keysArray[i].startsWith("wg_")) && !keysArray[i].includes("|")) {
                    //get general
                    general_info_WG.push(new GeneralInfo(keysArray[i], maThinkArray[`${whichScenarioToShow}`][keysArray[i]]))

                }
            }
        }

    }




    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarColumnsButton style={{ color: 'rgb(43, 140, 43)' }} />
                <GridToolbarFilterButton style={{ color: 'rgb(43, 140, 43)' }} />
                <GridToolbarDensitySelector style={{ color: 'rgb(43, 140, 43)' }} />
                <GridToolbarExport style={{ color: 'rgb(43, 140, 43)' }} />
            </GridToolbarContainer>
        );
    }

    const groupingModelRevenues = [
        {
            groupId: 'Revenues & Costs',
            headerAlign: 'center',
            description: "",
            children: [
                { field: "id" },
                { field: 'field' },
                { field: 'value' },

            ],
            headerClassName: 'headerTheming'
        }
    ]

    const groupingModelTranspotation = [
        {
            groupId: 'Transportation Costs',
            headerAlign: 'center',
            description: "",
            children: [
                { field: "id" },
                { field: 'field' },
                { field: 'value' },

            ],
            headerClassName: 'headerTheming'
        }
    ]

    const groupingModelConvertions = [
        {
            groupId: 'Conversion Costs',
            headerAlign: 'center',
            description: "",
            children: [
                { field: "id" },
                { field: 'field' },
                { field: 'value' },

            ],
            headerClassName: 'headerTheming'
        }
    ]
    const groupingModelGeneralInfo = [
        {
            groupId: 'General Information',
            headerAlign: 'center',
            description: "",
            children: [

                { field: 'field' },
                { field: 'value' },

            ],
            headerClassName: 'headerTheming'
        }
    ]
    const groupingModelEnvironmental = [
        {
            groupId: 'Transportation Direct Co2 Emissions',
            headerAlign: 'center',
            description: "",
            children: [

                { field: 'field' },
                { field: 'value' },

            ],
            headerClassName: 'headerTheming'
        }
    ]
    const groupingModelFlows = [
        {
            groupId: 'Routing Information',
            headerAlign: 'center',
            description: "",
            children: [

                { field: "from" },
                { field: "to" },
                { field: "flow" },
                { field: "period" },
            ],
            headerClassName: 'headerTheming'
        }
    ]


    //Columns for Details of Each scenario//
    const columnsFlow = [
        { field: "from", headerName: 'Starting Point', flex: 0.2, borderRight: 1, headerClassName: "simpleHead" },
        { field: "to", headerName: 'Destination', flex: 0.2, headerClassName: "simpleHead" },
        { field: "flow", headerName: 'Tonnes', flex: 0.2, headerClassName: "simpleHead" },
        { field: "period", headerName: 'Month', flex: 0.2, headerClassName: "simpleHead" },
    ]
    const columnsRevenue = [

        {
            field: "field", headerName: 'Revenues & Costs', flex: 0.2, borderRight: 1, headerClassName: "simpleHead", renderCell: (params) => {
                return (
                    <div style={{ fontWeight: params.row.field.includes('Total') ? 'bold' : 'normal' }}>
                        {params.value}
                    </div>
                );
            },
        },
        {
            field: "value", headerName: 'Euros', flex: 0.1, headerClassName: "simpleHead", renderCell: (params) => {
                const formattedValue = parseFloat(params.value).toLocaleString();
                return (
                    <div>
                        {formattedValue}
                    </div>
                );
            },
        },

    ]

    const revenueLabesBar = [
        'Biodiesel Revenue',
        'Biogasoline Revenue',
        'BioLPG Revenue',
        'Biochar revenue',
    ];
    const revenueLabesBarWG = [
        'Biodiesel Revenue',
        'Biogasoline Revenue',
        'Biojet fuel Revenue',
    ];

    const conversionLabesBar = [
        'Biomass Procurement Cost',
        'Annualized Cost of SCWG Facilities',
        'Conversion Cost of biomass',
        'Labor, insurance & maintenance'
    ];

    const environmentalLabelsBar = [
        'Total trailer trucks transportations',
        'Total tanker trucks transportation',
        'Mobile pyrolysis transportation',

    ]

    const environmentalLabelsBarWG = [
        'Total trailer trucks transportation',
        'Total tanker trucks transportation',
        'Total Emissions'

    ]

    const transportationLabelsBar = [
        'Field to fixed Pyrolysis facilities',
        'Field to mobile Pyrolysis facilities',
        'Fixed pyrolysis to Upgrading',
        'Mobile pyrolysis to upgrading ',
        'Field to storage',
        'Storage to fixed Pyrolysis',
        'Storage to mobile Pyrolysis',
        'Relocation of mobile facilities',
    ];

    const transportationLabelsBarWG = [
        "From the fields to the storage facilities",
        "From the fields to the FG facilities",
        "From the storage facilities to the FG facilities",
        "From the FG facilities to the FD facilities",
        "Total transportation cost"
    ];

    const monthLabels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const columnsTransportation = [

        {
            field: "field", headerName: 'Transportation Type', flex: 0.2, borderRight: 1, headerClassName: "simpleHead", renderCell: (params) => {
                return (
                    <div style={{ fontWeight: params.row.field.includes('Total') ? 'bold' : 'normal' }}>
                        {params.value}
                    </div>
                );
            },
        },
        {
            field: "value", headerName: 'Euros', flex: 0.1, headerClassName: "simpleHead", renderCell: (params) => {
                const formattedValue = parseFloat(params.value).toLocaleString();
                return (
                    <div>
                        {formattedValue}
                    </div>
                );
            },
        },

    ]

    const columnsConvertion = [

        {
            field: "field", headerName: 'Conversion', flex: 0.2, borderRight: 1, headerClassName: "simpleHead", renderCell: (params) => {
                return (
                    <div style={{ fontWeight: params.row.field.includes('Total') ? 'bold' : 'normal' }}>
                        {params.value}
                    </div>
                );
            },
        },
        {
            field: "value", headerName: 'Euros', flex: 0.1, headerClassName: "simpleHead", renderCell: (params) => {
                const formattedValue = parseFloat(params.value).toLocaleString();
                return (
                    <div>
                        {formattedValue}
                    </div>
                );
            },
        },

    ]

    const columnsEnvironmental = [

        {
            field: "field", headerName: 'Action Type', flex: 0.2, borderRight: 1, headerClassName: "simpleHead", renderCell: (params) => {
                return (
                    <div style={{ fontWeight: params.row.field.includes('Total') ? 'bold' : 'normal' }}>
                        {params.value}
                    </div>
                );
            },
        },
        {
            field: "value", headerName: 'Kg of Co2', flex: 0.1, headerClassName: "simpleHead", renderCell: (params) => {
                const formattedValue = parseFloat(params.value).toLocaleString();
                return (
                    <div>
                        {formattedValue}
                    </div>
                );
            },
        },

    ]





    ///---------------------------------------------------Fix the rows for Fast Pyrolysis--------------------------------------------------------------//
    var rowsRevenue = []
    var rowsTransportation = []
    var rowsConvertion = []
    var rowsEnvironmental = []
    var rowsGeneral = []
    if (whatToShow === "scenarioFP") {
        //console.log("MONTHLY:", maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"])

        rowsRevenue = [

            { id: 1, field: 'Biodiesel Revenue', value: maThinkArray[`${whichScenarioToShow}`]['Diesel Revenue [euros]'].toFixed(2).toLocaleString(), },
            { id: 2, field: 'Biogasoline Revenue', value: maThinkArray[`${whichScenarioToShow}`]['Gasoline Revenue [euros]'].toFixed(2).toLocaleString(), },
            { id: 3, field: 'BioLPG Revenue', value: maThinkArray[`${whichScenarioToShow}`]['LPG Revenue [euros]'].toFixed(2).toLocaleString(), },
            { id: 4, field: 'Biochar revenue', value: maThinkArray[`${whichScenarioToShow}`]['Biochar Revenue [euros]'].toFixed(2), },
            { id: 5, field: 'Total revenue', value: maThinkArray[`${whichScenarioToShow}`]["Total Revenue [euros]"].toFixed(2), },
            { id: 6, field: 'Total cost', value: maThinkArray[`${whichScenarioToShow}`]["Total Cost [euros]"].toFixed(2), },
            { id: 7, field: 'Total profit', value: maThinkArray[`${whichScenarioToShow}`]["Total Profit [euros]"].toFixed(2), },
        ];
        rowsTransportation = [
            { id: 1, field: 'Field to fixed Pyrolysis facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Fixed Fast Pyrolysis Facilities [euros]"].toFixed(2), },
            { id: 2, field: 'Field to mobile Pyrolysis facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
            { id: 3, field: 'Fixed pyrolysis to upgrading', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Fixed Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2), },
            { id: 4, field: 'Mobile pyrolysis to upgrading ', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Mobile Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2), },
            { id: 5, field: 'Field to storage', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Storage Facilities [euros]"].toFixed(2), },
            { id: 6, field: 'Storage to fixed Pyrolysis', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Fixed Fast Pyrolysis Facilities  [euros]"].toFixed(2), },
            { id: 7, field: 'Storage to mobile Pyrolysis', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
            { id: 8, field: 'Relocation of mobile facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total relocation cost of Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
            { id: 9, field: 'Total transportation cost', value: maThinkArray[`${whichScenarioToShow}`]["Total transportation cost [euros]"].toFixed(2), },
        ];
        rowsConvertion = [
            { id: 1, field: 'Labor, insurance & maintenance', value: maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2), },
            { id: 2, field: 'Annualized cost for FP-FU facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of FP-FU Facilities [euros]"].toFixed(2), },
            { id: 3, field: 'Coversion cost of biomass', value: maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), },
            { id: 4, field: 'Biomass procurement cost', value: maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), },
        ];
        rowsEnvironmental = [
            { id: 1, field: 'Trailer trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]["Environmental impact of trailer trucks transportation [kg Co2 eq]"].toFixed(2), },
            { id: 2, field: 'Tanker trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]["Environmental impact of tanker trucks transportation [kg Co2 eq]"].toFixed(2), },
            { id: 3, field: 'Mobile pyrolysis units transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental impact mobile fast pyrolysis units transportation [kg Co2 eq]'].toFixed(2), },
            { id: 4, field: 'Total', value: maThinkArray[`${whichScenarioToShow}`]["Total environmental impact [kg Co2 eq]"].toFixed(2), },
        ];


    }
    ///---------------------------------------------------ENDOF fix the rows for Fast Pyrolysis--------------------------------------------------------------//



    ///---------------------------------------------------Fix the rows for SCWG--------------------------------------------------------------//
    var rowsRevenueWG = []
    var rowsEnvironmentalWG = []
    var rowsConvertionWG = []
    var rowsTransportationWG = []
    if (whatToShow === "scenarioSCWG") {
        //console.log(maThinkArray[`${whichScenarioToShow}`])
        //console.log("MONTHLY:", maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_0'])
        rowsRevenueWG = [
            { id: 1, field: 'Biodiesel revenue', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Diesel Revenue [euros]'].replace("euros", "")).toFixed(2), },
            { id: 2, field: 'Biogasoline revenue', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Gasoline Revenue [euros]'].replace("euros", "")).toFixed(2) },
            { id: 3, field: 'Biojet fuel revenue', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Jet fuel Revenue [euros]'].replace("euros", "")).toFixed(2) },
            { id: 4, field: 'Total cost', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Total Cost'].replace("euros", "")).toFixed(2) },
            { id: 5, field: 'Total profit', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Total Profit'].replace("euros", "")).toFixed(2) },
            { id: 6, field: 'Total revenue', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Total Revenue [euros]'].replace("euros", "")).toFixed(2) },
            { id: 7, field: 'Total storage cost', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Total Storage Cost [euros]']).toFixed(2) },
            { id: 8, field: 'Total transportation', value: parseFloat(maThinkArray[`${whichScenarioToShow}`]['Total Transportation Cost [euros]']).toFixed(2) },



        ];
        rowsConvertionWG = [
            { id: 1, field: 'Annualized cost for SCWG facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of SCWG Facilities [euros]"].toFixed(2), },
            { id: 2, field: 'Conversion cost of biomass', value: maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), },
            { id: 3, field: 'Biomass procurement cost', value: maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), },
            { id: 4, field: 'Labor, insurance & maintenance', value: maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2), },
        ];
        rowsEnvironmentalWG = [

            { id: 1, field: 'Trailer trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of trailer trucks transportation [kg Co2 eq]: "].toFixed(2), },
            { id: 2, field: 'Tanker trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of tanker trucks transportation [kg Co2 eq]: "].toFixed(2), },
            { id: 3, field: 'Total', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Total environmental impact [kg Co2 eq]: "].toFixed(2), },
        ];

        rowsTransportationWG = [
            { id: 1, field: 'From the fields to the storage facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the storage facilities [euros]"].toFixed(2), },
            { id: 2, field: 'From the fields to the FG facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the FG facilities [euros]"].toFixed(2), },
            { id: 3, field: 'From the storage facilities to the FG facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the storage facilities to the FG facilities [euros]"].toFixed(2), },
            { id: 4, field: 'From the FG facilities to the FD facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the FG facilities to the FD facilities [euros]"].toFixed(2), },
            { id: 5, field: 'Total transportation cost', value: maThinkArray[`${whichScenarioToShow}`]["Total transportation cost [euros]"].toFixed(2), },
        ];

    }
    ///---------------------------------------------------ENDOF fix the rows for SCWG--------------------------------------------------------------//


    const maPalete = ["#98FB98", "#FFA07A", "#3CB371", "#FF8C00", "#228B22", "#FFA500", "#6B8E23", "#FF4500", "#2E8B57"]
    //['#5F8575', '#023020','#ffd9b5','#fc7b03', '#228B22','#dae02b' ,'#32CD32','#8A9A5B' , '#f27272']
    //


    function revenueToggle() {
        setIsShownRevenue(current => !current);
    }
    function conversionToggle() {
        setIsShownConvertion(current => !current);
    }
    function transportToggle() {
        setIsShownTransport(current => !current);
    }
    function environmentToggle() {
        setIsShownEnvironment(current => !current);
    }


    function renderRoutes(e) {
        //console.log(e)
        if (parseInt(e.row.flow) > 0) {
            setClickedRow(e.row)
            setSingle(current => !current);
            //console.log(single)
        }
        if (parseInt(e.row.flow) === 0) {
            alert("Zero Weight carried")
        }
    }


    const [showMulticriteriaDiv, setShowMulticriteriaDiv] = useState(false)
    function showMulticriteria() {
        setShowMulticriteriaDiv(current => !current);
        setWhatToShow("")
    }

    //TABLE MAIN//
    const classes = useStyles();
    const StyledTableRow = withStyles((theme) => ({
        root: {
            height: 10
        }
    }))(TableRow);

    const StyledTableCell = withStyles((theme) => ({
        root: {
            padding: "0px 16px"
        }
    }))(TableCell);

    function handleHoverColumn(columnId) {
        setActiveColumn(columnId);
        setActiveRow(null);

    }
    function handleRowHover(rowIndex) {
        setActiveRow(rowIndex);
        setActiveColumn(null);
    }




    const [expanded, setExpanded] = useState(false);
    const expandedRowsValues = ['As', 'Cd', 'Cr', 'Cu', 'Hg', 'Ni', 'Pb', 'Zn'];

    var as = []
    var cd = []
    var cr = []
    var cu = []
    var hg = []
    var ni = []
    var pb = []
    var zn = []
    for (const [key, value] of Object.entries(results.per_contaminant)) {
        //console.log(`karga:`, key, value, results.per_contaminant);
        as.push(value[0].toFixed(2), value[0].toFixed(2))
        cd.push(value[1].toFixed(2), value[1].toFixed(2))
        cr.push(value[2].toFixed(2), value[2].toFixed(2))
        cu.push(value[3].toFixed(2), value[3].toFixed(2))
        hg.push(value[4].toFixed(2), value[4].toFixed(2))
        ni.push(value[5].toFixed(2), value[5].toFixed(2))
        pb.push(value[6].toFixed(2), value[6].toFixed(2))
        zn.push(value[7].toFixed(2), value[7].toFixed(2))
    }



    const arrays = {
        'As': as,
        'Cd': cd,
        'Cr': cr,
        'Cu': cu,
        'Hg': hg,
        'Ni': ni,
        'Pb': pb,
        'Zn': zn
    };

    const [myHeight, setMyHeight] = useState("250px")
    const toggleExpanded = () => {
        setExpanded(!expanded);
        if (expanded === false) {
            setMyHeight("250px")
        }
        else {
            setMyHeight("650px")
        }
    };

    const handleButtonClick = (rowId) => {
        toggleExpanded(); // Toggle the state when the button is clicked

    };

    const handleRowClickTable = (rowIndex) => {
        //setExpanded(false)
        setWhatToShow("comparison")
        setShowMulticriteriaDiv(false)
        consoleRowData(rowIndex);
        setActiveRow(rowIndex);
        setActiveColumn(null); // Clear column highlight when a row is highlighted

    };




    const [showUserInfo, setShowUserInfo] = useState(false)

    function showTHEusersInfo() {
        setShowUserInfo(current => !current)
        // Swal.fire({
        //     title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Scenario Details</h5>`,//field info to become a prop
        //     html:
        //         `<div style={{ display: "flex", flexDirection: "column" }}>
        //         <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        //             <div>Discount Rate</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.DiscountRate}</div>
        //         </div>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             <div>Harvesting Unit Cost</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.HarvestingUnitCost}</div>
        //         </div>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             <div>LPG Price</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.LPGPrice}</div>
        //         </div>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             <div>Diesel Price</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.dieselPrice}</div>
        //         </div>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             <div>Jet Fuel Price</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.jetfuelPrice}</div>
        //         </div>
        //         <div style={{ display: "flex", justifyContent: "center" }}>
        //             <div>Gasoline Price</div>
        //             <div>:</div>
        //             <div>${results.userinfo.values_df.gasolinePrice}</div>
        //         </div>
        //     </div>`,//to become a prop
        //     confirmButtonColor: "green",
        //     confirmButtonText: "OK",
        // })
    }


    ///======================PDF STAFF=====================//

    // const options: Options = {
    //     filename: "results.pdf",
    //     page: {
    //         margin: 20
    //     }
    // };


    var pdfRef = useRef();
    var getTargetElement = () => document.getElementById("all_the_pdf");

    //const downloadPdf = () => generatePDF(getTargetElement, options);

    //const downloadPdf = () => generatePDF(pdfRef, {filename: 'page.pdf'});




    ///======================PDF STAFF=====================//






    const headers = [
        { label: 'hectares', key: `hectares` },
        { label: 'latitude', key: 'latitude' },
        { label: 'longitude', key: 'longitude' },
    ];
    const [arrayForExport, setArrayForExport] = useState([]);
    function handleDownloadFields() {
        const newArrayForExport = [];
        var arrayOfFields = results.field;
        //console.log("YOLLO",arrayOfFields)
        for (let i = 0; i < arrayOfFields.length; i++) {
            const newEntry = {
                hectares: arrayOfFields[i].area / 10000,
                latitude: arrayOfFields[i].center.lat,
                longitude: arrayOfFields[i].center.lng
            };

            // Check if the newEntry already exists in newArrayForExport
            const exists = newArrayForExport.some(
                (entry) =>
                    entry.latitude === newEntry.latitude &&
                    entry.longitude === newEntry.longitude &&
                    entry.hectares === newEntry.hectares
            );

            if (!exists) {
                newArrayForExport.push(newEntry);
            }
        }

        setArrayForExport(newArrayForExport);
    }

    function fixMykeyAndValue(key, value) {
        if (key === "As") {
            return `Arsenic:${value}(mg/kg)`
        }
        if (key === "cd") {
            return `Cadmium : ${value}(mg/kg)`
        }
        if (key === "cr") {
            return `Chromium:${value}(mg/kg)`
        }
        if (key === "cu") {
            return `Copper:${value}(mg/kg)`
        }
        if (key === "hg") {
            return `Mercury:${value}(mg/kg)`
        }
        if (key === "ni") {
            return `Nickel:${value}(mg/kg)`
        }
        if (key === "pb") {
            return `Lead:${value}(mg/kg)`
        }
        if (key === "zn") {
            return `Zinc:${value}(mg/kg)`
        }
        if (key === "climatic_zone_C") {
            return `Climatic Zone: ${value}`
        }
        if (key === "org_matter_content") {
            return `Organic Matter Content: ${value * 100}(%)`
        }
        if (key === "pH") {
            return `pH: ${value}`
        }

    }

    function fixTheValues(value) 
    {
        return `[As:${value[0]},cd:${value[1]},cr:${value[2]},cu:${value[3]},hg:${value[4]},ni:${value[5]},pb:${value[6]},zn:${value[7]}]`

    }

    return (
        <div className="details_view">
            <div className="sidebar">
                <Sidebar currentView="details" />
            </div>
            <div className="central_details_view" ref={pdfRef} id="all_the_pdf">
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        //height: "99vh",
                        margin: "3px",
                        boxShadow: 1,
                        overflowY: "scroll",
                        '& .headerTheming': {
                            fontSize: "17px",
                            background: "rgb(202, 255, 194)"
                        },
                        '& .simpleHead': {
                            fontSize: "16px",
                        },

                    }}>
                    {/* This TABLE HOLDS THE INFO OF ALL MULTIPLE RUNS */}
                    <div style={{ margin: "5px 5px 5px 5px", width: '99%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px", height: { myHeight } }}>

                        <div style={{ display: "flex", justifyContent: "space-around", width: '99%', }}>
                            {/* <img style={{ height: "25px", marginLeft: "2px", border:"1px solid black", borderRadius:"50px" }} src={questionmark} /> */}
                            {/* <div style={{ cursor: "pointer", textAlign: "center", margin: "5px 5px 0px 5px", border: "1px solid black", borderBottom: "0", fontSize: "15px", color: "rgb(45, 45, 45)", padding: "5px", borderRadius: "5px 5px 0px 0px", backgroundImage: "linear-gradient(to bottom right, rgb(158, 199, 147) , white, rgb(158, 199, 147) )", width: '33%' }} onClick={showTheGif}>General Usage Guidelines</div> */}
                            <div style={{ cursor: "pointer", textAlign: "center", margin: "5px 5px 0px 5px", border: "1px solid black", borderBottom: "0", fontSize: "15px", color: "rgb(45, 45, 45)", padding: "5px", borderRadius: "5px 5px 0px 0px", background: "rgb(158, 199, 147)", width: '33%', color: "white", fontWeight: 600 }} onClick={showTHEusersInfo} >Show Scenario Input & Info</div>
                            <div onClick={addName} style={{ cursor: "pointer", textAlign: "center", margin: "5px 5px 0px 5px", border: "1px solid black", borderBottom: "0", fontSize: "15px", color: "rgb(45, 45, 45)", padding: "5px", borderRadius: "5px 5px 0px 0px", background: "rgb(158, 199, 147)", width: '33%', color: "white", fontWeight: 600 }}>Save</div>
                        </div >

                        {showUserInfo ?
                            <div style={{ display: "flex", justifyContent: "space-evenly", border: "1px grey solid", borderRadius: "5px" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
                                    <strong>User Input</strong>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <div>Discount Rate</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.DiscountRate * 100}%</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>Harvesting Unit Cost</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.HarvestingUnitCost}(€/odt)</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>BioLPG Price</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.LPGPrice}(€/l)</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>Biodiesel Price</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.dieselPrice}(€/l)</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>Biojet Fuel Price</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.jetfuelPrice}(€/l)</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>Biogasoline Price</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.gasolinePrice}(€/l)</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div>Biochar Price</div>
                                        <div>:</div>
                                        <div>{results.userinfo.values_df.price_BC}(€/t)</div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <strong>Contaminants Information</strong>
                                    {results.mlStaff !== undefined ?
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                            <div>{Object.entries(results.mlStaff).map(([key, value]) => (
                                                <div key={key}>
                                                    <div>{fixMykeyAndValue(key, value)}</div>
                                                </div>
                                            ))}
                                            </div>
                                        </div> :
                                        results.per_contaminant !== undefined ?
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                                <div>{Object.entries(results.per_contaminant).map(([key, value]) => (
                                                    <div key={key}>
                                                        <div>{key}: {fixTheValues(value)}</div>
                                                    </div>
                                                ))}
                                                </div>
                                            </div> :
                                            <>No contaminants information for this scenario</>}
                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <strong>Biomass Information</strong>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <div>{Object.entries(results.biomass).map(([key, value]) => (
                                            <div key={key}>
                                                <div>{key}:{(value * 1000).toFixed(2)} (kg/ha)</div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "15%" }}>
                                    <div onClick={handleDownloadFields}><CSVLink data={arrayForExport} headers={headers}>Export Fields</CSVLink></div>
                                    <strong>Guidelines</strong>
                                    <div style={{fontSize:"14px"}}>Copy and download all the needed information to re-run the scenario.</div>
                                    <div style={{fontSize:"14px"}}>Save the scenario and retrieve these information at any time.</div>
                                </div>
                            </div>
                            : <></>}




                        {/* <TableContainer component={Paper} >
                            <table style={{ width: "100%", height: { myHeight }, borderCollapse: "collapse" }} aria-label="dynamic table">
                                <thead>
                                    <tr style={{ border: "1px solid black" }}>
                                        <td style={{ border: "1px solid black", backgroundColor: 'lightgreen' }}> KPI </td>
                                        {columns.map((column) => (
                                            <td
                                                key={column.id}
                                                onClick={() => handleColumnClick(column.id, column)}
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: activeColumn === column.id ? 'rgba(0, 195, 0, 0.3)' : 'rgba(0, 195, 0, 0.5)',
                                                    border: "1px solid black"
                                                }}
                                            >
                                                {column.label}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <tr
                                                className={classes.tableRow}
                                                onClick={() => handleRowClickTable(rowIndex)}
                                                style={{
                                                    border: "1px solid black",
                                                    borderTop: rowIndex === 0 ? "1px solid black" : "none",
                                                    borderBottom: rowIndex === rows.length - 1 ? "1px solid black" : "none",
                                                    backgroundColor: activeRow === rowIndex ? 'rgba(0, 195, 0, 0.3)' : 'inherit',
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <td style={{ border: "1px solid black", margin: "0px", backgroundColor: 'lightgreen' }}>
                                                    {row.id}
                                                    {rowIndex === 8 && (
                                                        <button onClick={handleButtonClick} style={{ zIndex: 10 }}>{expanded ? "Revert" : "Expand"}</button>
                                                    )}
                                                </td>
                                                {columns.map((column) => (
                                                    <td
                                                        className={classes.tableCell}
                                                        key={column.id}
                                                        style={{
                                                            backgroundColor: activeColumn === column.id && activeRow === null ? 'rgba(0, 195, 0, 0.3)' : '',
                                                            paddingTop: "0px",
                                                            paddingBottom: 0,
                                                            border: "1px solid black",
                                                            cursor: column.id !== 0 ? 'pointer' : 'default'
                                                        }}
                                                        onClick={column.id !== 0 ? () => handleColumnClick(column.id, column) : null}
                                                    >
                                                        {column.id !== 0 && row[column.id]}
                                                    </td>
                                                ))}
                                            </tr>
                                            {expanded && rowIndex === 8 && expandedRowsValues.map((value, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: "1px solid black", margin: "0px", backgroundColor: 'rgb(235, 245, 198)' }}>
                                                        {value} [mg/kg dry biomass]
                                                    </td>
                                                    {arrays[value].map((item, columnIndex) => (
                                                        <td
                                                            key={columnIndex}
                                                            className={classes.tableCell}
                                                            style={{
                                                                paddingTop: "0px",
                                                                paddingBottom: 0,
                                                                border: "1px solid black"
                                                            }}
                                                        >
                                                            {item}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </TableContainer> */}
                        <TableContainer component={Paper}>
                            <table style={{ width: "100%", height: { myHeight }, borderCollapse: "collapse" }} aria-label="dynamic table">
                                <thead>
                                    <tr style={{ border: "1px solid black" }}>
                                        <td style={{ border: "1px solid black", backgroundColor: 'lightgreen', cursor: 'pointer' }}>
                                            KPI
                                        </td>
                                        {columns.map((column) => (
                                            <td
                                                key={column.id}
                                                onClick={() => handleColumnClick(column.id, column)}
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: activeColumn === column.id ? 'rgba(0, 195, 0, 0.3)' : 'rgba(0, 195, 0, 0.5)',
                                                    border: "1px solid black",
                                                    transition: "background-color 0.3s ease"
                                                }}
                                                onMouseOver={(e) => e.target.style.backgroundColor = 'green'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = activeColumn === column.id ? 'rgba(0, 195, 0, 0.3)' : 'rgba(0, 195, 0, 0.5)'}
                                            >
                                                {column.label}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <Tooltip title={INFOTEXTHOVER[rowIndex].text || ''} placement="top">
                                                <tr
                                                    className={classes.tableRow}
                                                    onClick={() => handleRowClickTable(rowIndex)}
                                                    style={{
                                                        border: "1px solid black",
                                                        borderTop: rowIndex === 0 ? "1px solid black" : "none",
                                                        borderBottom: rowIndex === rows.length - 1 ? "1px solid black" : "none",
                                                        backgroundColor: activeRow === rowIndex ? 'rgba(0, 195, 0, 0.3)' : 'inherit',
                                                        cursor: "default",
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            border: "1px solid black",
                                                            margin: "0px",
                                                            backgroundColor: 'lightgreen',
                                                            cursor: 'pointer',
                                                            transition: "background-color 0.3s ease"
                                                        }}
                                                        onMouseOver={(e) => e.target.style.backgroundColor = 'green'}
                                                        onMouseOut={(e) => e.target.style.backgroundColor = 'lightgreen'}
                                                    >
                                                        {row.id}
                                                        {rowIndex === 8 && (
                                                            <button onClick={handleButtonClick} style={{ zIndex: 10, borderRadius: "5px", marginLeft: "10px", background: "white", cursor: "pointer" }}>
                                                                {expanded ? "Revert" : "Expand"}
                                                            </button>
                                                        )}
                                                    </td>
                                                    {columns.map((column) => (
                                                        <td
                                                            className={classes.tableCell}
                                                            key={column.id}
                                                            style={{
                                                                backgroundColor: activeColumn === column.id && activeRow === null ? 'rgba(0, 195, 0, 0.3)' : '',
                                                                paddingTop: "0px",
                                                                paddingBottom: 0,
                                                                border: "1px solid black",
                                                                cursor: column.id !== 0 ? 'default' : 'pointer'
                                                            }}
                                                            onClick={column.id !== 0 ? () => handleColumnClick(column.id, column) : null}
                                                        >
                                                            {column.id !== 0 && row[column.id]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </Tooltip>
                                            {expanded && rowIndex === 8 && expandedRowsValues.map((value, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: "1px solid black", margin: "0px", backgroundColor: 'rgb(235, 245, 198)' }}>
                                                        {value} [mg/kg dry biomass]
                                                    </td>
                                                    {arrays[value].map((item, columnIndex) => (
                                                        <td
                                                            key={columnIndex}
                                                            className={classes.tableCell}
                                                            style={{
                                                                paddingTop: "0px",
                                                                paddingBottom: 0,
                                                                border: "1px solid black"
                                                            }}
                                                        >
                                                            {item}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </TableContainer>

                        {showMulticriteriaDiv ?
                            <div style={{ width: "99%", marginBottom: "500px" }} ><div className="multicriteria_hidden" style={{ width: "98.5%", height: "45px" }} >Multicriteria Assessment<RemoveCircleOutlineIcon onClick={showMulticriteria} style={{ color: "red", cursor: "pointer", boxShadow: "1px 1px 3px 1px red", borderRadius: "50px", marginLeft: "5px" }} /></div><Multicriteria kpis={kPIs} identifiers={identifiers} /></div> :
                            <div className="multicriteria_hidden" style={{ width: "98.5%", height: "45px" }} >Multicriteria Assessment<AddCircleOutlineIcon onClick={showMulticriteria} style={{ color: "rgb(119, 109, 226)", cursor: "pointer", boxShadow: "1px 1px 3px 1px rgba(119, 109, 226, 0.83)", borderRadius: "50px", marginLeft: "5px" }} /></div>}


                    </div>

                    {/* END OF TABLE THAT HOLDS THE INFO OF ALL MULTIPLE RUNS */}
                    {whatToShow === "scenarioFP" ?
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                //height: "99vh",
                                margin: "75px 3px 3px 3px",
                                // boxShadow: 1,
                                // overflowY: "scroll",
                                '& .headerTheming': {
                                    fontSize: "17px",
                                    background: "rgb(202, 255, 194)"
                                },
                                '& .simpleHead': {
                                    fontSize: "16px",
                                },

                            }}>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}//for row height
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showCellRightBorder={true}
                                    showColumnVerticalBorder={true}
                                    rows={rowsRevenue}
                                    columns={columnsRevenue}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelRevenues}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Revenues & Costs<Tooltip title="Toggle Chart Type" arrow onClick={revenueToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip> </div>

                                {isShownRevenue ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },
                                                data: [
                                                    { id: 1, value: maThinkArray[`${whichScenarioToShow}`]['Gasoline Revenue [euros]'].toFixed(2), label: 'Biogasoline Revenue [euros]' },
                                                    { id: 2, value: maThinkArray[`${whichScenarioToShow}`]['Biochar Revenue [euros]'].toFixed(2), label: 'Biochar Revenue [euros]' },
                                                    { id: 3, value: maThinkArray[`${whichScenarioToShow}`]["LPG Revenue [euros]"].toFixed(2), label: "BioLPG Revenue [euros]" },
                                                    { id: 4, value: maThinkArray[`${whichScenarioToShow}`]["Diesel Revenue [euros]"].toFixed(2), label: "Biodiesel Revenue [euros]" },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                padding: 2,

                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />
                                </div> : <div style={{ width: "95%", height: "500", marginLeft: "20px" }}><BarChart
                                    xAxis={[{
                                        scaleType: 'band', data: revenueLabesBar, tickLabelStyle: {
                                            angle: 0, fontSize: 10,
                                        },
                                    }]}
                                    series={[
                                        { data: [maThinkArray[`${whichScenarioToShow}`]['Diesel Revenue [euros]'].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Gasoline Revenue [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["LPG Revenue [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]['Biochar Revenue [euros]'].toFixed(2),], label: 'Revenue in Euros', id: 1, color: '#097969' },//maThink["Total Revenue [euros]"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>

                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    headerRowHeight={100}
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}

                                    rows={rowsConvertion}
                                    columns={columnsConvertion}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelConvertions}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Convertion cost breakdown<Tooltip title="Toggle Chart Type" arrow onClick={conversionToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip> </div>
                                {isShownConvertion ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },
                                                data: [
                                                    { id: 1, label: 'Biomass Procurement Cost', value: maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), },
                                                    { id: 2, label: 'Annualized Cost of FP-FU Facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of FP-FU Facilities [euros]"].toFixed(2), },
                                                    { id: 3, label: 'Conversion Cost of biomass', value: maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), },
                                                    { id: 4, label: 'Labor, insurance & maintenance', value: maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2), },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,

                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />
                                </div> : <div style={{ width: "95%", height: "500", marginLeft: "20px" }}><BarChart
                                    xAxis={[{ scaleType: 'band', data: conversionLabesBar }]}
                                    series={[
                                        { data: [maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of FP-FU Facilities [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2),], label: 'Conversion cost in Euros', id: 1, color: '#097969' },//maThink["Total Profit [euros]"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    rows={rowsTransportation}
                                    columns={columnsTransportation}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelTranspotation}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Transportation Costs<Tooltip title="Toggle Chart Type" arrow onClick={transportToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip></div>
                                {isShownTransport ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },

                                                // arcLabel: (item) => `${item.label} (${item.value})`,
                                                data: [
                                                    { id: 1, label: 'Field to fixed Pyrolysis facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Fixed Fast Pyrolysis Facilities [euros]"].toFixed(2), },
                                                    { id: 2, label: 'Field to mobile Pyrolysis facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
                                                    { id: 3, label: 'Fixed pyrolysis to upgrading', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Fixed Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2), },
                                                    { id: 4, label: 'Mobile pyrolysis to upgrading ', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Mobile Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2), },
                                                    { id: 5, label: 'Field to storage', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Storage Facilities [euros]"].toFixed(2), },
                                                    { id: 6, label: 'Storage to fixed Pyrolysis', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Fixed Fast Pyrolysis Facilities  [euros]"].toFixed(2), },
                                                    { id: 7, label: 'Storage to mobile Pyrolysis', value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
                                                    { id: 8, label: 'Relocation of mobile facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total relocation cost of Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2), },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,

                                            },
                                        }}

                                    />
                                </div> : <div style={{ width: "100%", height: "500", }}><BarChart
                                    xAxis={[{ scaleType: 'band', data: transportationLabelsBar, tickLabelStyle: { angle: 45, fontSize: 11, textAnchor: 'start', }, }]}
                                    series={[
                                        {
                                            data: [
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Fixed Fast Pyrolysis Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Fixed Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Mobile Fast Pyrolysis Facilities to the Fixed Upgrading Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the Storage Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Fixed Fast Pyrolysis Facilities  [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the Storage Facilities to the Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total relocation cost of Mobile Fast Pyrolysis Facilities [euros]"].toFixed(2),
                                            ],

                                            label: 'Transportation cost in Euros', id: 1, color: '#097969'
                                        },
                                    ]}
                                    leftAxis={null}
                                    margin={{
                                        bottom: 100
                                    }}
                                /></div>}
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    rows={rowsEnvironmental}
                                    columns={columnsEnvironmental}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelEnvironmental}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Transportation Direct Co2 Emissions<Tooltip title="Toggle Chart Type" arrow onClick={environmentToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip></div>
                                {isShownEnvironment ? <div style={{ position: "relative", height: "90%" }}>
                                    {/* <div style={{ position: "absolute", backgroundColor: "rgba(100,100,100,0)", width: "47%", height: "78%", margin: "6.7% 0 0 01%", borderRadius: "50%", boxShadow: "0px 0px 10px 2px rgba(22, 242, 55, 0.83)" }}></div> */}
                                    <PieChart
                                        colors={maPalete}

                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },

                                                // arcLabel: (item) => `${item.label} (${item.value})`,
                                                data: [
                                                    { id: 1, label: 'Total trailer trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]["Environmental impact of trailer trucks transportation [kg Co2 eq]"].toFixed(2), },
                                                    { id: 2, label: 'Total tanker trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]["Environmental impact of tanker trucks transportation [kg Co2 eq]"].toFixed(2), },
                                                    { id: 3, label: 'Mobile pyrolysis transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental impact mobile fast pyrolysis units transportation [kg Co2 eq]'].toFixed(2), },
                                                    //{ id: 4, label: 'Total', value: maThinkArray[`${whichScenarioToShow}`]["Total environmental impact [kg Co2 eq]"].toFixed(2), },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />

                                </div> : <div style={{ width: "100%", height: "500", marginLeft: "20px", }}><BarChart
                                    xAxis={[{
                                        scaleType: 'band', data: environmentalLabelsBar, tickLabelStyle: {
                                            angle: 15, fontSize: 9, textAnchor: 'start',
                                        },
                                    }]}
                                    series={[
                                        { data: [maThinkArray[`${whichScenarioToShow}`]["Environmental impact of trailer trucks transportation [kg Co2 eq]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Environmental impact of tanker trucks transportation [kg Co2 eq]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]['Environmental impact mobile fast pyrolysis units transportation [kg Co2 eq]'].toFixed(2),], label: 'Kilograms of CO2', id: 1, color: '#097969' },//maThink["kg Co2 eq total"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>
                            {/* STaff for month Biomass movement  */}
                            <div style={{ margin: "5px 5px 5px 5px", width: '100%', height: "400px", boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <Tooltip title='The graph shows the biomass amount in storage (as a sum for all storage facilities) at the end of each month (month X), by adding the amount available at the end of the previous month (X-1) and the new biomass amount harvested during month X minus the biomass sent to biofuel conversion units in month X. Harvesting is assumed to happen within a single month.' placement="top">
                                    <div><img style={{ width: "25px" }} src={questionmark}></img></div>
                                </Tooltip>
                                <BarChart
                                    xAxis={[{ scaleType: 'band', data: monthLabels, tickLabelStyle: { angle: 45, fontSize: 11, textAnchor: 'start', }, }]}
                                    series={[
                                        {
                                            data: [
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_0']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_1']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_2']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_3']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_4']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_5']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_6']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_7']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_8']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_9']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_10']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_11']),
                                            ],

                                            label: 'Monthly Biomass Storage', id: 1, color: '#097969'
                                        },
                                    ]}
                                    leftAxis={null}
                                    margin={{
                                        bottom: 100
                                    }}
                                />
                            </div>
                            {/* STaff for month Biomass movement END */}
                            <div style={{ margin: "5px 5px 5px 5px", width: '100%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}//for row height
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                        "&:hover": {
                                            cursor: "pointer",
                                        },
                                    }}
                                    onCellClick={(e) => { renderRoutes(e) }}
                                    showCellRightBorder={true}
                                    showColumnVerticalBorder={true}
                                    rows={flow_info_Table}
                                    columns={columnsFlow}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelFlows}
                                />
                            </div>

                            <div style={{ width: '100%', margin: '5px', border: "1px solid rgba(63, 62, 62, 0.3)", borderRadius: "5px" }}>
                                {single ? <MapJsSingle general={general_info} fields={sendTheFieldstoMap} facilities={facilities_info} flow={flow_info} clickedRow={clickedRow} singleRow={single}></MapJsSingle> : <MapJS general={general_info} fields={sendTheFieldstoMap} facilities={facilities_info} flow={flow_info} clickedRow={clickedRow} singleRow={single} />}                            </div>
                        </Box> : <></>}

                    {whatToShow === "scenarioSCWG" ?
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                //height: "99vh",
                                margin: "75px 3px 3px 3px",
                                // boxShadow: 1,
                                // overflowY: "scroll",
                                '& .headerTheming': {
                                    fontSize: "17px",
                                    background: "rgb(202, 255, 194)"
                                },
                                '& .simpleHead': {
                                    fontSize: "16px",
                                },

                            }}>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}//for row height
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showCellRightBorder={true}
                                    showColumnVerticalBorder={true}
                                    rows={rowsRevenueWG}
                                    columns={columnsRevenue}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelRevenues}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Revenues Breakdown<Tooltip title="Toggle Chart Type" arrow onClick={revenueToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip> </div>

                                {isShownRevenue ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },
                                                data: [
                                                    { id: 1, value: parseFloat(maThinkArray[`${whichScenarioToShow}`]["Economic Results"]['Gasoline Revenue [euros]']).toFixed(2), label: 'Biogasoline Revenue [euros]' },
                                                    { id: 2, value: parseFloat(maThinkArray[`${whichScenarioToShow}`]["Economic Results"]["Jet fuel Revenue [euros]"]).toFixed(2), label: "Biojet fuel Revenue [euros]" },
                                                    { id: 3, value: parseFloat(maThinkArray[`${whichScenarioToShow}`]["Economic Results"]["Diesel Revenue [euros]"]).toFixed(2), label: "Biodiesel Revenue [euros]" },

                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                padding: 2,

                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />
                                </div> : <div style={{ width: "95%", height: "500", marginLeft: "20px" }}><BarChart
                                    xAxis={[{
                                        scaleType: 'band', data: revenueLabesBarWG, tickLabelStyle: {
                                            angle: 0, fontSize: 10,
                                        },
                                    }]}
                                    series={[
                                        {
                                            data: [
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Diesel Revenue [euros]']).toFixed(2),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Gasoline Revenue [euros]']).toFixed(2),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]['Economic Results']['Jet fuel Revenue [euros]']).toFixed(2),
                                            ],

                                            label: 'Euros', id: 1, color: '#097969'
                                        },//maThink["Total Revenue [euros]"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>



                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    rows={rowsConvertionWG}
                                    columns={columnsConvertion}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelConvertions}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Convertion cost breakdown<Tooltip title="Toggle Chart Type" arrow onClick={conversionToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip> </div>
                                {isShownConvertion ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },
                                                data: [
                                                    { id: 1, label: 'Biomass Procurement Cost', value: maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), },
                                                    { id: 2, label: 'Annualized Cost of SCWG Facilities', value: maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of SCWG Facilities [euros]"].toFixed(2), },
                                                    { id: 3, label: 'Conversion Cost of biomass', value: maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), },
                                                    { id: 4, label: 'Labor, insurance & maintenance', value: maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2), },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,

                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />
                                </div> : <div style={{ width: "95%", height: "500", marginLeft: "20px" }}><BarChart
                                    xAxis={[{ scaleType: 'band', data: conversionLabesBar }]}
                                    series={[
                                        { data: [maThinkArray[`${whichScenarioToShow}`]["Total Biomass Procurement Cost [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Annualized Cost of SCWG Facilities [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Conversion Cost of biomass [euros]"].toFixed(2), maThinkArray[`${whichScenarioToShow}`]["Total Yearly Labor, Insurance & Maintenance [euros]"].toFixed(2),], label: 'Conversion cost in Euros', id: 1, color: '#097969' },//maThink["Total Profit [euros]"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    rows={rowsTransportationWG}
                                    columns={columnsTransportation}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelTranspotation}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Transportation Costs<Tooltip title="Toggle Chart Type" arrow onClick={transportToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip></div>
                                {isShownTransport ? <div style={{ height: "90%" }}>
                                    <PieChart
                                        colors={maPalete}
                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },

                                                // arcLabel: (item) => `${item.label} (${item.value})`,
                                                data: [
                                                    { id: 1, label: "Fields to the storage facilities", value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the storage facilities [euros]"].toFixed(2), },
                                                    { id: 2, label: "Fields to the FG facilities", value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the FG facilities [euros]"].toFixed(2), },
                                                    { id: 3, label: "Storage facilities to the FG facilities", value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the storage facilities to the FG facilities [euros]"].toFixed(2), },
                                                    { id: 4, label: "FG facilities to the FD facilities", value: maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the FG facilities to the FD facilities [euros]"].toFixed(2), },
                                                ],
                                            }
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,

                                            },
                                        }}

                                    />
                                </div> : <div style={{ width: "100%", height: "500", }}><BarChart
                                    xAxis={[{ scaleType: 'band', data: transportationLabelsBarWG, tickLabelStyle: { angle: 45, fontSize: 11, textAnchor: 'start', }, }]}
                                    series={[
                                        {
                                            data: [
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the storage facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the fields to the FG facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the storage facilities to the FG facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total Transportation cost from the FG facilities to the FD facilities [euros]"].toFixed(2),
                                                maThinkArray[`${whichScenarioToShow}`]["Total transportation cost [euros]"].toFixed(2),
                                            ],

                                            label: 'Transportation cost in Euros', id: 1, color: '#097969'
                                        },
                                    ]}
                                    leftAxis={null}
                                    margin={{
                                        bottom: 100
                                    }}
                                /></div>}
                            </div>

                            <div style={{ margin: "5px 5px 5px 5px", width: '49.2%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    rows={rowsEnvironmentalWG}
                                    columns={columnsEnvironmental}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelEnvironmental}
                                />
                            </div>
                            <div style={{ margin: "5px 5px 5px 5px", width: '49%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", background: "rgba(214, 214, 214, 0.3)", borderRadius: "5px" }}>
                                <div style={{ display: "flex", padding: "10px 10px 0px 200px ", justifyContent: "space-between", color: "grey" }}>Transportation Direct Co2 Emissions<Tooltip title="Toggle Chart Type" arrow onClick={environmentToggle}><MoreVertIcon sx={{ fontSize: 30, "&:hover": { color: "green", cursor: "pointer" } }} /></Tooltip></div>
                                {isShownEnvironment ? <div style={{ position: "relative", height: "90%" }}>
                                    {/* <div style={{ position: "absolute", backgroundColor: "rgba(100,100,100,0)", width: "47%", height: "78%", margin: "6.7% 0 0 01%", borderRadius: "50%", boxShadow: "0px 0px 10px 2px rgba(22, 242, 55, 0.83)" }}></div> */}
                                    <PieChart
                                        colors={maPalete}

                                        series={[
                                            {
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 100, additionalRadius: 0 },

                                                // arcLabel: (item) => `${item.label} (${item.value})`,
                                                data: [
                                                    { id: 1, label: 'Total trailer trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of trailer trucks transportation [kg Co2 eq]: "].toFixed(2), },
                                                    { id: 2, label: 'Total tanker trucks transportation', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of tanker trucks transportation [kg Co2 eq]: "].toFixed(2), },
                                                    // { id: 4, label: 'Solid biomass', value: maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["kg Co2 eq of trailer trucks transportation"].toFixed(2), },
                                                ],
                                            },
                                        ]}
                                        margin={{ right: 320 }}
                                        slotProps={{
                                            legend: {
                                                direction: 'column',
                                                position: { vertical: 'middle', horizontal: 'right' },
                                                paddingLeft: 20,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                markGap: 2,
                                                itemGap: 15,
                                            },
                                        }}
                                    />

                                </div> : <div style={{ width: "100%", height: "500", marginLeft: "20px", }}><BarChart
                                    xAxis={[{
                                        scaleType: 'band', data: environmentalLabelsBarWG, tickLabelStyle: {
                                            angle: 15, fontSize: 9, textAnchor: 'start',
                                        },
                                    }]}
                                    series={[
                                        { data: [parseFloat(maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of trailer trucks transportation [kg Co2 eq]: "]).toFixed(2), parseFloat(maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Environmental impact of tanker trucks transportation [kg Co2 eq]: "]).toFixed(2), parseFloat(maThinkArray[`${whichScenarioToShow}`]['Environmental Performance']["Total environmental impact [kg Co2 eq]: "]).toFixed(2),], label: 'Kilograms of CO2', id: 1, color: '#097969' },//maThink["kg Co2 eq total"].toFixed(2),
                                    ]}
                                    leftAxis={null}
                                /></div>}
                            </div>

                            {/* STaff for month Biomass movement  */}
                            <div style={{ margin: "5px 5px 5px 5px", width: '100%', height: "400px", boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <Tooltip title='The graph shows the biomass amount in storage (as a sum for all storage facilities) at the end of each month (month X), by adding the amount available at the end of the previous month (X-1) and the new biomass amount harvested during month X minus the biomass sent to biofuel conversion units in month X. Harvesting is assumed to happen within a single month.' placement="top">
                                    <div><img style={{ width: "25px" }} src={questionmark}></img></div>
                                </Tooltip>

                                <BarChart
                                    xAxis={[{ scaleType: 'band', data: monthLabels, tickLabelStyle: { angle: 45, fontSize: 11, textAnchor: 'start', }, }]}
                                    series={[
                                        {
                                            data: [
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_0']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_1']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_2']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_3']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_4']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_5']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_6']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_7']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_8']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_9']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_10']),
                                                parseFloat(maThinkArray[`${whichScenarioToShow}`]["Monthly Biomass Storage"]['month_t_11']),
                                            ],

                                            label: 'Monthly Biomass Storage', id: 1, color: '#097969'
                                        },
                                    ]}
                                    leftAxis={null}
                                    margin={{
                                        bottom: 100
                                    }}
                                />
                            </div>
                            {/* STaff for month Biomass movement END */}

                            <div style={{ margin: "5px 5px 5px 5px", width: '100%', boxShadow: "3px 3px 17px 1px rgba(130, 130, 130, 0.83)", borderRadius: "5px" }}>
                                <DataGrid
                                    rowHeight={43}//for row height
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    sx={{
                                        borderRadius: "5px",
                                        "& .MuiDataGrid-cell": {
                                            color: "grey",
                                        },
                                        "&:hover": {
                                            cursor: "pointer",
                                        },
                                    }}
                                    onCellClick={(e) => { renderRoutes(e) }}
                                    showCellRightBorder={true}
                                    showColumnVerticalBorder={true}
                                    rows={flow_info_Table_WG}
                                    columns={columnsFlow}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{ columnGrouping: true }}
                                    columnGroupingModel={groupingModelFlows}
                                />
                            </div>

                            <div style={{ width: '100%', margin: '5px', border: "1px solid rgba(63, 62, 62, 0.3)", borderRadius: "5px" }}>
                                {single ? <MapJsSingle general={general_info_WG} fields={sendTheFieldstoMap} facilities={facilities_info_WG} flow={flow_info_WG} clickedRow={clickedRow} singleRow={single}></MapJsSingle> : <MapJS general={general_info_WG} fields={sendTheFieldstoMap} facilities={facilities_info_WG} flow={flow_info_WG} clickedRow={clickedRow} singleRow={single} />}
                            </div>
                        </Box> : <></>}
                    {whatToShow === "comparison" && expanded === false ? <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            height: "50vh",
                            width: "98%",
                            marginTop: "55px",

                            // boxShadow: 1,
                            // overflowY: "scroll",
                            '& .headerTheming': {
                                fontSize: "17px",
                                background: "rgb(202, 255, 194)"
                            },
                            '& .simpleHead': {
                                fontSize: "16px",
                            },

                        }}>
                        <BarChart

                            className="custombottom"
                            xAxis={[{ scaleType: 'band', data: compareBarChartLabels, }]}
                            series={[{ data: compareBarChartValues, label: barChartCurrent, id: 1, color: '#097969' }]}
                            yAxis={[
                                {
                                    label: barChartYaxis,
                                },

                            ]}
                            sx={{
                                //change left yAxis label styles
                                "& .MuiChartsAxis-directionY .MuiChartsAxis-label": {
                                    transform: "translateX(-50px) !important"
                                },
                            }}
                            margin={{ left: 150 }}


                        />
                        {/* TRIAL FOR STACKED */}
                        {/* {doit ? <BarChart

                            className="custombottom"
                            xAxis={[{ scaleType: 'band', data: compareBarChartLabels, }]}
                            series={[seriesMa1]}
                            yAxis={[
                                {
                                    label: barChartYaxis,
                                },

                            ]}
                            sx={{
                                //change left yAxis label styles
                                "& .MuiChartsAxis-directionY .MuiChartsAxis-label": {
                                    transform: "translateX(-50px) !important"
                                },
                            }}
                            margin={{ left: 150 }}


                        /> : <></>} */}
                    </Box> : <></>}

                </Box>
            </div>

            {/* <button onClick={()=>{navigate("/");}}>Star over</button> */}
        </div >
    )
}

export default DetailsView;