import { MapContainer, TileLayer, Circle, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../src/scss/mapjs.scss";
import RoutingTrial from "./RoutingTrial";
import L from 'leaflet';
import { LOCATIONS } from "./components/locations";
import { useEffect, useState } from "react";
import pyroIcon from "./images/fire.png";
import { click } from "@testing-library/user-event/dist/click";
import location from './images/location-icon.png';
import gear from './images/gears-vector-icon.jpg';
import RoutingSingle from "./RoutingSingle";
import scwg from './images/wg.png';
import wgfp from './images/fp_wg.png';
import storage from './images/storage.png';
import upgradeIcon from "./images/upgraden.png";


export default function MapJsSingle(general, facilities, flow, clickedRow, singleRow) {



  console.log(general)
  var flows = [];

  var resultFacilities = [];
  var resultFields = [];
  var pyroFacilities = [];
  var storageFacilities = [];
  var upgradeFacilities = [];
  var start;
  var finish;
  var renderIcons = [];

  var fields = general.fields;
  console.log(fields)
  for (let i = 0; i < fields.length; i++) {
    fields[i].id = `f_${i}`
    console.log(fields[i])
  }
  var hardCodedFields = [
    // {
    //   id: "f_0",
    //   lat: 58.52484941,
    //   long: -3.333502065,
    //   radius: 449.6205
    // },
    // {
    //   id: "f_1",
    //   lat: 55.75714208,
    //   long: -2.777306979,
    //   radius: 610.864
    // },
    // {
    //   id: "f_2",
    //   lat: 55.86133936,
    //   long: -4.24821144,
    //   radius: 2129.41
    // },
    // {
    //   id: "f_3",
    //   lat: 57.6969384,
    //   long: -3.994933233,
    //   radius: 1910.046
    // },
    // {
    //   id: "f_4",
    //   lat: 56.61848991,
    //   long: -2.753734059,
    //   radius: 1567.493
    // },
    // {
    //   id: "f_5",
    //   lat: 56.78113,
    //   long: -5.47388,
    //   radius: 349.16
    // },
  ];

  function calculateRadiusFromArea(area) {
    const pi = Math.PI;
    const radius = Math.sqrt(area / pi);
    return radius;
  }
  if (general) {

    flows = [...general.flow];
    console.log("GENERAL Single", general)
    resultFacilities = [...general.facilities]


    for (let y = 0; y < fields.length; y++) {
      if (general.clickedRow.from.startsWith("Field"))
      {
        general.clickedRow.from=general.clickedRow.from.replace("Field", "f")
        console.log("TRIAL",general.clickedRow.from)
      }

      if (general.clickedRow.from === fields[y].id) {
        start = { "type": general.clickedRow.from, "from": [fields[y].center.lat, fields[y].center.lng], "radius": calculateRadiusFromArea(fields[y].area) }
        console.log("START",start)
      }
    }

    for (let i = 0; i < resultFacilities.length; i++) {
      if (general.clickedRow.from.startsWith("Super Critical Water Gasification"))
      {
        general.clickedRow.from=general.clickedRow.from.replace("Super Critical Water Gasification Facility", "wg")
        console.log("TRIAL",general.clickedRow.from)
      }
      if (general.clickedRow.from.startsWith("Pyrolysis"))
      {
        general.clickedRow.from=general.clickedRow.from.replace("Pyrolysis Facility", "fp")
        console.log("TRIAL",general.clickedRow.from)
      }
      if (general.clickedRow.from.startsWith("Upgrade"))
      {
        general.clickedRow.from=general.clickedRow.from.replace("Upgrade Facility", "u")
        console.log("TRIAL",general.clickedRow.from)
      }
      if (general.clickedRow.from.startsWith("Storage"))
      {
        general.clickedRow.from=general.clickedRow.from.replace("Storage Facility", "s")
        console.log("TRIAL",general.clickedRow.from)
      }
      if (general.clickedRow.from === resultFacilities[i].id) {
        start = { "type": general.clickedRow.from, "from": [resultFacilities[i].lat, resultFacilities[i].long] }
        console.log("START",start)
      }

    }
    for (let i = 0; i < resultFacilities.length; i++) {
      if (general.clickedRow.to.startsWith("Super Critical Water Gasification"))
      {
        general.clickedRow.to=general.clickedRow.to.replace("Super Critical Water Gasification Facility", "wg")
        console.log("TRIAL",general.clickedRow.to)
      }
      if (general.clickedRow.to.startsWith("Pyrolysis"))
      {
        general.clickedRow.to=general.clickedRow.to.replace("Pyrolysis Facility", "fp")
        console.log("TRIAL",general.clickedRow.to)
      }
      if (general.clickedRow.to.startsWith("Upgrade"))
      {
        general.clickedRow.to=general.clickedRow.to.replace("Upgrade Facility", "u")
        console.log("TRIAL",general.clickedRow.to)
      }
      if (general.clickedRow.to.startsWith("Storage"))
      {
        general.clickedRow.to=general.clickedRow.to.replace("Storage Facility", "s")
        console.log("TRIAL",general.clickedRow.to)
      }
      if (general.clickedRow.to === resultFacilities[i].id) {
        finish = { "type": general.clickedRow.to, "to": [resultFacilities[i].lat, resultFacilities[i].long] }
        console.log("END",finish)
      }
    }
  }



  let customMarkerUpgrade = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(5, 22),
      iconSize: new L.Point(24, 24),
      iconUrl: upgradeIcon
    }
  });

  let customMarkerPyro = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(7, 22),
      iconSize: new L.Point(24, 24),
      iconUrl: wgfp
    }
  });
  let customMarkerSCWG = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(10, 27),
      iconSize: new L.Point(30, 30),
      iconUrl: wgfp
    }
  });
  let factoryMarker = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(5, 22),
      iconSize: new L.Point(24, 24),
      iconUrl: storage//'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png'
    }
  });





  return (
    <MapContainer center={[general.fields[0].center.lat, general.fields[0].center.lng]} zoom={7} style={{ height: "70vh" }}>
      {start.type.startsWith("f_") ?
        <Circle
          key={start.type}
          center={[start.from[0], start.from[1]]}
          pathOptions="blue"
          radius={start.radius}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }} />

        : <></>}
      {finish.type.startsWith("f_") ?
        <Circle
          key={finish.type}
          center={[finish.to[0], finish.to[1]]}
          pathOptions="blue"
          // radius={start.radius}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }} />

        : <></>}
      {start.type.startsWith("s_") ?
        <Marker
          icon={new factoryMarker()}
          position={[start.from[0], start.from[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(3,171,3)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Storage Facility {finish.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Storage Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{start.from[0].toFixed(2)} {start.from[1].toFixed(2)}</p></Popup></Marker> : <></>}

      {finish.type.startsWith("s_") ?
        <Marker
          icon={new factoryMarker()}
          position={[finish.to[0], finish.to[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(3,171,3)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Storage Facility {finish.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Storage Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{finish.to[0].toFixed(2)} {finish.to[1].toFixed(2)}</p></Popup></Marker> : <></>}

      {start.type.startsWith("u_") ?

        <Marker
          icon={new customMarkerUpgrade()}
          position={[start.from[0], start.from[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Upgrade Facility {start.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Upgrade Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{start.from[0].toFixed(2)} {start.from[1].toFixed(2)}</p></Popup></Marker> : <></>}

      {finish.type.startsWith("u_") ?
        <Marker
          icon={new customMarkerUpgrade()}
          position={[finish.to[0], finish.to[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Upgrade Facility {finish.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Upgrade Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{finish.to[0].toFixed(2)} {finish.to[1].toFixed(2)}</p></Popup></Marker> : <></>}
      {start.type.startsWith("wg_") ?

        <Marker
          icon={new customMarkerSCWG()}
          position={[start.from[0], start.from[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>SCWG Facility {start.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />SCWG Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{start.from[0].toFixed(2)} {start.from[1].toFixed(2)}</p></Popup></Marker> : <></>}

      {finish.type.startsWith("wg_") ?
        <Marker
          icon={new customMarkerSCWG()}
          position={[finish.to[0], finish.to[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "rgb(17, 36, 242)", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>SCWG Facility {finish.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />SCWG Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{finish.to[0].toFixed(2)} {finish.to[1].toFixed(2)}</p></Popup></Marker> : <></>}
      {start.type.startsWith("fp_") ?

        <Marker
          icon={new customMarkerPyro()}
          position={[start.from[0], start.from[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "red", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Fast Pyrolysis {start.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Fast Pyrolysis Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{start.from[0].toFixed(2)} {start.from[1].toFixed(2)}</p></Popup></Marker> : <></>}
      {finish.type.startsWith("fp_") ?

        <Marker
          icon={new customMarkerPyro()}
          position={[finish.to[0], finish.to[1]]}
          eventHandlers={{
            click: (e) => {
              console.log(e)
            }
          }}
        ><Popup><h3 style={{ textAlign: "center", color: "white", background: "red", paddingBottom: "5px", paddingTop: "5px", margin: "-15px -25px 0px -21px", borderRadius: "5px 5px 0px 0px" }}>Fast Pyrolysis {finish.capacity}</h3><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={gear} alt="area icon" />Fast Pyrolysis Facility</p><p><img style={{ width: "15px", marginRight: "2.5px", marginTop: "1px" }} src={location} alt="area icon" />{finish.to[0].toFixed(2)} {finish.to[1].toFixed(2)}</p></Popup></Marker> : <></>}

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <RoutingSingle routes={flows} fields={fields} facilities={resultFacilities} currentRoute={general.clickedRow}></RoutingSingle>
    </MapContainer>
  );
}