import { KeycloakProvider, useKeycloak } from "keycloak-react-web";
import { Navigate, useNavigate } from "react-router-dom";
import logo from './images/ceresis_logo.png';
import text from './images/ceresis_text_g-remove.png';
import title from './images/log_remove.png';
import good from './images/good_pngre.png';
import Swal from "sweetalert2";
import ukflag from './images/ukflag.png';
import uaflag from './images/ukranianflag.png';
import keycloak from "./Keycloak";

function Apage() {
    const navigate = useNavigate()
    const { keycloak, initialized } = useKeycloak();
    //console.log(keycloak.tokenParsed.sub)


    const downloadPdfEn = () => {
        let pdfPath = process.env.PUBLIC_URL + '/trialpdf.pdf';  // Try using process.env.PUBLIC_URL first

        const link = document.createElement('a');
        link.href = pdfPath;
        link.setAttribute('download', 'CERESiS User Manual_en.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadPdfUkr = () => {
        let pdfPath = process.env.PUBLIC_URL + '/trialpdf_ua.pdf';  // Try using process.env.PUBLIC_URL first

        const link = document.createElement('a');
        link.href = pdfPath;
        link.setAttribute('download', 'CERESiS User Manual_ua.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    async function downloadTheManual() {
        const { value: choise } = await Swal.fire({
            title: ` <h5 style='background-color:green; color:white; margin:-25px -30px -5px  -30px; padding:7px; border-radius:5px 5px 0px 0px'>User Manual Language</h5>`,//field info to become a prop   
            input: 'radio',
            inputOptions: {
                'english': `<span style="font-size:18px;  font-weight:600;">English<img style="width:25px; margin-left:5px; margin-top:3px;" src='${ukflag}'/></span><br/>`,
                'ukranian': `<span style="font-size:18px;  font-weight:600;">Υкраїнська<img style="width:25px; margin-left:5px; margin-top:3px;" src='${uaflag}'/></span>`
            },
            confirmButtonColor: "green",
            confirmButtonText: "Download",//to become a prop
            allowOutsideClick: true,
            focusConfirm: false,

        })
        if (choise === 'english') {
            downloadPdfEn();
            //swalPopUp_OptimizationINput();
        }
        if (choise === 'ukranian') {
            //alert("END OF DEMO");
            // fireOptimizationDialog();
            downloadPdfUkr();
        }
    }


    return (
        <div style={{ width: "100%" }}>
            <div style={{ margin: "50px 100px 30px 100px", border: "0.3px solid grey", height: "85%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "5px", background: " radial-gradient(circle, rgba(255,255,255,1) 38%, rgba(149,255,100,1) 88%, rgba(101,255,31,1) 100%)", boxShadow: "2px 10px 10px rgba(0,0,0,0.3)" }}>
                {/* <div style={{ display: "flex", justifyContent:"flex-start" }}> */}

                <div style={{ fontSize: "50px", fontWeight: "600", color: "green", margin: "20px" }}>CERESiS <span style={{ color: "rgb(23,50,17)" }}>DSS</span></div>
                {/* <img src={title} style={{height:"110px", marginRight:"2%"}} /> */}
                <img src={good} style={{ height: "450px", zIndex: "1", }} />
                {/* <img src={background} style={{height:"300px"}}/> */}
                {/* </div> */}
                <div style={{ margin: "20px", marginLeft: "5%", display: "flex", justifyContent: "center" }}>

                    {!keycloak.authenticated && (
                        <button style={{ cursor: "pointer", marginBottom: "10px", color: "white", background: "green", borderRadius: "5px", fontSize: "18px", padding: "10px", fontWeight: "600" }} onClick={() => keycloak.login()}>Login</button>)}
                    <button style={{ cursor: "pointer", marginBottom: "10px", color: "white", background: "green", borderRadius: "5px", fontSize: "18px", padding: "10px", fontWeight: "600", marginLeft: "30px", marginRight: "30px" }} onClick={downloadTheManual}>Manual</button>
                    {!!keycloak.authenticated && (
                        <><button style={{ cursor: "pointer", marginBottom: "10px", marginRight: "30px", color: "white", background: "green", borderRadius: "5px", fontSize: "18px", padding: "10px", fontWeight: "600" }} onClick={() => keycloak.logout()}>Logout</button><button style={{ cursor: "pointer", marginBottom: "10px", color: "white", background: "green", borderRadius: "5px", fontSize: "18px", fontWeight: "600", padding: "10px" }} onClick={() => navigate('/main')}>Visit DSS</button></>)}
                </div>
            </div>


        </div>

    )
}

export default Apage;