import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import './scss/itinerary.css';


export default function RoutingSingle(routes, fields, facilities, currentRoute) {

    var singleRoute=[];
    var broughtRoutes = [];

    
    var hardCodedFields = routes.fields;

    console.log("in single route:",routes.facilities)
    console.log("in single route:",routes.fields)
    
    var routesToRender = [];
    //broughtRoutes = [...Object.values(routes)];
    
    for (let i=0;i<routes.facilities.length;i++)
    {
        for(let m=0;m<routes.fields.length;m++)
        {
            if (routes.currentRoute.from===routes.facilities[i].id)
            {
                if(routes.currentRoute.to===routes.fields[m].id)
                {
                    console.log("from",routes.facilities[i].id, "to",routes.fields[m].id)
                    singleRoute.push({ "from": [routes.facilities[i].lat, routes.facilities[i].long], "finalDest": [routes.fields[m].center.lat, routes.fields[m].center.lng] })
                }
                for (let y=0;y<routes.facilities.length;y++)
                {
                    if(routes.currentRoute.to===routes.facilities[y].id)
                    {
                       
                        console.log("from",routes.facilities[i].id, "to",routes.facilities[y].id)
                        singleRoute.push({ "from": [routes.facilities[i].lat, routes.facilities[i].long], "finalDest": [routes.facilities[y].lat, routes.facilities[y].long] })
                    }
                }
                
            }
            if (routes.currentRoute.from===routes.fields[m].id)
            {
                if(routes.currentRoute.to===routes.fields[m].id)
                {
                    console.log("from",routes.fields[m].id, "to",routes.fields[m].id)
                }
                if(routes.currentRoute.to===routes.facilities[i].id)
                {
                    console.log("from",routes.fields[m].id, "to",routes.facilities[i].id)
                    singleRoute.push({ "from": [routes.fields[m].center.lat, routes.fields[m].center.lng], "finalDest": [routes.facilities[i].lat, routes.facilities[i].long] })

                }
            }
        }
    }


    // if (routes && routes.facilities) {
    //     broughtRoutes = [...Object.values(routes)];
    //     console.log("We  Got the routes", broughtRoutes[0])
    //     console.log("we got facilities", broughtRoutes[1])
    //     console.log("we got Single Route", routes.currentRoute)
        
        
    //     for (let i = 0; i < broughtRoutes[0].length; i++) {

    //         if (routes.currentRoute.from === broughtRoutes[0][i].from) {

    //             if (routes.currentRoute.to === broughtRoutes[0][i].to) {
    //                 var current = broughtRoutes[0][i];
    //                 var currentFlow = routes.currentRoute.flow;
    //                 if (current.from.startsWith("f_")) {
    //                     for (let y = 0; y < hardCodedFields.length; y++) {
    //                         if (current.from === hardCodedFields[y].id) {
    //                             //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long])
    //                             for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                                 if (current.to === broughtRoutes[1][z].id) {

    //                                     singleRoute.push({ "from": [hardCodedFields[y].center.lat, hardCodedFields[y].center.lng], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //                 if (!current.from.startsWith("f_")) {
    //                     for (let x = 0; x < broughtRoutes[1].length; x++) {
    //                         if (current.from === broughtRoutes[1][x].id) {
    //                             //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long])
    //                             for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                                 if (current.to === broughtRoutes[1][z].id) {

    //                                     singleRoute.push({ "from": [broughtRoutes[1][x].lat, broughtRoutes[1][x].long], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })

    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }



              
    //         }


    //         ///CHECKIN STARTING POINT IN FIELDS
    //         if (broughtRoutes[0][i].from.startsWith("f_")) {
    //             for (let y = 0; y < hardCodedFields.length; y++) {
    //                 if (broughtRoutes[0][i].from === hardCodedFields[y].id) {
    //                     //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long])
    //                     for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                         if (broughtRoutes[0][i].to === broughtRoutes[1][z].id) {
    //                             //console.log("From field", broughtRoutes[0][i].from , [hardCodedFields[y].lat, hardCodedFields[y].long],"TO facility", broughtRoutes[1][z].id, [broughtRoutes[1][z].lat, broughtRoutes[1][z].long])
    //                             if (hardCodedFields[y].center.lat.toFixed(8) !== broughtRoutes[1][z].lat.toFixed(8) && hardCodedFields[y].center.lng.toFixed(8) !== broughtRoutes[1][z].long.toFixed(8)) {
    //                                 routesToRender.push({ "from": [hardCodedFields[y].center.lat, hardCodedFields[y].center.lng], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })
    //                             }

    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //     }
    //     for (let i = 0; i < broughtRoutes[0].length; i++) {
    //         //CHECKING STARTING POINT FACILITIES
    //         for (let x = 0; x < broughtRoutes[1].length; x++) {
    //             if (broughtRoutes[0][i].from === broughtRoutes[1][x].id) {
    //                 //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long])
    //                 for (let z = 0; z < broughtRoutes[1].length; z++) {
    //                     if (broughtRoutes[0][i].to === broughtRoutes[1][z].id) {
    //                         //console.log("From facility", broughtRoutes[0][i].from, [broughtRoutes[1][x].lat, broughtRoutes[1][x].long],"TO facility", broughtRoutes[1][z].id, [broughtRoutes[1][z].lat, broughtRoutes[1][z].long])

    //                         if (broughtRoutes[1][x].lat.toFixed(8) !== broughtRoutes[1][z].lat.toFixed(8) && broughtRoutes[1][x].long.toFixed(8) !== broughtRoutes[1][z].long.toFixed(8)) {
    //                             routesToRender.push({ "from": [broughtRoutes[1][x].lat, broughtRoutes[1][x].long], "finalDest": [broughtRoutes[1][z].lat, broughtRoutes[1][z].long] })
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //     }

    //     console.log("YEEEEEEEEEEEEE", routesToRender)


    // }

    const map = useMap();
    //console.log(mywaypoints)

    useEffect(() => {
        if (!map) return;
        if (singleRoute.length>0)
        {
            let randomColor = Math.floor(Math.random() * 16777215).toString(16);
            var control=L.Routing.control({
                waypoints: [
                    L.latLng(singleRoute[0].from),
                    L.latLng(singleRoute[0].finalDest),
                ],
                router:L.Routing.mapbox("pk.eyJ1IjoiY2hyaXN0b3M4OSIsImEiOiJjbTFqcGtwbmEwMTd5MmxzNGgzbmNzZHN6In0.pE3po1sAWAXkkDv86VsJIA"),                          //`#${randomColor}`
                lineOptions: { styles: [{ color: `red`, opacity: 1, weight: 2 }] },
                //collapsible: true,
                show:false,
                createMarker: function (i, waypoint, n) {
                    const marker = L.marker(waypoint.latLng, {
                        draggable: false,
                        opacity: 1,
                        icon: L.icon({
                            shadowUrl: null,
                            iconAnchor: new L.Point(5, 22),
                            iconSize: new L.Point(1, 1),
                            iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
                        })
                    });
                    return marker;
                },
            }).on('routesfound', function (e) {
                var routes = e.routes;
                var summary = routes[0].summary;
                // alert distance and time in km and minutes
                console.log('Total distance is ' + summary.totalDistance / 1000 + ' km and total time is ' + Math.round(summary.totalTime % 3600 / 60) + ' minutes');
            }).addTo(map);
        }
        return () => map;
    },[])

    
    //return null;
}