// import { FeatureGroup, MapContainer, TileLayer, Circle, CircleMarker, Popup, Marker, GeoJSON } from "react-leaflet";
// import { useEffect, useRef, useState } from "react";
// import { EditControl } from "react-leaflet-draw";
// import SiteObject from './SiteObject';
// import FacilitiesObject from "./FacilitiesObject";
// import { createControlComponent } from "@react-leaflet/core";
// import "leaflet/dist/leaflet.css";
// import "leaflet-draw/dist/leaflet.draw.css";
// import Swal from 'sweetalert2';
// import { stringify } from 'flatted';
// import L, { polygon } from 'leaflet';
// import qm from './images/qm.jpg';
// import "leaflet-routing-machine";
// import "./scss/map.css";
// import { Helmet } from "react-helmet";
// import { INFOTEXT } from "./components/constatstext";
// import { STYLE } from "./components/styles";
// import { LOCATIONS } from "./components/locations";
// import pyroIcon from "./images/pyrolysis.png";
// //import european from "./output.json";
// import sample from './noice.json';


// function Mapleaf3({ createArrayOfFields, createArrayOfFacilities, createZone, gotFieldsFromCsv }) {

//   let array = [];
//   const [finalArray,setFinalArray]=useState([]);
//   let arrayFacilities = [];
//   var myar = []
//   const mapRef = useRef();

//   console.log('FieldsFromCsv:', gotFieldsFromCsv)
//   const [fetching, setFetching] = useState(0);
//   const [pointInfo, setPointInfo] = useState([]);
//   const [helpContext, setHelpContext] = useState("");
//   const [finishedLoadingFieldsFacilities, setFinishedLoadingFieldsFacilities] = useState(false);
//   const [facilityInputRequired, setFacilityInputRequired] = useState(true);


//   let fieldInputRequired = true;


//   //*****Adding Custom Marker******/

//   const [iconUrl, setIconUrl] = useState('https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png')

//   let customMarker = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(5, 22),
//       iconSize: new L.Point(24, 24),
//       iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png'
//     }
//   });

//   let customMarker1 = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(5, 22),
//       iconSize: new L.Point(24, 24),
//       iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png'
//     }
//   });

//   let customMarker2 = L.Icon.extend({
//     options: {
//       shadowUrl: null,
//       iconAnchor: new L.Point(5, 22),
//       iconSize: new L.Point(24, 24),
//       iconUrl: pyroIcon
//     }
//   });

//   function _onDrawStart(e) {
//     updateIcon("pyrolysis");
//   }

//   const _deleted = (e) => {

//     console.log(e)
//     let info = e.layers._layers;
//     let idArray = Object.keys(info)
//     console.log(idArray, array)
//     let id = idArray[0];



//     if (array.length > 0) {
//       for (let i = 0; i < array.length; i++) {
//         for (let y = 0; y < idArray.length; y++) {
//           if (array[i].id === parseInt(idArray[y])) {
//             array.splice(i, 1);
//             myar.splice(i, 1)
//           }
//         }
//       }
//     }
//     if (arrayFacilities.length > 0) {
//       for (let i = 0; i < arrayFacilities.length; i++) {

//         for (let y = 0; y < idArray.length; y++) {
//           if (arrayFacilities[i].id === parseInt(idArray[y])) {
//             arrayFacilities.splice(i, 1);
//           }
//         }
//       }
//     }
//     sendFieldInfoToMainnView([...array]);
//     sendFacililitiesInfoToMainnView([...arrayFacilities]);
//   }

//   // function _edited(e) {
//   //   console.log(pointInfo);
//   //   let editedPosition = updateThePointInfo(e.layers._layers)[1];
//   //   let radius = updateThePointInfo(e.layers._layers)[0];
//   // }

//   //*************** Helper function to Update initial info of a Field/Facility in map ***************/
//   function updateThePointInfo(initial_info) {
//     let some = stringify(initial_info);
//     let latLongEdited = eval(some);//==>LatLONG of edited 
//     //console.log("YOOOOO", initial_info)
//     let curID = Object.keys(initial_info)[0];//==> ID OF EDITED
//     let editedPos = latLongEdited[3];
//     //console.log("EDITED ", editedPos)
//     let radius = latLongEdited[1]._mRadius;//==>Radius of edited 
//     return [radius, editedPos, curID]
//   }



//   //**********POP UP for inputing information for Fields--  */
//   async function fireTheSwalForField(e) {
//     setHelpContext("fields_add");
//     fieldInputRequired = true;

//     if (e.layerType === "circle")
//       array.push(new SiteObject(e.layer._leaflet_id, e.layer._latlng, Math.PI * (e.layer._mRadius * e.layer._mRadius)));
//     if (e.layerType === "polygon")
//       array.push(new SiteObject(e.layer._leaflet_id, e.layer.getBounds().getCenter(), L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0])));

//     console.log("SUPERARR",array)
//     setFinalArray(array)
//     console.log("SUPERARR FINA",finalArray)
//     sendFieldInfoToMainnView([...array]);
//     //}
//   }

//   //


//   //**************************Fixing the Data to be send **************************/









//   //**********POP UP for the information of Facilities--**********/

//   function updateIcon(type) {
//     if (type === "pyrolysis") {
//       customMarker = customMarker2;
//     }
//     if (type === "upgrade") {
//       setIconUrl('https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png');
//     }
//   }


//   async function fireTheSwalForFacilities(e) {
//     setHelpContext("facilities_add");
//     const { value: formValues } = await Swal.fire({
//       title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Facility Information<Tooltip title='${INFOTEXT.FACILITY_HELP}' arrow><img src=${qm} style="width:30px; float:right; cursor:pointer" /></Tooltip></h5>`,//field info to become a prop
//       html:
//         `${STYLE.POPUP_ADD_FACILITY}`,
//       focusConfirm: true,
//       confirmButtonColor: "green",
//       confirmButtonText: "ADD",
//       allowOutsideClick: false,
//       preConfirm: () => {
//         return [
//           document.getElementById('swal-input1').value,
//         ]
//       }
//     })
//     updateIcon(formValues[0]);
//     for (let i = 0; i < formValues.length; i++) {
//       if (formValues[i] === 'choice' || formValues[i] === null) {
//         fireTheSwalForFacilities(e)
//       }
//       else {
//         if (e.layerType === "marker") {
//           arrayFacilities.push(new FacilitiesObject(e.layer._leaflet_id, e.layer._latlng, formValues[0]));
//         }
//         //console.log(arrayFacilities);
//         sendFacililitiesInfoToMainnView([...arrayFacilities]);
//         break;
//       }

//     }
//   }

//   ///*******SENDING Field info to main view as props*/
//   function sendFieldInfoToMainnView(array) {
//     createArrayOfFields(array)
//   }
//   function sendFacililitiesInfoToMainnView(arrayOfFacilities) {
//     createArrayOfFacilities(arrayOfFacilities)
//   }

//   function sendZonesToMain(mostCommon) {
//     createZone(mostCommon)
//   }






//   // useEffect(() => {

//   //   if (!finishedLoadingFieldsFacilities && array.length === 0) {
//   //     for (let i = 0; i < LOCATIONS.length; i++) {
//   //       array.push(new SiteObject(LOCATIONS[i].id, { lat: LOCATIONS[i].lat, lng: LOCATIONS[i].long }, Math.PI * (LOCATIONS[i].radius * LOCATIONS[i].radius), "formValues[0]", "formValues[1]", "formValues[2]", "formValues[3]"));
//   //       arrayFacilities.push(new FacilitiesObject(LOCATIONS[i].id, { lat: LOCATIONS[i].lat, lng: LOCATIONS[i].long }, "obscure"));
//   //     }
//   //     setTimeout(() => {

//   //       let keys = Object.keys(mapRef.current._layers);


//   //       keys.forEach((key) => {
//   //         //console.log(mapRef.current._layers[key]._mRadius);
//   //         for (let i = 0; i < array.length; i++) {
//   //           if (JSON.stringify(mapRef.current._layers[key]._latlng) === JSON.stringify(array[i].center)) {
//   //             //console.log("Field Match: ", array[i].id, " ", key)
//   //             array[i].id = parseInt(key);

//   //           }
//   //         }
//   //         for (let i = 0; i < arrayFacilities.length; i++) {
//   //           if (JSON.stringify(mapRef.current._layers[key]._latlng) === JSON.stringify(arrayFacilities[i].center)) {
//   //             //console.log("Facilities Match: ", arrayFacilities[i].id, " ", key)
//   //             arrayFacilities[i].id = parseInt(key);

//   //           }
//   //         }
//   //       });


//   //       console.log("array on load", array)
//   //     }, 1000);

//   //     setFinishedLoadingFieldsFacilities(true)
//   //   }
//   //   sendFieldInfoToMainnView([...array]);
//   //   sendFacililitiesInfoToMainnView([...arrayFacilities])
//   // }, [])

//   //***************Function Called on START of CREATE */

//   //**********Function called when we create a shape on map **********/

//   let isOnCreated = false
//   async function _onCreated(e) {

//     console.log(e)
//     isOnCreated = true
//     console.log("mos", isOnCreated)
//     if (e.layerType === "polygon") {
//       fireTheSwalForField(e);//call the PopUp for field INFO

//     }
//     if (e.layerType === "circle") {
//       fireTheSwalForField(e);
//       console.log("EOOO", e.target)
//       console.log(e.target._events);
//       //e.target.on("click", function (e) { console.log(e, "-----", e.originalEvent.target._leaflet_id) })//

//     }
//     if (e.layerType === "marker") {
//       fireTheSwalForFacilities(e);
//     }
//   }

//   // STAFF to LOAD from CSV-----------------------------------
//   function radiusFromHectares(hectares) {
//     const areaInSquareMeters = hectares * 10000;
//     const radius = Math.sqrt(areaInSquareMeters / Math.PI);
//     return radius;
//   }
//   const featureGroupRef = useRef();
//   useEffect(() => {

//     const drawnItems = featureGroupRef.current;
//     if (!drawnItems) return;
//     drawnItems.clearLayers();
//     gotFieldsFromCsv.forEach(area => {
//       const { hectares, latitude, longitude } = area;
//       const radius = radiusFromHectares(hectares);
//       const center = [latitude, longitude];
//       const circle = L.circle(center, { radius });
//       drawnItems.addLayer(circle);
//       console.log("FUCK", drawnItems)
//     });
//     const layers = drawnItems.getLayers(); // Get all layers as an array

//     layers.forEach(layer => {
//       console.log("SEXY: ", layer);
//       array.push(new SiteObject(layer._leaflet_id, { lat: layer._latlng.lat, lng: layer._latlng.lng }, Math.PI * (layer._mRadius * layer._mRadius), "formValues[0]", "formValues[1]", "formValues[2]", "formValues[3]"));
//     });
//     console.log("SUPERARR inCSV",array)
//     console.log("SUPERARR FINA",finalArray)
//     sendFieldInfoToMainnView(array);

//     if (drawnItems.length > 0) {
//       const allZones = drawnItems.map(area => area.zone);
//       const mostCommon = mostCommonElement(allZones);
//       console.log("MOST COMMON ZONE:", mostCommon);
//       // Call your function to send the most common zone to the main component
//       sendZonesToMain(mostCommon);
//     }
//   }, [gotFieldsFromCsv]);



//   //find the LEAFLET ID AND ASSIGN AND FIX THE THING
//   useEffect(() => {

//     if (array.length === 0) {
//       array = []
//       sendFieldInfoToMainnView([...array]);

//     }
//     if (!finishedLoadingFieldsFacilities && array.length === 0) {

//       setTimeout(() => {

//         let some = mapRef.current._layers;
//         for (const keys in some) {
//           if (some[keys] instanceof L.Circle) {
//             array.push(new SiteObject(some[keys]._leaflet_id, { lat: some[keys]._latlng.lat, lng: some[keys]._latlng.lng }, Math.PI * (some[keys]._mRadius * some[keys]._mRadius), "formValues[0]", "formValues[1]", "formValues[2]", "formValues[3]"));
//           }
//           if (some[keys] instanceof L.Marker) {
//             arrayFacilities.push(new FacilitiesObject(some[keys]._leaflet_id, { lat: some[keys]._latlng.lat, lng: some[keys]._latlng.lng }, "obscure"));
//           }
//         }
//         sendFieldInfoToMainnView([...array]);
//         sendFacililitiesInfoToMainnView([...arrayFacilities])
//       }, 1000)
//     }
//     if (array.length > 0) {

//       sendFieldInfoToMainnView([...array]);
//       sendFacililitiesInfoToMainnView([...arrayFacilities])
//     }

//   }, [])






//   //ON EDIT
//   const _onEdited = (e) => {


//     if (array.length > 0) {

//       let editedPosition = updateThePointInfo(e.layers._layers)[1];
//       let radius = updateThePointInfo(e.layers._layers)[0];
//       let id = parseInt(updateThePointInfo(e.layers._layers)[2]);
//       let layers = e.layers;



//       for (let i = 0; i < array.length; i++) {

//         //finding the UniqueID of the Polygon/Circle/Marker that we are editing
//         let indexToUpdate = array.findIndex((obj => obj.id === id));
//         console.log(indexToUpdate)
//         layers.eachLayer(function (layer) {
//           if (layer instanceof L.Circle) {
//             //******* UpDating area and center of CIrcle on EDIT *******/
//             array[indexToUpdate].area = Math.PI * (radius * radius);
//             array[indexToUpdate].center = editedPosition;
//             //console.log("we get in the circle", array[indexToUpdate].area, indexToUpdate, editedPosition)

//           }
//           if (layer instanceof L.Polygon) {
//             //******* UpDating area and center of Polygon on EDIT *******/
//             array[indexToUpdate].area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
//             array[indexToUpdate].center = layer.getBounds().getCenter();
//           }

//         });
//         sendFieldInfoToMainnView([...array]);

//       }
//     }

//     if (arrayFacilities.length > 0) {
//       let editedPosition = updateThePointInfo(e.layers._layers)[1];
//       let id = parseInt(updateThePointInfo(e.layers._layers)[2]);
//       let layers = e.layers;

//       for (let i = 0; i < arrayFacilities.length; i++) {
//         let indexToUpdate = arrayFacilities.findIndex((obj => obj.id === id));
//         layers.eachLayer(function (layer) {
//           if (layer instanceof L.Marker) {
//             //******* UpDating area and center of Polygon on EDIT *******/
//             arrayFacilities[indexToUpdate].center = editedPosition;
//           }
//         });
//         sendFacililitiesInfoToMainnView([...arrayFacilities]);
//       }
//     }
//   }

//   useEffect(() => {
//     setPointInfo([...array]);
//   }, [])//IMPORTANT we changed to this FORM THIS [array]


//   function suckMe(e) {
//     let maMarker = e.target;
//     maMarker.setIcon(new customMarker());
//     //maMarker.setLatLng(new L.LatLng(0, 0));
//   }


//   // setTimeout(() => {
//   //   setFetching(1);
//   //   doThatagain();
//   // }, 10000);

//   function doThatagain() {
//     setTimeout(() => {
//       setFetching(0);
//     }, 3000);

//   }




//   const geoJsonStyle = {
//     color: 'green', // Set the outline color of GeoJSON features
//     weight: 0,
//     fillOpacity: 0,
//   };


//   function mostCommonElement(array) {
//     // Count occurrences of each element
//     const countMap = new Map();
//     array.forEach(element => {
//       countMap.set(element, (countMap.get(element) || 0) + 1);
//     });

//     // Find the element with the highest count
//     let mostCommon;
//     let maxCount = 0;
//     countMap.forEach((count, element) => {
//       if (count > maxCount) {
//         mostCommon = element;
//         maxCount = count;
//       }
//     });

//     return mostCommon;
//   }

//   const onEachFeature = (feature, layer) => {

//     // layer.on("click",()=>{
//     //   alert(feature.properties.zone)
//     // })
//     layer.on('mousedown', () => {
//       if (isOnCreated) {
//         console.log("Mostc:", isOnCreated)
//         console.log('Namemos: ' + feature.properties.IPCC5 + '\nClimate: ' + feature.properties.zone);
//         myar.push(feature.properties.zone)
//         const mostCommon = mostCommonElement(myar)
//         isOnCreated = false
//         console.log("MOSTCOM", mostCommon, myar)
//         sendZonesToMain(mostCommon)

//       }
//     });
//     // }

//   };




//   return (
//     <div>
//       {fetching === 1 ? <div style={{ position: "absolute", width: "100%", height: "100vh", background: "red" }}></div> : <></>}
//       <div >

//         <MapContainer center={[35.417416, 24.530005]} zoom={7} scrollWheelZoom={true} ref={mapRef} style={{ position: 'relative' }}>

//           <FeatureGroup ref={featureGroupRef}>
//             {/* marker: { icon: new customMarker() } */}
//             <EditControl position="topleft" onCreated={_onCreated} onEdited={_onEdited} onDeleted={_deleted} delete={{ removeAllLayers: false }} draw={{ rectangle: false, marker: false, polyline: false, circlemarker: false }} />

//             {/* {LOCATIONS ? <div>{LOCATIONS.map((locations) => (
//               <><Circle
//                 key={locations.id}
//                 center={[locations.lat, locations.long]}
//                 pathOptions="blue"
//                 radius={locations.radius}
//                 eventHandlers={{
//                   click: (e) => {
//                     console.log(e)
//                   }
//                 }} />
//                 <Marker
//                   icon={new customMarker2()}
//                   position={[locations.lat, locations.long]}
//                   eventHandlers={{
//                     click: (e) => {
//                       suckMe(e)
//                     },
//                   }}
//                 />
//               </>
//             ))}</div> : <></>} */}


//           </FeatureGroup>

//           <TileLayer
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           />
//           <GeoJSON data={sample} style={geoJsonStyle} onEachFeature={onEachFeature} />
//           {/* {array.length > 0 && arrayFacilities.length > 0 ?<div><RoutingMachine/> <RoutingMachine2/> </div>: <div></div>} */}
//         </MapContainer>
//       </div>
//       <Helmet>
//         <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>
//         <script src="https://unpkg.com/@mui/material@latest/umd/material-ui.production.min.js"></script>
//         <script>
//           {
//             `function secPop()
//               {
//                 if ("${helpContext}"==="facilities_add")
//                   Swal.fire("Facilities information","${INFOTEXT.FACILITY_HELP}");
//                 if ("${helpContext}"==="fields_add")
//                   Swal.fire("Fields information","${INFOTEXT.FIELD_HELP}");
//               }`
//           }
//         </script>
//       </Helmet>
//     </div>
//   )

// }

// export default Mapleaf3;

/////////////// STAFFF FROM OUTSIDE ///////////////////
import { FeatureGroup, MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import { EditControl } from "react-leaflet-draw";
import SiteObject from './SiteObject';
import FacilitiesObject from "./FacilitiesObject";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import Swal from 'sweetalert2';
import L from 'leaflet';
import qm from './images/qm.jpg';
import "./scss/map.css";
import { Helmet } from "react-helmet";
import { INFOTEXT } from "./components/constatstext";
import { STYLE } from "./components/styles";
import pyroIcon from "./images/pyrolysis.png";
import sample from './noice.json';

function Mapleaf3({ createArrayOfFields, createArrayOfFacilities, createZone, gotFieldsFromCsv }) {
  const [finalArray, setFinalArray] = useState([]);
  const [arrayFacilities, setArrayFacilities] = useState([]);
  const mapRef = useRef();
  const featureGroupRef = useRef();
  const [fetching, setFetching] = useState(0);
  const [helpContext, setHelpContext] = useState("");
  const [finishedLoadingFieldsFacilities, setFinishedLoadingFieldsFacilities] = useState(false);
  const [facilityInputRequired, setFacilityInputRequired] = useState(true);
  const [iconUrl, setIconUrl] = useState('https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png');
  let fieldInputRequired = true;
  let isOnCreated = false;
  let myar = [];

  const customMarker = new L.Icon({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Factory_icon.svg/1099px-Factory_icon.svg.png',
    iconSize: [24, 24],
    iconAnchor: [5, 22],
  });

  const customMarker2 = new L.Icon({
    iconUrl: pyroIcon,
    iconSize: [24, 24],
    iconAnchor: [5, 22],
  });

  // useEffect(() => {
  //   const drawnItems = featureGroupRef.current;
  //   if (!drawnItems) return;
  //   drawnItems.clearLayers();
  //   const newArray = gotFieldsFromCsv.map(area => {
  //     const { hectares, latitude, longitude } = area;
  //     const radius = radiusFromHectares(hectares);
  //     const center = [latitude, longitude];
  //     const circle = L.circle(center, { radius });
  //     drawnItems.addLayer(circle);
  //     return new SiteObject(circle._leaflet_id, circle.getLatLng(), Math.PI * (radius * radius));
  //   });
  //   setFinalArray(prevArray => [...prevArray, ...newArray]);
  // }, [gotFieldsFromCsv]);

  useEffect(() => {
    const drawnItems = featureGroupRef.current;
    if (!drawnItems) return;

    // Get all current layers from the FeatureGroup
    const currentLayers = drawnItems.getLayers();

    // Filter out layers that were imported from CSV
    const csvLayerIds = gotFieldsFromCsv.map(area => area.id);
    const layersToRemove = currentLayers.filter(layer => csvLayerIds.includes(layer._leaflet_id));

    // Remove only the layers that were imported from CSV
    layersToRemove.forEach(layer => drawnItems.removeLayer(layer));

    // Add circles for fields from CSV
    gotFieldsFromCsv.forEach(area => {
      const { hectares, latitude, longitude } = area;
      const radius = radiusFromHectares(hectares);
      const center = [latitude, longitude];
      const circle = L.circle(center, { radius });
      drawnItems.addLayer(circle);
      // Create SiteObject and add to finalArray
      const newSite = new SiteObject(circle._leaflet_id, circle.getLatLng(), Math.PI * (radius * radius));
      setFinalArray(prevArray => [...prevArray, newSite]);
    });
  }, [gotFieldsFromCsv]);

  const sendFieldInfoToMainnView = (array) => createArrayOfFields(array);
  const sendFacililitiesInfoToMainnView = (arrayOfFacilities) => createArrayOfFacilities(arrayOfFacilities);
  const sendZonesToMain = (mostCommon) => createZone(mostCommon);

  useEffect(() => {
    sendFieldInfoToMainnView(finalArray);
  }, [finalArray]);

  useEffect(() => {
    sendFacililitiesInfoToMainnView(arrayFacilities);
  }, [arrayFacilities]);

  const _onCreated = async (e) => {
    isOnCreated = true;
    if (e.layerType === "polygon") {
      //alert("WE did poly")
      const newSite = new SiteObject(e.layer._leaflet_id, e.layer.getBounds().getCenter(), L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]));
      setFinalArray(prevArray => [...prevArray, newSite]);
    }
    if (e.layerType === "circle") {
      //alert("WE did circle")
      const newSite = new SiteObject(e.layer._leaflet_id, e.layer.getLatLng(), Math.PI * (e.layer._mRadius * e.layer._mRadius));
      setFinalArray(prevArray => [...prevArray, newSite]);
    }
    if (e.layerType === "marker") {
      const formValues = await fireTheSwalForFacilities();
      if (formValues) {
        const newFacility = new FacilitiesObject(e.layer._leaflet_id, e.layer.getLatLng(), formValues[0]);
        setArrayFacilities(prevArray => [...prevArray, newFacility]);
      }
    }
  };

  const _onEdited = (e) => {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      if (layer instanceof L.Circle) {
        const updatedSite = new SiteObject(layer._leaflet_id, layer.getLatLng(), Math.PI * (layer._mRadius * layer._mRadius));
        setFinalArray(prevArray => {
          const indexToUpdate = prevArray.findIndex(obj => obj.id === layer._leaflet_id);
          if (indexToUpdate !== -1) {
            const newArray = [...prevArray];
            newArray[indexToUpdate] = updatedSite;
            return newArray;
          }
          return prevArray;
        });
      }
      if (layer instanceof L.Polygon) {
        const updatedSite = new SiteObject(layer._leaflet_id, layer.getBounds().getCenter(), L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]));
        setFinalArray(prevArray => {
          const indexToUpdate = prevArray.findIndex(obj => obj.id === layer._leaflet_id);
          if (indexToUpdate !== -1) {
            const newArray = [...prevArray];
            newArray[indexToUpdate] = updatedSite;
            return newArray;
          }
          return prevArray;
        });
      }
    });
  };

  const _deleted = (e) => {
    const layers = e.layers;
    const idArray = Object.keys(layers._layers).map(id => parseInt(id));
    setFinalArray(prevArray => prevArray.filter(item => !idArray.includes(item.id)));
    setArrayFacilities(prevArray => prevArray.filter(item => !idArray.includes(item.id)));
  };

  const fireTheSwalForFacilities = async () => {
    setHelpContext("facilities_add");
    const { value: formValues } = await Swal.fire({
      title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Facility Information</h5>`,
      html: `${STYLE.POPUP_ADD_FACILITY}`,
      focusConfirm: true,
      confirmButtonColor: "green",
      confirmButtonText: "ADD",
      allowOutsideClick: false,
      preConfirm: () => {
        return [document.getElementById('swal-input1').value];
      }
    });
    return formValues;
  };

  const geoJsonStyle = {
    color: 'green',
    weight: 0,
    fillOpacity: 0,
  };



  function radiusFromHectares(hectares) {
    const areaInSquareMeters = hectares * 10000;
    return Math.sqrt(areaInSquareMeters / Math.PI);
  }



  ///new way to claculate zones 
  // useEffect(() => {
  //   const drawnItems = featureGroupRef.current;
  //   if (!drawnItems){console.log("COMO No draw"); return;} 

  //   const map = mapRef.current;
  //   if (!map){console.log("COMO No Map"); return;}

  //   const zones = [];

  //   // Get all current layers from the FeatureGroup
  //   // const currentLayers = drawnItems.getLayers();
  //   // console.log("COMO::", currentLayers);

  //   // // Process FeatureGroup layers
  //   // currentLayers.forEach(layer => {
  //   //   if (layer.feature && layer.feature.properties && layer.feature.properties.zone) {
  //   //     zones.push(layer.feature.properties.zone);
  //   //   }
  //   // });

  //   // Process GeoJSON layers
  //   console.log("COMO::", map);
  //   map.eachLayer(layer => {   
  //     if (layer.feature && layer.feature.properties && layer.feature.properties.zone) {
  //       console.log("PPPCOMOPP", layer.feature.properties.zone)

  //       zones.push(layer.feature.properties.zone);
  //     }
  //   });

  //   const mostCommon = mostCommonZone(zones);
  //   sendZonesToMain(mostCommon);
  // }, [gotFieldsFromCsv, mapRef, featureGroupRef, sendZonesToMain]);
  const [zoneCounts, setZoneCounts] = useState({});
  const [mostCommonZone, setMostCommonZone] = useState(13);
  // useEffect(() => {
  //   const drawnItems = featureGroupRef.current;
  //   if (!drawnItems || !mapRef.current) return;

  //   const map = mapRef.current;
  //   var zones = {};

  //   // Ensure GeoJSON layers are present
  //   map.eachLayer(layer => {
  //     if (layer.feature && layer.feature.properties && layer.feature.properties.zone) {
  //       zones[layer.feature.properties.zone] = 0;
  //     }
  //   });

  //   // Iterate through drawn items and count how many fall within each zone
  //   drawnItems.getLayers().forEach(drawLayer => {
  //     map.eachLayer(zoneLayer => {
  //       if (zoneLayer.feature && zoneLayer.feature.properties && zoneLayer.feature.properties.zone) {
  //         const zone = zoneLayer.feature.properties.zone;
  //         const zoneBounds = zoneLayer.getBounds();

  //         if (drawLayer instanceof L.Circle) {
  //           if (zoneBounds.contains(drawLayer.getLatLng())) {
  //             zones[zone] += 1;
  //           }
  //         }if (drawLayer instanceof L.Polygon) {
  //           if (zoneBounds.contains(drawLayer.getCenter())) {
  //             zones[zone] += 1;
  //           }
  //         }
  //       }
  //     });
  //   });

  //   setZoneCounts(zones);
  //   console.log("Zone counts:", zones);
  //   let maxCount = 0;
  //   let mostCommon = null;
  //   for (const [zone, count] of Object.entries(zones)) {
  //     if (count > maxCount) {
  //       maxCount = count;
  //       mostCommon = zone;
  //     }
  //   }

  //   setMostCommonZone(mostCommon);
  //   console.log("Zone counts:", zones);
  //   console.log("Most common zone:", mostCommon);

  //   sendZonesToMain(mostCommon);
  // }, [gotFieldsFromCsv, finalArray]);


  ///------------OLD way to claculate zones

  // const onEachFeature = (feature, layer) => {
  //   layer.on('mousedown', () => {
  //     if (isOnCreated) {
  //       myar.push(feature.properties.zone);
  //       const mostCommon = mostCommonElement(myar);
  //       sendZonesToMain(mostCommon);
  //       isOnCreated = false;
  //     }
  //   });
  // };
  // function mostCommonElement(array) {
  //   console.log("WE FIRE COMON")
  //   const countMap = new Map();
  //   array.forEach(element => {
  //     countMap.set(element, (countMap.get(element) || 0) + 1);
  //   });
  //   let mostCommon;
  //   let maxCount = 0;
  //   countMap.forEach((count, element) => {
  //     if (count > maxCount) {
  //       mostCommon = element;
  //       maxCount = count;
  //     }
  //   });

  //   console.log("COMON: ",mostCommon)
  //   return mostCommon;
  // }

  useEffect(() => {
    const drawnItems = featureGroupRef.current;
    if (!drawnItems || !mapRef.current) return;

    const map = mapRef.current;
    let zones = {};

    // Ensure GeoJSON layers are present and initialize zone counts
    map.eachLayer(layer => {
      if (layer.feature && layer.feature.properties && layer.feature.properties.zone) {
        zones[layer.feature.properties.zone] = 0;
      }
    });

    // Iterate through drawn items and count how many fall within each zone
    drawnItems.getLayers().forEach(drawLayer => {
      let itemCounted = false;

      map.eachLayer(zoneLayer => {
        if (zoneLayer.feature && zoneLayer.feature.properties && zoneLayer.feature.properties.zone) {
          const zone = zoneLayer.feature.properties.zone;
          const zoneBounds = zoneLayer.getBounds();

          // Check if the center of the drawn item falls within the zone bounds
          if (drawLayer instanceof L.Circle) {
            if (zoneBounds.contains(drawLayer.getLatLng()) && !itemCounted) {
              zones[zone] += 1;
              itemCounted = true;
            }
          } else if (drawLayer instanceof L.Polygon) {
            if (zoneBounds.contains(drawLayer.getCenter()) && !itemCounted) {
              zones[zone] += 1;
              itemCounted = true;
            }
          }

          // Stop checking further zones if the item is already counted
          if (itemCounted) return;
        }
      });
    });

    setZoneCounts(zones);
    //console.log("Zone counts:", zones);

    let maxCount = 0;
    let mostCommon = null;
    for (const [zone, count] of Object.entries(zones)) {
      if (count > maxCount) {
        maxCount = count;
        mostCommon = zone;
      }
    }

    setMostCommonZone(mostCommon);
    //console.log("Most common zone:", mostCommon);

    sendZonesToMain(mostCommon);
  }, [gotFieldsFromCsv, finalArray]);


  return (
    <div>
      {fetching === 1 ? <div style={{ position: "absolute", width: "100%", height: "100vh", background: "red" }}></div> : null}
      <div>
        <MapContainer center={[35.417416, 24.530005]} zoom={7} scrollWheelZoom={true} ref={mapRef} style={{ position: 'relative' }}>
          <FeatureGroup ref={featureGroupRef}>
            <EditControl position="topleft" onCreated={_onCreated} onEdited={_onEdited} onDeleted={_deleted} delete={{ removeAllLayers: false }} draw={{ rectangle: false, marker: false, polyline: false, circlemarker: false }} />
          </FeatureGroup>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON data={sample} style={geoJsonStyle} />
          {/* onEachFeature={onEachFeature} */}
        </MapContainer>
      </div>
      <Helmet>
        <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>
        <script>
          {`
            function secPop() {
                if ("${helpContext}"==="facilities_add")
                  Swal.fire("Facilities information","${INFOTEXT.FACILITY_HELP}");
                if ("${helpContext}"==="fields_add")
                  Swal.fire("Fields information","${INFOTEXT.FIELD_HELP}");
              }`
          }
        </script>
      </Helmet>
    </div>
  )

}

export default Mapleaf3;