import '../scss/sidebar_right.scss';
import Box from '@mui/material/Box';
import CardRightSide from './CardRightSide';
import CardRightSideFacilities from './CardRightSideFacilities';
import Swal from 'sweetalert2';
import qm from '../images/qm.jpg';
import flower from '../images/flower.jpg';
import { Helmet } from "react-helmet";
import { INFOTEXT } from "./constatstext";
import { useNavigate } from 'react-router-dom';
import { STYLE } from '../components/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, Fragment } from 'react';
import { kmeans } from 'ml-kmeans';
import red from '../images/redx.png';
import green from '../images/tick.png';
import swal from 'sweetalert';
import { CSVLink, CSVDownload } from "react-csv";
import Papa from "papaparse";
import * as XLSX from 'xlsx';

const allowedExtensions = ["csv", "excel"];
let locationsToSendClus = [];
let resultMatrixClus = [];
function SideBarRight({ arrayOfFields, arrayOfFacilities, passInstanceInfo, passUserInput, passBiomassInfo, passSumofArea, passMoistureMetal, passBiomassPerCont, zone, passImportedFields,passContaminationAndZoneToMain }) {
    //PROP ==> for SITES and Facilities
    //Pass an Array of Obj  PROP and populate the Boxes in A List within each category
    //console.log("WE HAVE ARRAY OF FIELDS RIGHT", arrayOfFields);
    //console.log("WE HAVE ARRAY OF FACILITIESS RIGHT", arrayOfFacilities);
    //console.log("WE have mostCOMON RIGHT:", zone)

    const [arrayForExport, setArrayForExport] = useState([]);
    const [completedRequest, setCompletedRequest] = useState("NO");
    const navigate = useNavigate();

    useEffect(() => {
        const newArrayForExport = [];

        for (let i = 0; i < arrayOfFields.length; i++) {
            const newEntry = {
                hectares: arrayOfFields[i].area / 10000,
                latitude: arrayOfFields[i].center.lat,
                longitude: arrayOfFields[i].center.lng
            };

            // Check if the newEntry already exists in newArrayForExport
            const exists = newArrayForExport.some(
                (entry) =>
                    entry.latitude === newEntry.latitude &&
                    entry.longitude === newEntry.longitude &&
                    entry.hectares === newEntry.hectares
            );

            if (!exists) {
                newArrayForExport.push(newEntry);
            }
        }

        setArrayForExport(newArrayForExport);
    }, [arrayOfFields])

    const headers = [
        { label: 'hectares', key: `hectares` },
        { label: 'latitude', key: 'latitude' },
        { label: 'longitude', key: 'longitude' },
    ];



    let locationsToSend = [];
    let resultMatrix = [];

    //***********************MATRIX CALCULATION FOR DISTANCES ********************************/
    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance;
    }



    function createDistanceMatrix(locationsToSend) {
        const distanceMatrix = [];
        for (let i = 0; i < locationsToSend.length; i++) {
            const row = [];
            for (let j = 0; j < locationsToSend.length; j++) {
                const distance = calculateDistance(
                    locationsToSend[i][0],  // lat of point i
                    locationsToSend[i][1],  // lon of point i
                    locationsToSend[j][0],  // lat of point j
                    locationsToSend[j][1]   // lon of point j
                );
                row.push(distance);
            }
            distanceMatrix.push(row);
        }

        return distanceMatrix;
    }



    //***********************MATRIX CALCULATION FOR DISTANCES ********************************/

    const [clusterAreas, setClusterAreas] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [centroids, setCentroids] = useState([]);

    useEffect(() => { locationPreprocessing() }, [arrayOfFields])
    //Calculate the locations to be send in backend
    function locationPreprocessing() {
        for (let i = 0; i < arrayOfFields.length; i++) {
            locationsToSend.push([arrayOfFields[i].center.lat, arrayOfFields[i].center.lng, (arrayOfFields[i].area * 0.0001)])
        }

        //console.log("HELLO FRIENDS", locationsToSend)
        if (locationsToSend.length > 6) {
            // console.log("TOOO MUUUCH", locationsToSend)

            // const coordinates = locationsToSend.map(([lat, long]) => [lat, long]);

            // const { centroids: finalCentroids, clusters: assignments } = kmeans(coordinates, 6);

            // const clusteredData = locationsToSend.map((point, index) => ({
            //     lat: point[0],
            //     long: point[1],
            //     area: point[2],
            //     cluster: assignments[index],
            // }));

            // setClusters(clusteredData);

            // //setCentroids(finalCentroids);
            // let myCentroids = finalCentroids;

            // const calculateClusterArea = (clusterRadius) => {
            //     // Assuming clusterRadius is in kilometers
            //     let clusterArea = Math.PI * Math.pow(clusterRadius, 2);
            //     const minAreaInHectares = 1; // Minimum reasonable area in hectares
            //     const maxAreaInHectares = 10000; // Maximum reasonable area in hectares
            //     console.log("WE CARE:", clusterArea)
            //     //let clusterAreaInHectares = clusterAreaInSquareKm * 100; // Convert to hectares
            //     //clusterArea = Math.max(minAreaInHectares, Math.min(maxAreaInHectares, clusterArea));

            //     return clusterArea ;
            // };

            // const calculateAverageRadius = (clusteredData, clusterId) => {
            //     const clusterPoints = clusteredData.filter((point) => point.cluster === clusterId);
            //     const totalRadius = clusterPoints.reduce((sum, point) => sum + point.area, 0);
            //     return clusterPoints.length > 0 ? totalRadius / clusterPoints.length : 0;
            // };
            // finalCentroids.forEach((centroid, index) => {
            //     console.log(`Center of Cluster ${index + 1}: Latitude ${centroid[0]}, Longitude ${centroid[1]}`);
            // });
            // locationsToSend = [...finalCentroids]
            // //console.log("Too much?",locationsToSend)
            // let clusterAreas = myCentroids.map((_, clusterId) => {
            //     const averageRadius = calculateAverageRadius(clusteredData, clusterId);
            //     //console.log(averageRadius)
            //     return calculateClusterArea(averageRadius);
            // });
            // setClusterAreas(clusterAreas);
            // let cluseterAreaCommon = [...clusterAreas]
            // console.log("Clustered areas common WE CARE", cluseterAreaCommon)
            // for (let i = 0; i < locationsToSend.length; i++) {
            //     locationsToSend[i].push(cluseterAreaCommon[i])
            // }
            // //resultMatrix = createDistanceMatrix(locationsToSend);
            //console.log("TOOO MUUUCH", locationsToSend);

            const coordinates = locationsToSend.map(([lat, long]) => [lat, long]);

            const { centroids: finalCentroids, clusters: assignments } = kmeans(coordinates, 6);

            const clusteredData = locationsToSend.map((point, index) => ({
                lat: point[0],
                long: point[1],
                area: point[2],
                cluster: assignments[index],
            }));

            setClusters(clusteredData);

            let myCentroids = finalCentroids;

            // Calculate the total area of fields within each cluster
            const calculateClusterArea = (clusteredData, clusterId) => {
                const clusterPoints = clusteredData.filter((point) => point.cluster === clusterId);
                const totalArea = clusterPoints.reduce((sum, point) => sum + point.area, 0);
                return totalArea;
            };

            finalCentroids.forEach((centroid, index) => {
                console.log(`Center of Cluster ${index + 1}: Latitude ${centroid[0]}, Longitude ${centroid[1]}`);
            });

            locationsToSend = [...finalCentroids];
            let clusterAreas = myCentroids.map((_, clusterId) => {
                return calculateClusterArea(clusteredData, clusterId);
            });

            setClusterAreas(clusterAreas);

            for (let i = 0; i < locationsToSend.length; i++) {
                locationsToSend[i].push(clusterAreas[i]);
            }

        }
        resultMatrix = createDistanceMatrix(locationsToSend);
        //console.log("Resulting Matrix", resultMatrix); //<==resulting Matrix holding the distance between points
        //console.log("Final locations ", locationsToSend);

        locationsToSendClus = locationsToSend;
        resultMatrixClus = resultMatrix;


    }







    //console.log("Resulting Matrix Clus", resultMatrixClus); //<==resulting Matrix holding the distance between points
    //console.log("Final locations Clus", locationsToSendClus);
    var browser = myFunction()
    var myRenderBrowser;
    if (browser === "Chrome") {
        myRenderBrowser = STYLE.POP_UP_LAND_CHARACTERIZATION_CHROME
    }
    else {
        myRenderBrowser = STYLE.POP_UP_LAND_CHARACTERIZATION
    }

    function zoneC() {
        var sumLat = 0;
        locationsToSend.forEach(coord => {
            sumLat += coord[0];
        });

        // Calculate the average latitude and longitude
        const avgLat = sumLat / locationsToSend.length;

        const nottinghamLatitude = 52.9548;
        console.log(avgLat)
        if (avgLat > nottinghamLatitude) {
            return 4
        }
        else {
            return 7
        }
    }


    async function swalPopUp_BiomassYield() {
        const { value: formValues } = await Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Soil Contamination and Characteristics<Tooltip title='${INFOTEXT.FIELD_HELP}'><img src=${qm} style="width:30px; float:right; cursor:pointer"  /></Tooltip></h5>`,//field info to become a prop
            html:
                `${myRenderBrowser}`,//to become a prop
            focusConfirm: true,
            width: 800,
            showCancelButton: true,
            cancelButtonColor: "red",
            confirmButtonColor: "green",
            confirmButtonText: "Submit",//to become a prop
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                var biomasArray = [
                    document.getElementById('swal-input0').value,
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value,
                    document.getElementById('swal-input3').value,
                    document.getElementById('swal-input4').value,
                    document.getElementById('swal-input5').value,
                    document.getElementById('swal-input6').value,
                    document.getElementById('swal-input7').value,
                    document.getElementById('swal-input8').value,
                    document.getElementById('swal-input9').value,
                ]


                //alert(biomasArray)
                if (biomasArray[9] === '') {

                    Swal.showValidationMessage('<i class="fa fa-info-circle"></i> <strong>Soil Texture</strong>&nbsp;input is required')

                }
                else {
                    for (let i = 0; i < biomasArray.length; i++) {
                        if (biomasArray[i] === "" || biomasArray[i] === null) {
                            biomasArray[i] = 0
                        }
                        if (!parseFloat(biomasArray[i])) {
                            console.log("WE DIND PARSE", biomasArray[i])
                        }
                    }
                    if (zone == undefined || zone.length == 0) {
                        zone = 13
                    }

                    //**************************Fixing the Data to be send **************************/
                    let dataForBiomassML = {
                        "As": parseFloat(biomasArray[0]),
                        "cd": parseFloat(biomasArray[1]),
                        "cr": parseFloat(biomasArray[2]),
                        "cu": parseFloat(biomasArray[3]),
                        "hg": parseFloat(biomasArray[4]),
                        "ni": parseFloat(biomasArray[5]),
                        "pb": parseFloat(biomasArray[6]),
                        "zn": parseFloat(biomasArray[7]),
                        "climatic_zone_C": parseInt(zone),
                        "org_matter_content": parseFloat(biomasArray[8]) / 100,
                        "pH": parseFloat(biomasArray[9]),
                    }
                    //createMLCall(formValues[0],formValues[1],formValues[2],formValues[3],formValues[4],formValues[5],formValues[6],formValues[7],formValues[8],formValues[9],formValues[10],formValues[11])
                    console.log(dataForBiomassML)

                    return fetch(`/api/predict`, {//http://167.235.129.196:5050/predict   /api/predict
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(dataForBiomassML)
                    })//.then(response =>  console.log(response.ok)).then(data=>console.log(data)).catch(console.error);
                        .then(response => {
                            console.log(response)
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            }
                            return response.json()
                        }).then((result) => {
                            //console.log("BIOMASS", result);
                            passContaminationAndZoneToMain(dataForBiomassML);
                            ResultsPopUp_BiomassYield(result);
                        }).catch(error => {
                            alert(error)
                        })
                }

                //swalPopUp_OptimizationINput();
            }
        })



    }





    async function swalPopUp_Manual() {
        biomass = {};
        per_contaminant = {};
        moisture_metal = {};
        var summan_willow = 0;
        var summan_misc = 0;
        var summan_canary = 0;
        var summan_napier = 0;
        var summan_sugar = 0;
        var summan_energy = 0;
        var summan_giant = 0;
        var summan_switch = 0;

        let weMovetoNextStep = "NO";
        let weHaveValue = "NO";

        const { value: formValues,isConfirmed, isDismissed } = await Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Biomass and Soil Characteristics<Tooltip title='${INFOTEXT.FIELD_HELP}'><img src=${qm} style="width:30px; float:right; cursor:pointer"  /></Tooltip></h5>`,//field info to become a prop
            html: STYLE.POP_UP_MANUAL_2,//to become a prop
            focusConfirm: true,
            width: 1200,
            confirmButtonColor: "green",
            confirmButtonText: "Submit",//to become a prop
            allowOutsideClick: true,
            showLoaderOnConfirm: true,
            showCancelButton: true,
            cancelButtonColor: "red",
            cancelButtonText: "Cancel",
            preConfirm: () => {
                var biomasArrayManual = [
                    document.getElementById('swal-type-Willow').checked,//0==
                    document.getElementById('swal-type-Willow').value,//1==
                    document.getElementById('swal-biomass-yield-man').value,//2
                    document.getElementById('swal-arsenic-man').value,//3
                    document.getElementById('swal-cadmium-man').value,//4
                    document.getElementById('swal-chrom-man').value,//5
                    document.getElementById('swal-copper-man').value,//6
                    document.getElementById('swal-mer-man').value,//7
                    document.getElementById('swal-lead-man').value,//8
                    document.getElementById('swal-nickel-man').value,//9
                    document.getElementById('swal-zinc-man').value,//10
                    document.getElementById('swal-type-Miscanthus').checked,//11==
                    document.getElementById('swal-type-Miscanthus').value,//12==
                    document.getElementById('swal-biomass-yield-man-Miscanthus').value,//13
                    document.getElementById('swal-arsenic-man-Miscanthus').value,//14
                    document.getElementById('swal-cadmium-man-Miscanthus').value,//15
                    document.getElementById('swal-chrom-man-Miscanthus').value,//16
                    document.getElementById('swal-copper-man-Miscanthus').value,//17
                    document.getElementById('swal-mer-man-Miscanthus').value,//18
                    document.getElementById('swal-lead-man-Miscanthus').value,//19
                    document.getElementById('swal-nickel-man-Miscanthus').value,//20
                    document.getElementById('swal-zinc-man-Miscanthus').value,//21
                    document.getElementById('swal-type-Canary').checked,//22====
                    document.getElementById('swal-type-Canary').value,//23====
                    document.getElementById('swal-biomass-yield-man-Canary').value,//24
                    document.getElementById('swal-arsenic-man-Canary').value,//25
                    document.getElementById('swal-cadmium-man-Canary').value,//26
                    document.getElementById('swal-chrom-man-Canary').value,//27
                    document.getElementById('swal-copper-man-Canary').value,//28
                    document.getElementById('swal-mer-man-Canary').value,//29
                    document.getElementById('swal-lead-man-Canary').value,//30
                    document.getElementById('swal-nickel-man-Canary').value,//31
                    document.getElementById('swal-zinc-man-Canary').value,//32
                    document.getElementById('swal-type-Napier').checked,//33==
                    document.getElementById('swal-type-Napier').value,//34==
                    document.getElementById('swal-biomass-yield-man-Napier').value,//35
                    document.getElementById('swal-arsenic-man-Napier').value,//36
                    document.getElementById('swal-cadmium-man-Napier').value,//37
                    document.getElementById('swal-chrom-man-Napier').value,//38
                    document.getElementById('swal-copper-man-Napier').value,//39
                    document.getElementById('swal-mer-man-Napier').value,//40
                    document.getElementById('swal-lead-man-Napier').value,//41
                    document.getElementById('swal-nickel-man-Napier').value,//42
                    document.getElementById('swal-zinc-man-Napier').value,//43
                    document.getElementById('swal-type-Sugar').checked,//44==
                    document.getElementById('swal-type-Sugar').value,//45==
                    document.getElementById('swal-biomass-yield-man-Sugar').value,//46
                    document.getElementById('swal-arsenic-man-Sugar').value,//47
                    document.getElementById('swal-cadmium-man-Sugar').value,//48
                    document.getElementById('swal-chrom-man-Sugar').value,//49
                    document.getElementById('swal-copper-man-Sugar').value,//50
                    document.getElementById('swal-mer-man-Sugar').value,//51
                    document.getElementById('swal-lead-man-Sugar').value,//52
                    document.getElementById('swal-nickel-man-Sugar').value,//53
                    document.getElementById('swal-zinc-man-Sugar').value,//54
                    document.getElementById('swal-type-Energy').checked,//55==
                    document.getElementById('swal-type-Energy').value,//56==
                    document.getElementById('swal-biomass-yield-man-Energy').value,//57
                    document.getElementById('swal-arsenic-man-Energy').value,//58
                    document.getElementById('swal-cadmium-man-Energy').value,//59
                    document.getElementById('swal-chrom-man-Energy').value,//60
                    document.getElementById('swal-copper-man-Energy').value,//61
                    document.getElementById('swal-mer-man-Energy').value,//62
                    document.getElementById('swal-lead-man-Energy').value,//63
                    document.getElementById('swal-nickel-man-Energy').value,//64
                    document.getElementById('swal-zinc-man-Energy').value,//65
                    document.getElementById('swal-type-Giant').checked,//66==
                    document.getElementById('swal-type-Giant').value,//67==
                    document.getElementById('swal-biomass-yield-man-Giant').value,//68
                    document.getElementById('swal-arsenic-man-Giant').value,//69
                    document.getElementById('swal-cadmium-man-Giant').value,//70
                    document.getElementById('swal-chrom-man-Giant').value,//71
                    document.getElementById('swal-copper-man-Giant').value,//72
                    document.getElementById('swal-mer-man-Giant').value,//73
                    document.getElementById('swal-lead-man-Giant').value,//74
                    document.getElementById('swal-nickel-man-Giant').value,//75
                    document.getElementById('swal-zinc-man-Giant').value,//76
                    document.getElementById('swal-type-Switchgrass').checked,//77==
                    document.getElementById('swal-type-Switchgrass').value,//78==
                    document.getElementById('swal-biomass-yield-man-Switchgrass').value,//79
                    document.getElementById('swal-arsenic-man-Switchgrass').value,//80
                    document.getElementById('swal-cadmium-man-Switchgrass').value,//81
                    document.getElementById('swal-chrom-man-Switchgrass').value,//82
                    document.getElementById('swal-copper-man-Switchgrass').value,//83
                    document.getElementById('swal-mer-man-Switchgrass').value,//84
                    document.getElementById('swal-lead-man-Switchgrass').value,//85
                    document.getElementById('swal-nickel-man-Switchgrass').value,//86
                    document.getElementById('swal-zinc-man-Switchgrass').value,//87
                ]

                //**************************Fixing the Data to be send **************************/




                for (let i = 3; i < 11; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_willow = summan_willow + parseFloat(biomasArrayManual[i])
                }
                for (let i = 14; i < 22; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_misc = summan_misc + parseFloat(biomasArrayManual[i])
                }
                for (let i = 25; i < 33; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_canary = summan_canary + parseFloat(biomasArrayManual[i])
                }
                for (let i = 36; i < 44; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_napier = summan_napier + parseFloat(biomasArrayManual[i])
                }
                for (let i = 47; i < 55; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_sugar = summan_sugar + parseFloat(biomasArrayManual[i])
                }
                for (let i = 58; i < 66; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_energy = summan_energy + parseFloat(biomasArrayManual[i])
                }
                for (let i = 69; i < 77; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_giant = summan_giant + parseFloat(biomasArrayManual[i])
                }
                for (let i = 80; i < 88; i++) {
                    if (isNaN(parseFloat(biomasArrayManual[i]))) {
                        biomasArrayManual[i] = 0;
                    }
                    summan_switch = summan_switch + parseFloat(biomasArrayManual[i])
                }


                if (biomasArrayManual[0]) {
                    weMovetoNextStep = "OK"
                    if (isNaN(parseFloat(biomasArrayManual[2])) || parseFloat(biomasArrayManual[2]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[2]=1;
                    }
                    biomass[biomasArrayManual[1]] = (biomasArrayManual[2]) * 0.001;
                    per_contaminant[biomasArrayManual[1]] = [parseFloat(biomasArrayManual[3]), parseFloat(biomasArrayManual[4]), parseFloat(biomasArrayManual[5]), parseFloat(biomasArrayManual[6]), parseFloat(biomasArrayManual[7]), parseFloat(biomasArrayManual[8]), parseFloat(biomasArrayManual[9]), parseFloat(biomasArrayManual[10])]
                    moisture_metal[biomasArrayManual[1]] = [(0), (summan_willow)];
                }
                if (biomasArrayManual[11]) {
                    weMovetoNextStep = "OK"
                    if (isNaN(parseFloat(biomasArrayManual[13])) || parseFloat(biomasArrayManual[13]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[13]=1;
                    }
                    biomass[biomasArrayManual[12]] = (biomasArrayManual[13]) * 0.001;
                    per_contaminant[biomasArrayManual[12]] = [parseFloat(biomasArrayManual[14]), parseFloat(biomasArrayManual[15]), parseFloat(biomasArrayManual[16]), parseFloat(biomasArrayManual[17]), parseFloat(biomasArrayManual[18]), parseFloat(biomasArrayManual[19]), parseFloat(biomasArrayManual[20]), parseFloat(biomasArrayManual[21]),]
                    moisture_metal[biomasArrayManual[12]] = [(0), (summan_misc)];
                }
                if (biomasArrayManual[22]) {
                    weMovetoNextStep = "OK"
                    if (isNaN(parseFloat(biomasArrayManual[24])) || parseFloat(biomasArrayManual[24]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[24]=1;
                    }
                    biomass[biomasArrayManual[23]] = (biomasArrayManual[24]) * 0.001;
                    per_contaminant[biomasArrayManual[23]] = [parseFloat(biomasArrayManual[25]), parseFloat(biomasArrayManual[26]), parseFloat(biomasArrayManual[27]), parseFloat(biomasArrayManual[28]), parseFloat(biomasArrayManual[29]), parseFloat(biomasArrayManual[30]), parseFloat(biomasArrayManual[31]), parseFloat(biomasArrayManual[32]),]
                    moisture_metal[biomasArrayManual[23]] = [(0), (summan_canary)];
                }
                if (biomasArrayManual[33]) {
                    weMovetoNextStep = "OK"
                    if (isNaN(parseFloat(biomasArrayManual[35])) || parseFloat(biomasArrayManual[35]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[35]=1;
                    }
                    biomass[biomasArrayManual[34]] = (biomasArrayManual[35]) * 0.001;
                    per_contaminant[biomasArrayManual[34]] = [parseFloat(biomasArrayManual[36]), parseFloat(biomasArrayManual[37]), parseFloat(biomasArrayManual[38]), parseFloat(biomasArrayManual[39]), parseFloat(biomasArrayManual[40]), parseFloat(biomasArrayManual[41]), parseFloat(biomasArrayManual[42]), parseFloat(biomasArrayManual[43]),]
                    moisture_metal[biomasArrayManual[34]] = [(0), (summan_napier)];
                }
                if (biomasArrayManual[44]) {
                    weMovetoNextStep = "OK"

                    if (isNaN(parseFloat(biomasArrayManual[46])) || parseFloat(biomasArrayManual[46]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[46]=1;
                    }
                    biomass[biomasArrayManual[45]] = (biomasArrayManual[46]) * 0.001;
                    per_contaminant[biomasArrayManual[45]] = [parseFloat(biomasArrayManual[47]), parseFloat(biomasArrayManual[48]), parseFloat(biomasArrayManual[49]), parseFloat(biomasArrayManual[50]), parseFloat(biomasArrayManual[51]), parseFloat(biomasArrayManual[52]), parseFloat(biomasArrayManual[53]), parseFloat(biomasArrayManual[54]),]
                    moisture_metal[biomasArrayManual[45]] = [(0), (summan_sugar)];
                }
                if (biomasArrayManual[55]) {
                    weMovetoNextStep = "OK"

                    if (isNaN(parseFloat(biomasArrayManual[57])) || parseFloat(biomasArrayManual[57]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[57]=1;
                    }
                    biomass[biomasArrayManual[56]] = (biomasArrayManual[57]) * 0.001;
                    per_contaminant[biomasArrayManual[56]] = [parseFloat(biomasArrayManual[58]), parseFloat(biomasArrayManual[59]), parseFloat(biomasArrayManual[60]), parseFloat(biomasArrayManual[61]), parseFloat(biomasArrayManual[62]), parseFloat(biomasArrayManual[63]), parseFloat(biomasArrayManual[64]), parseFloat(biomasArrayManual[65]),]
                    moisture_metal[biomasArrayManual[56]] = [(0), (summan_energy)];
                }
                if (biomasArrayManual[66]) {
                    weMovetoNextStep = "OK"

                    if (isNaN(parseFloat(biomasArrayManual[68])) || parseFloat(biomasArrayManual[68]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[68]=1;
                    }
                    biomass[biomasArrayManual[67]] = (biomasArrayManual[68]) * 0.001;
                    per_contaminant[biomasArrayManual[67]] = [parseFloat(biomasArrayManual[69]), parseFloat(biomasArrayManual[70]), parseFloat(biomasArrayManual[71]), parseFloat(biomasArrayManual[72]), parseFloat(biomasArrayManual[73]), parseFloat(biomasArrayManual[74]), parseFloat(biomasArrayManual[75]), parseFloat(biomasArrayManual[76]),]
                    moisture_metal[biomasArrayManual[67]] = [(0), (summan_giant)];
                }
                if (biomasArrayManual[77]) {
                    weMovetoNextStep = "OK"

                    if (isNaN(parseFloat(biomasArrayManual[79])) || parseFloat(biomasArrayManual[79]) <= 0) {
                        //alert("Setting the yield to one (1)")
                        weHaveValue = "NaNVal"
                        //biomasArrayManual[79]=1;
                    }
                    biomass[biomasArrayManual[78]] = (biomasArrayManual[79]) * 0.001;
                    per_contaminant[biomasArrayManual[78]] = [parseFloat(biomasArrayManual[80]), parseFloat(biomasArrayManual[81]), parseFloat(biomasArrayManual[82]), parseFloat(biomasArrayManual[83]), parseFloat(biomasArrayManual[84]), parseFloat(biomasArrayManual[85]), parseFloat(biomasArrayManual[86]), parseFloat(biomasArrayManual[87]),]
                    moisture_metal[biomasArrayManual[78]] = [(0), (summan_switch)];
                }
            },
            didDismiss: (result) => {
                if (result.dismiss === Swal.DismissReason.cancel) {
                    // Action when Cancel button is clicked
                    console.log('User clicked Cancel');
                }
            }


        })

        if (isConfirmed)
        {
            if (weMovetoNextStep !== "OK") {
                Swal.fire({ icon: "error", title: "Choose biomass", text: "You need to choose at least one biomas" })
            }
            if (weHaveValue !== "NO") {
                Swal.fire({ icon: "error", title: "Add biomass yield", text: "You need to provide a biomass yield larger than zero (0)." })
            }
            if (weHaveValue === "NO" & weMovetoNextStep === "OK") {
                console.log("Friends biomas", biomass, biomass["Reed canary grass"])
                //console.log("Friends moisture_metal", moisture_metal)
               console.log("Friends:", per_contaminant)
               passContaminationAndZoneToMain(undefined)
                swalPopUp_OptimizationINput();
            }
        }
        if (isDismissed) {
            Swal.fire({ icon: "info", title: "Returning to map", text: 'You can press "Next" and follow he steps again' })
        }
        





    }
    //**************************SWAL POP_UP FOR BIOMASS YIELD RESULTS ************************/
    //declare biomass to be send
    let biomass = {};
    let per_contaminant = {};
    let moisture_metal = {};
    function myFunction() {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf("Edg") != -1) {
            return 'Edge';
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return 'Firefox';
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
        {
            return 'IE';
        } else {
            return 'unknown';
        }
    }
    async function ResultsPopUp_BiomassYield(result) {
        biomass = {};
        per_contaminant = {};
        moisture_metal = {};
        //console.log("Biomass Results:", result)
        let plantArray = []
        for (let i = 0; i < result.biomass_yield.length; i++) {
            //console.log(result.predictions_per_contaminant.out_As[i])
            switch (i) {
                case 0:
                    plantArray.push(['Reed canary grass', result.biomass_yield[0][1], result.moisture_perc_at_harvest[0][1], result.sum_heavy_metal[0][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 1:
                    plantArray.push(['Miscanthus', result.biomass_yield[1][1], result.moisture_perc_at_harvest[1][1], result.sum_heavy_metal[1][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 2:
                    plantArray.push(['SRC Willow', result.biomass_yield[2][1], result.moisture_perc_at_harvest[2][1], result.sum_heavy_metal[2][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 3:
                    plantArray.push(['Capiacu/Napier Grass', result.biomass_yield[3][1], result.moisture_perc_at_harvest[3][1], result.sum_heavy_metal[3][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 4:
                    plantArray.push(['Sugar Cane', result.biomass_yield[4][1], result.moisture_perc_at_harvest[4][1], result.sum_heavy_metal[4][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 5:
                    plantArray.push(['Energy Cane', result.biomass_yield[5][1], result.moisture_perc_at_harvest[5][1], result.sum_heavy_metal[5][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 6:
                    plantArray.push(['Giant Reed', result.biomass_yield[6][1], result.moisture_perc_at_harvest[6][1], result.sum_heavy_metal[6][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                case 7:
                    plantArray.push(['Switchgrass', result.biomass_yield[7][1], result.moisture_perc_at_harvest[7][1], result.sum_heavy_metal[7][1], result.predictions_per_contaminant.out_As[i][1], result.predictions_per_contaminant.out_Cd[i][1], result.predictions_per_contaminant.out_Cr[i][1], result.predictions_per_contaminant.out_Cu[i][1], result.predictions_per_contaminant.out_Hg[i][1], result.predictions_per_contaminant.out_Ni[i][1], result.predictions_per_contaminant.out_Pb[i][1], result.predictions_per_contaminant.out_Zn[i][1]])
                    break;
                // case 8:
                //     plantArray.push(['Arachis hypogaea L.', result.biomass_yield[8][1], result.moisture_perc_at_harvest[8][1], result.sum_heavy_metal[8][1]])
                //     break;
                // case 9:
                //     plantArray.push(['Brasica campestris L.', result.biomass_yield[9][1], result.moisture_perc_at_harvest[9][1], result.sum_heavy_metal[9][1]])
                //     break;
                // case 10:
                //     plantArray.push(['Cardoon', result.biomass_yield[10][1], result.moisture_perc_at_harvest[10][1], result.sum_heavy_metal[10][1]])
                //     break;
                // case 11:
                //     plantArray.push(['Sweet Shogum', result.biomass_yield[11][1], result.moisture_perc_at_harvest[11][1], result.sum_heavy_metal[11][1]])
                //     break;
                // case 12:
                //     plantArray.push(['Zea mays (26)', result.biomass_yield[12][1], result.moisture_perc_at_harvest[12][1], result.sum_heavy_metal[12][1]])
                //     break;
                // case 13:
                //     plantArray.push(['B. vulgaris', result.biomass_yield[13][1], result.moisture_perc_at_harvest[13][1], result.sum_heavy_metal[13][1]])
                //     break;
                // case 14:
                //     plantArray.push(['M. sylvestris', result.biomass_yield[14][1], result.moisture_perc_at_harvest[14][1], result.sum_heavy_metal[14][1]])
                //     break;
                // case 15:
                //     plantArray.push(['P. tanacetifolia', result.biomass_yield[15][1], result.moisture_perc_at_harvest[15][1], result.sum_heavy_metal[15][1]])
                //     break;
                // case 16:
                //     plantArray.push(['L. usitatissimum', result.biomass_yield[16][1], result.moisture_perc_at_harvest[16][1], result.sum_heavy_metal[16][1]])
                //     break;
                // case 17:
                //     plantArray.push(['S. bicolor Santa Fe red', result.biomass_yield[17][1], result.moisture_perc_at_harvest[17][1], result.sum_heavy_metal[17][1]])
                //     break;
                // case 18:
                //     plantArray.push(['S. bicolor S. biomass 133', result.biomass_yield[18][1], result.moisture_perc_at_harvest[18][1], result.sum_heavy_metal[18][1]])
                //     break;
                // case 19:
                //     plantArray.push(['Tobacco', result.biomass_yield[19][1], result.moisture_perc_at_harvest[19][1], result.sum_heavy_metal[19][1]])
                //     break;
                // case 20:
                //     plantArray.push(['Sunflower', result.biomass_yield[20][1], result.moisture_perc_at_harvest[20][1], result.sum_heavy_metal[20][1]])
                //     break;
                // case 21:
                //     plantArray.push(['Poplar', result.biomass_yield[21][1], result.moisture_perc_at_harvest[21][1], result.sum_heavy_metal[21][1]])
                //     break;
                // case 22:
                //     plantArray.push(['Populus x canadensis Monch', result.biomass_yield[22][1], result.moisture_perc_at_harvest[22][1], result.sum_heavy_metal[22][1]])
                //     break;
                // case 23:
                //     plantArray.push(['P. deltoides March', result.biomass_yield[23][1], result.moisture_perc_at_harvest[23][1], result.sum_heavy_metal[23][1]])
                //     break;
                // case 24:
                //     plantArray.push(['P. alba L.', result.biomass_yield[24][1], result.moisture_perc_at_harvest[24][1], result.sum_heavy_metal[24][1]])
                //     break;
                // case 25:
                //     plantArray.push(['Robinia pseudo-accacia', result.biomass_yield[25][1], result.moisture_perc_at_harvest[25][1], result.sum_heavy_metal[25][1]])
                //     break;
                // case 26:
                //     plantArray.push(['Panicum virgatum', result.biomass_yield[26][1], result.moisture_perc_at_harvest[26][1], result.sum_heavy_metal[26][1]])
                //     break;
                // case 27:
                //     plantArray.push(['Spartina pectinate', result.biomass_yield[27][1], result.moisture_perc_at_harvest[27][1], result.sum_heavy_metal[27][1]])
                // // case 28:
                // //     plantArray.push(['Sida hermaphrodita L. Rusby',result.prediction[28][1]])
                default:
                    plantArray.push(['Unknown Plant', 0])
            }
        }

        //console.log(plantArray)
        for (let x = 0; x < plantArray.length; x++) {
            if (plantArray[x][1] > 0) {
                plantArray[x].push(green)
                plantArray[x].push('visible')
            }
            if (plantArray[x][1] <= 0) {
                plantArray[x].push(red)
                plantArray[x].push('hidden')
            }
        }
        //console.log(plantArray)

        const { value: formValues } = await Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Selection of biomass type<Tooltip title='${INFOTEXT.BIOMASS_SELECT}'><img src=${qm} style="width:30px; float:right; cursor:pointer"  /></Tooltip></h5>`,//field info to become a prop
            html:
                `<div style="display:flex; flex-direction:column; height:480px;overflow:auto;">
            <div style="margin:2px; display:flex; align-items:center; color:white; border-radius:3px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between; background-color:rgba(3, 79, 4, 0.5);">
                <div style="margin:3px; font-size:20px; font-weight:600;">Suitability</div>  
                <div style="margin:3px; font-size:20px; font-weight:600;">Biomass Type</div>
                <div style="margin:3px; font-size:20px; font-weight:600;">Estimated Yield</div>
            </div>
          <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[0][12]} style="width:20px; margin:4px;"/>  
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[0][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[0][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[0][13]};" type="checkbox" value="0" id="swal-input0" style="margin:5px;"/>
          </div>
          
          <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[1][12]} style="width:20px; margin:4px;"/>  
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[1][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[1][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[1][13]};" type="checkbox" value="1" id="swal-input1" style="margin:5px;"/>
          </div>
          
          <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[2][12]} style="width:20px; margin:4px;"/>  
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[2][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[2][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[2][13]};" type="checkbox" value="2" id="swal-input2" style="margin:5px;"/>
          </div>

            <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[3][12]} style="width:20px; margin:4px;"/>
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[3][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[3][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[3][13]};" type="checkbox" value="3" id="swal-input3" style="margin:5px;"/>
            </div>

            <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[4][12]} style="width:20px; margin:4px;"/>  
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[4][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[4][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[4][13]};" type="checkbox" value="4" id="swal-input4" style="margin:5px;"/>
            </div>

            <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[5][12]} style="width:20px; margin:4px;"/>
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[5][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[5][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[5][13]};" type="checkbox" value="5" id="swal-input5" style="margin:5px;"/>
            </div>

            <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            <img src=${plantArray[6][12]} style="width:20px; margin:4px;"/>
            <img src=${flower} style="width:40px; margin:4px;"/>
            <div style="margin:3px; font-size:15px;">${plantArray[6][0]}</div>
            <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[6][1].toFixed(2)} (kg/ha)</div>
            <input style="visibility: ${plantArray[6][13]};" type="checkbox" value="6" id="swal-input6" style="margin:5px;"/>
            </div>
                <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
                <img src=${plantArray[7][12]} style="width:20px; margin:4px;"/>  
                <img src=${flower} style="width:40px; margin:4px;"/>
                <div style="margin:3px; font-size:15px;">${plantArray[7][0]}</div>
                <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[7][1].toFixed(2)} (kg/ha)</div>
                <input style="visibility: ${plantArray[7][13]};" type="checkbox" value="7" id="swal-input7" style="margin:5px;"/>
            </div>`

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[8][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[8][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[8][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[8][5]};" type="checkbox" value="8" id="swal-input8" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[9][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[9][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[9][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[9][5]};" type="checkbox" value="9" id="swal-input9" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[10][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[10][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[10][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[10][5]};" type="checkbox" value="10" id="swal-input10" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[11][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[11][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[11][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[11][5]};" type="checkbox" value="11" id="swal-input11" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[12][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[12][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[12][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[12][5]};" type="checkbox" value="12" id="swal-input12" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[13][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[13][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[13][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[13][5]};" type="checkbox" value="13" id="swal-input13" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[14][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[14][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[14][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[14][5]};" type="checkbox" value="14" id="swal-input14" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[15][4]} style="width:20px; margin:4px;"/>  
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[15][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[15][1].toFixed(2)} (kg/ha) </div>
            //   <input style="visibility: ${plantArray[15][5]};" type="checkbox" value="15" id="swal-input15" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[16][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[16][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[16][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[16][5]};" type="checkbox" value="16" id="swal-input16" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[17][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[17][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[17][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[17][5]};" type="checkbox" value="17" id="swal-input17" style="margin:5px;"/>
            // </div>

            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[18][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[18][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[18][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[18][5]};" type="checkbox" value="18" id="swal-input18" style="margin:5px;"/>
            // </div>
            // <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[19][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[19][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[19][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[19][5]};" type="checkbox" value="19" id="swal-input19" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[20][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[20][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[20][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[20][5]};" type="checkbox" value="20" id="swal-input20" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[21][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[21][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[21][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[21][5]};" type="checkbox" value="21" id="swal-input21" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[22][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[22][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[22][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[22][5]};" type="checkbox" value="22" id="swal-input22" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[23][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[23][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[23][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[23][5]};" type="checkbox" value="23" id="swal-input23" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[24][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[24][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[24][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[24][5]};" type="checkbox" value="24" id="swal-input24" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[25][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[25][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[25][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[25][5]};" type="checkbox" value="25" id="swal-input25" style="margin:5px;"/>
            //   </div>

            //   <div style="margin:2px; display:flex; align-items:center; border-radius:5px; border: 1px solid rgba(0,0,0,0.3); justify-content:space-between;">
            //   <img src=${plantArray[26][4]} style="width:20px; margin:4px;"/>
            //   <img src=${flower} style="width:40px; margin:4px;"/>
            //   <div style="margin:3px; font-size:15px;">${plantArray[26][0]}</div>
            //   <div style="margin:3px; padding:5px;  font-size:15px;">${plantArray[26][1].toFixed(2)} (kg/ha)</div>
            //   <input style="visibility: ${plantArray[26][5]};" type="checkbox" value="26" id="swal-input26" style="margin:5px;"/>
            //   </div>
            //     </div>`

            ,
            //to become a prop
            focusConfirm: true,
            confirmButtonColor: "green",
            confirmButtonText: "ADD",//to become a prop
            allowOutsideClick: false,
            preConfirm: () => {
                var chosenBiomas = [
                    document.getElementById('swal-input0').checked,
                    document.getElementById('swal-input1').checked,
                    document.getElementById('swal-input2').checked,
                    document.getElementById('swal-input3').checked,
                    document.getElementById('swal-input4').checked,
                    document.getElementById('swal-input5').checked,
                    document.getElementById('swal-input6').checked,
                    document.getElementById('swal-input7').checked,
                    // document.getElementById('swal-input8').checked,
                    // document.getElementById('swal-input9').checked,
                    // document.getElementById('swal-input10').checked,
                    // document.getElementById('swal-input11').checked,
                    // document.getElementById('swal-input12').checked,
                    // document.getElementById('swal-input13').checked,
                    // document.getElementById('swal-input14').checked,
                    // document.getElementById('swal-input15').checked,
                    // document.getElementById('swal-input16').checked,
                    // document.getElementById('swal-input17').checked,
                    // document.getElementById('swal-input18').checked,
                    // document.getElementById('swal-input19').checked,
                    // document.getElementById('swal-input20').checked,
                    // document.getElementById('swal-input21').checked,
                    // document.getElementById('swal-input22').checked,
                    // document.getElementById('swal-input23').checked,
                    // document.getElementById('swal-input24').checked,
                    // document.getElementById('swal-input25').checked,
                    // document.getElementById('swal-input26').checked,
                ]

                console.log(chosenBiomas)

                for (let i = 0; i < chosenBiomas.length; i++) {

                    if (chosenBiomas[i]) {
                        biomass[plantArray[i][0]] = (plantArray[i][1]) * 0.001;
                    }
                }
                for (let i = 0; i < chosenBiomas.length; i++) {

                    if (chosenBiomas[i]) {
                        moisture_metal[plantArray[i][0]] = [(plantArray[i][2]), (plantArray[i][3])];
                        per_contaminant[plantArray[i][0]] = [plantArray[i][4], plantArray[i][5], plantArray[i][6], plantArray[i][7], plantArray[i][8], plantArray[i][9], plantArray[i][10], plantArray[i][11]]
                    }
                }
                //CHECK if user has added at least one choice
                let sum = 0
                for (let i = 0; i < chosenBiomas.length; i++) {

                    if (chosenBiomas[i]) {
                        sum++
                    }

                }
                if (sum <= 0) {
                    Swal.showValidationMessage('<i class="fa fa-info-circle"></i> <strong>Choose at least one to proceed</strong>')
                }
                //console.log("Friends biomas", biomass)
                //console.log("Friends moisture_metal", moisture_metal)
                //console.log("Friends:", per_contaminant)

            }
        })
        swalPopUp_OptimizationINput();

    }




    //SWAL POPUP 
    //Opens a pop up where the user inputs information for going to step 2 (OPTIMIZATION SOLUTION)
    //The required input are the red ones as marked on fragkoulis' EXCEL
    async function swalPopUp_OptimizationINput(e) {
        const { value: formValues } = await Swal.fire({
            title: `<h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Supply-chain techno-economic parameters<Tooltip title='${INFOTEXT.HELPER_9}'><img src=${qm} style="width:30px; float:right; cursor:pointer"  /></Tooltip></h5>`,//field info to become a prop
            html:
                `${STYLE.POPUP_STEP2_INPUT}`,//to become a prop
            focusConfirm: true,
            height: 800,
            confirmButtonColor: "green",
            confirmButtonText: "Submit",//to become a prop
            showCancelButton: true,
            cancelButtonText: "Clear & Restart",
            cancelButtonColor: "red",
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {

                var testArray = [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value,
                    document.getElementById('swal-input3').value,
                    document.getElementById('swal-input4').value,
                    document.getElementById('swal-input5').value,
                    document.getElementById('swal-input6').value,
                    document.getElementById('swal-input7').value,
                    document.getElementById('swal-input8').checked,
                ]


                if (testArray[0].length === 0) {
                    testArray[0] = 1.444
                }
                if (testArray[1].length === 0) {
                    testArray[1] = 1.32
                }
                if (testArray[2].length === 0) {
                    testArray[2] = 0.7
                }
                if (testArray[3].length === 0) {
                    testArray[3] = 0.7
                }
                if (testArray[4].length === 0) {
                    testArray[4] = 250
                }
                if (testArray[5].length === 0) {
                    testArray[5] = 10
                }
                if (testArray[6].length === 0) {
                    testArray[6] = 60
                }
                if (testArray[6].length === 0) {
                    testArray[6] = 60
                }
                if (!testArray[7]) {
                    testArray[7] = 0
                }
                if (testArray[7]) {
                    testArray[7] = 3
                }
                //diesel=.1.44//gasoline=1.32,//lpg=0.7//jet=0,



                if (locationsToSend.length === 0) {
                    locationsToSend = locationsToSendClus
                    //console.log(locationsToSend)
                }
                if (resultMatrix.length === 0) {
                    resultMatrix = resultMatrixClus
                    //console.log(resultMatrix)
                }
                let sumOfArea = 0
                for (let i = 0; i < locationsToSend.length; i++) {
                    sumOfArea = sumOfArea + locationsToSend[i][2];
                    //console.log(`Sum of Area${i}`, sumOfArea)
                    //console.log(`Sum of Area${i}`, locationsToSend)
                }



                var data = {
                    "values_df": {
                        "dieselPrice": parseFloat(testArray[0]),
                        "gasolinePrice": parseFloat(testArray[1]),
                        "LPGPrice": parseFloat(testArray[2]),
                        "jetfuelPrice": parseFloat(testArray[3]),
                        "StorageMaxCapacity": 99999999999999,//this needs to be changed in the request
                        "min_cap_S": 10,
                        "max_cap_S": 99999999999999,
                        "min_cap_U": 10,
                        "max_cap_U": 99999999999999,
                        "min_cap_FP": 10,
                        "max_cap_FP": 99999999999999,
                        "min_cap_WG": 10,
                        "max_cap_WG": 99999999999999,
                        "price_BF": 1000,
                        "price_BC": parseFloat(testArray[4]),
                        //document.getElementById('swal-input10').value,
                        "DiscountRate": parseFloat(testArray[5]) / 100,
                        "HarvestingUnitCost": parseFloat(testArray[6]),
                        "mobile": parseFloat(testArray[7]),
                        "biomass": biomass,
                        "locations": locationsToSend,
                        "distances": resultMatrix,
                    }
                }


                //console.log("OPTIMIZATION", testArray[8] === "", data)

                passUserInput(data);
                return fetch(`/api/optimize`, {///api/optimize//http://167.235.129.196:5040/optimize
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })//.then(response =>  console.log(response.ok)).then(data=>console.log(data)).catch(console.error);
                    .then(response => {
                        console.log(response)
                        if (!response.ok) {
                            Swal.fire({ icon: "error", title: "An error has occured", text: "The areas marked are too large to calculate, please edit and make the areas smaller." })
                            throw new Error(response.statusText)

                        }
                        return response.json()
                    }).then((result) => {
                        //console.log("ma frien", per_contaminant)
                        passInstanceInfo(result);
                        passBiomassInfo(biomass);
                        passBiomassPerCont(per_contaminant)
                        //console.log("WHat we pass: ", sumOfArea)
                        passSumofArea(sumOfArea);
                        passMoistureMetal(moisture_metal);
                        navigate("/details");
                    }).catch(error => {
                        alert(error)
                    })
                //]
            }


        })
    }



    function goToStep2() {
        if (arrayOfFields.length < 2) {
            Swal.fire({
                icon: "error",
                html: "Not enough fields/areas have been selected <br/>Please input at least two (2) field to proceed. <br/>You can use the markers on the top left of the map.",
                showConfirmButton: true,
                confirmButtonColor: "green"
                //timer: 1500
            });
        }
        if (arrayOfFields.length >= 2) {
            fireOptimizationDialog();
        }
        // if (arrayOfFields.length === 0 || arrayOfFacilities.length === 0) {
        //     alert("You should have at least on Site(s) and Facilitie(s)")
        // }
        // else {
        //     fireOptimizationDialog();
        // }
    }

    async function fireOptimizationDialog() {
        const { value: choise } = await Swal.fire({
            title: ` <h5 style='background-color:green; color:white; margin:-25px -30px; padding:7px; border-radius:5px 5px 0px 0px'>Selection of Biomass<Tooltip  placement="right-end" title="You can either select biomass type(s) manually or let our system suggest options based on the characteristics of the areas you have marked as contaminated."><img src=${qm} style="width:30px; float:right; cursor:pointer; align-text:right;"/></Tooltip></h5>`,//field info to become a prop   
            input: 'radio',
            inputOptions: {
                'biomasses': '<span style="font-size:18px;  font-weight:600; ">Manual</span><br/>',
                'optimization': '<span style="font-size:18px; font-weight:600;">Automatic</span>'
            },
            confirmButtonColor: "green",
            confirmButtonText: "Continue",//to become a prop
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonColor: "red",
            focusConfirm: false,

        })
        if (choise === 'optimization') {
            swalPopUp_BiomassYield();
            //swalPopUp_OptimizationINput();
        }
        if (choise === 'biomasses') {
            //alert("END OF DEMO");
            // fireOptimizationDialog();
            swalPopUp_Manual();
        }
    }

    //Importing fields through CSV Staff

    const [csvData, setCsvData] = useState([]);
    // correct file extension is not used

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const arrayBuffer = event.target.result;
                const data = new Uint8Array(arrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                const headers = worksheet[0];
                const rows = worksheet.slice(1);

                const parsedData = rows.map((row) => {
                    return headers.reduce((acc, header, index) => {
                        acc[header] = row[index];
                        return acc;
                    }, {});
                });

                const isValidEntry = (entry) => {
                    const hectares = parseFloat(entry['hectares']);
                    const latitude = parseFloat(entry['latitude']);
                    const longitude = parseFloat(entry['longitude']);
                    return !isNaN(hectares) && !isNaN(latitude) && !isNaN(longitude);
                };

                const hasValidData = parsedData.length > 0 && parsedData.every(isValidEntry);

                if (hasValidData) {
                    //console.log(parsedData);
                    setCsvData(parsedData);
                    passImportedFields(parsedData);
                } else {
                    Swal.fire({title:'Invalid data found.', text: 'Ensure that every row has numeric values for hectares, latitude, and longitude.', icon:"error"});
                }
            } catch (error) {
                console.error('Error parsing file:', error);
            }
        };

        reader.onerror = (error) => {
            console.error('FileReader error:', error);
        };

        reader.readAsArrayBuffer(file);
    };

    const csvHandle = () => {
        document.getElementById('csvInput').click();
    };
    //-------_CSV -----import STAF-------------////
    function addFieldsManualy() {
        return (<CardRightSide fieldID={1} fieldArea={100} fieldCenter={[23, 45]} />)
    }

    var trial = locationsToSend

    function fun() {
        console.log('fun:', locationsToSend[0], trial)
    }
    const handleDownload = () => {
        var text;
        if (resultMatrix.length === 0) {
            text = resultMatrixClus.map(innerArray => innerArray.join(',')).join('\n');
        }
        else {
            text = resultMatrix.map(innerArray => innerArray.join(',')).join('\n');
        }
        const data = new Blob([text], { type: 'text/plain' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'matrix.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    return (
        <div className="sidebar_right_inner">
            <Box sx={{
                width: '98%',
                boxShadow: 1,
                height: "93vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid rgba(63, 62, 62, 0.3);",
            }}>
                <div className='header'> Sites List</div>
                <Box sx={{
                    height: '100%',
                    width: "97%",
                    border: "1px solid rgba(63, 62, 62, 0.3);",
                    overflow: "auto",
                }}>

                    {arrayOfFields.length > 0 ?
                        <div>
                            {arrayOfFields.map((field) => (
                                <CardRightSide
                                    fieldLat={field.center.lat}
                                    fieldLng={field.center.lng}
                                    fieldID={field.id}
                                    fieldArea={field.area / 10000}
                                    fieldContaminationLvl={field.contamination_lvl}
                                    fieldContaminationType={field.contamination_type} />
                            ))}
                        </div> :
                        <></>}
                </Box>

                {/* <button className='right_side_button_con' onClick={addFieldsManualy}>ADD</button> */}
            </Box>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                <CSVLink className='right_side_button_con_ex_ex' data={arrayForExport} headers={headers}><a onClick={handleDownload}>Export Fields</a></CSVLink>
                <button className='right_side_button_con_ex' htmlFor="csvInput" onClick={csvHandle}>Import Fields</button><input onChange={handleFileUpload} id="csvInput" name="file" type="File" style={{ display: 'none' }} />
                {/* <button className='right_side_button_con_ex' onClick={handleParse}>Import Fields</button> */}
                <button className='right_side_button_con' onClick={goToStep2}>Next Step</button>
            </div>

            <Helmet>
                <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>
                <script>
                    {
                        `function optiPop()
                        {
                            Swal.fire("General Information","${INFOTEXT.OPTIMIZATION_HELP}");
                        }`
                    }
                </script>
                <script>
                    {
                        `function addContaminant()
                        {
                            const container = document.getElementById('contaminants-container');
                            container.insertAdjacentHTML('beforeend', 
                                '<div style="display:flex; margin:1px;">' +
                                    '<div style="margin:1px;"><label>New Contaminant</label></div>' +
                                    '<div style="margin:1px;"><input type="number" placeholder="0" style="font-weight:600; width:80px; color:rgb(102, 102, 102);padding:3px; border-radius:2px; margin:-1px 2px; background:rgb(128, 128, 128, 0.1);border: 1px solid rgb(0, 0, 0, 0.2);"></div>' +
                                '</div>'
                            );
                        }`
                    }
                </script>
            </Helmet>
        </div>
    )
}

export default SideBarRight;



// function padArray6x6(originalArray) {
//     const resultArray = [];

//     // Loop through each row
//     for (let i = 0; i < 6; i++) {
//       const row = originalArray[i] || []; // Use an empty array if the row doesn't exist

//       // Loop through each column
//       const newRow = [];
//       for (let j = 0; j < 6; j++) {
//         const value = row[j] || 0; // Use 0 if the column doesn't exist
//         newRow.push(value);
//       }

//       resultArray.push(newRow);
//     }

//     return resultArray;
//   }

//   function padArray3x3(originalArray) {
//     const resultArray = [];

//     // Loop through each row
//     for (let i = 0; i < 6; i++) {
//       const row = originalArray[i] || []; // Use an empty array if the row doesn't exist

//       // Loop through each column
//       const newRow = [];
//       for (let j = 0; j < 3; j++) {
//         const value = row[j] || 0; // Use 0 if the column doesn't exist
//         newRow.push(value);
//       }

//       resultArray.push(newRow);
//     }

//     return resultArray;
//   }

//------------------------CAN be added if we eventually add Facilites to be inputed by the user------------------------------------//

{/* <button className='right_side_button' style={{ marginBottom: "-2px" }}>Save Sites </button> */ }
{/* <hr />
                <div className='header'> Facilities List</div>
                <Box sx={{
                    height: '50%',
                    width: "97%",
                    border: "1px solid rgba(63, 62, 62, 0.3);",
                    overflow: "auto",

                }}>
                    {arrayOfFacilities.length > 0 ?
                        <div>
                            {arrayOfFacilities.map((facility) => (
                                <CardRightSideFacilities
                                    facilityCenter={[facility.center.lat + " " + facility.center.lng]}
                                    facilityID={facility.id}
                                    facilityTechnology={facility.facility_technology} />
                            ))}
                        </div> :
                        <></>}

                </Box>
                <button className='right_side_button'>Save Facilities</button> */}