
export const INFOTEXT={
    FIELD_HELP:   "You can input the concentrations of contaminants present in the soil of the designated fields, along with general soil characteristics. Note: All parameters are considered homogeneous across all land areas entered.",
    HELPER_9:"You can set specific economic parameters for your supply chain. Input the estimated selling price of the end products, the discount rate, the cost of procuring biomass, and indicate whether you plan to use mobile Pyrolysis units, beyond fixed ones. Note: Selecting mobile Fast Pyrolysis units may increase significantly the time to generate results.",
    OPTIMIZATION_HELP:"You can either select biomass type(s) manually or let our system suggest options based on the characteristics of the areas you have marked as contaminated.",
    BIOMASS_SELECT:"You can choose among the biomass types available for your designated areas as derived automatically from our system . Note: You can select multiple biomass types."
}

export const STEPS={
    STEP1:"Location & Initial Inputs",
    STEP2:"List of Biomasses",
    STEP3:"Decontamination Level",
    STEP4:"Combination of Biomass Technology",
    STEP5:"Investement Reccomendation",
    STEP6:"Optimization Solution",
    STEP7:"Complete Solution"
}
export const NAVIGATION={
    USER:"User View",
    MAIN:"Main View",
    DETAILS:"Results View"
}


export const INFOTEXTHOVER=[
    {
        header: "Total Life Cycle Cost",
        text: "Total Life-Cycle Cost (TLCC) represents the total expenditure over the whole project’s life (including initial capital, operating, maintenance and  feedstock costs) and discounts this amount to a present value.",
    },
    {
        header: "Net Present Value",
        text: "NPV calculates the difference between the present value of all cash inflows and outflows over the life of the project. It helps to determine if the project is financially viable by considering the value of money over time.",
    },
    {
        header: "Internal Rate of Return",
        text: "IRR is a metric used to evaluate the profitability of an investment. It is the discount rate at which the net present value of all future cash flows (both positive and negative) from the project equals zero.",
    },
    {
        header: "Levelized Cost of (bio) Energy",
        text: "LCOE is the average cost per unit of bioenergy produced over the project's lifetime.",
    },
    {
        header: "Primary Energy Demand",
        text: "This measures the total amount of non-renewable primary energy required to produce biofuels, including all fossil energy inputs  to processes, materials and fuels required throughout the value chains.",
    },
    {
        header: "Productivity",
        text: "Productivity measures the total final biofuel energy produced from a given area of land (hectares). It indicates how efficiently the land is used for bioenergy production.",
    },
    {
        header: "Global Warming Potential",
        text: "GWP estimates the impact of the CERESiS value chains on climate change by measuring the amount of greenhouse gases emitted throughout the project's life cycle.",
    },
    {
        header: "Acidification Potential",
        text: "This tracks the total amount of pollutants relevant to the Acidification Potential of the CERESiS value chains (NOx, SO2, etc.).",
    },
    {
        header: "Off-take of contaminants",
        text: "This measures the amount of contaminants removed annually from the soil and carried in the biomass feedstock, contributing to the clean-up of contaminated land.",
    },
    {
        header: "Decontamination efficiency",
        text: "This evaluates how effectively the CERESiS decontamination methods remove contaminants from the biomass feedstock, so that they are not passed on to the  biofuel produced. It represents the mass fraction of contaminants expected to be captured. Higher efficiency means more successful decontamination of the biomass stream.",
    },
    {
        header: "Land under Phytoremediation",
        text: "This indicates the area of contaminated land utilized for biomass production for each case considered.",
    },
    {
        header: "Job creation",
        text: "This measures the total number of direct, indirect and induced jobs generated by each CERESiS value chain considered.",
    },
    {
        header: "Social acceptability",
        text: "This assesses the level of support and acceptance from the local community and other stakeholders for the CERESiS value chains considered. It is a custom-made indicator (scaled from 0-worst to 10-best), combining regional level            CERESiS performance aspects (e.g. local environmental impacts, crop yield, potential local use of solid by-products) and how important are these aspects to local communities (acquired from interviews' feedback). High social acceptability suggests strong community approval.",
    },
]